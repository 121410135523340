<template>
  <div class="read-also-card">
    <div class="read-also-card__content">
      <p class="read-also-card__subtitle">читати також</p>
      <h4 class="read-also-card__title">{{ content.title }}</h4>
      <a :href="content.link" class="read-also-card__link" target="_blank">
        Читати статтю
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 11L15 6L10 1" stroke="#515BA2"/>
          <path d="M0 6L15 6" stroke="#515BA2"/>
        </svg>
      </a>
    </div>
    <img src="@/assets/img_const-pages/read-also-card.png" class="read-also-card__img desc"/>
    <img src="@/assets/img_const-pages/read-also-mob.png" class="read-also-card__img mob"/>
  </div>
</template>

<script>
export default {
  name: 'ReadAlsoCard',
  props: {
    content: {
      type: Object,
      required: false
    },
  }
}
</script>

<style lang="scss" scoped>
.read-also-card {
  background: #FFFFFF;
  border-radius: 14px;
  margin: 48px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    .read-also-card__link {
      color: #6875CF;

      @media (max-width: 1024px) {
        color: #515BA2;
      }

      svg {
        color: #6875CF;
        @media (max-width: 1024px) {
          color: #515BA2;
        }

        path {
          stroke: #6875CF;
          @media (max-width: 1024px) {
            stroke: #515BA2;
          }
        }
      }
    }
  }

  @media (max-width: 550px) {
    flex-direction: column-reverse;
    // max-width: 288px;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }

  &__content {
    padding: 32px 70px 28px 30px;

    @media (max-width: 830px) {
      padding: 16px 32px 14px 16px;
    }
    @media (max-width: 550px) {
      padding: 20px 15px;
    }
  }

  &__subtitle {
    font-family: "Raleway-Bold";
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2E2E2E;
  }

  &__title {
    font-family: "Raleway-Bold";
    font-size: 18px;
    line-height: 24px;
    color: #2E2E2E;
    margin-top: 18px;
    margin-bottom: 52px;
    max-width: 398px;
  }

  &__link {
    color: #515BA2;
    font-size: 13px;
    line-height: 15px;
    font-family: "Raleway-Bold";
    text-decoration: none;

    svg {
      margin: 0 10px;
    }
  }

  &__img {
    &.mob {
      display: none;

      @media (max-width: 550px) {
        display: block;
        object-fit: contain;
      }
    }

    &.desc {
      @media (max-width: 550px) {
        display: none;
      }
    }
  }
}
</style>
