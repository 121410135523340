<template>
  <section class="faq">
    <h4 class="faq__title">
      Часті запитання
    </h4>

    <ul class="faq__list">
      <li
        class="faq__item"
        :class="{active: faqActive.includes(item.id)}"
        v-for="item of faq"
        :key="item.id"
        @click="toggleDrop(item)"
      >
        <div class="faq__item-shown">
          {{ item.title }}
          <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.43538 6.83282C6.21024 7.05573 5.8465 7.05573 5.62078 6.83282L0.272368 1.54254C-0.0533546 1.22076 -0.0533546 0.697219 0.272368 0.374864C0.598092 0.0530806 1.12673 0.0530806 1.45246 0.374864L6.02837 4.90041L10.6031 0.374864C10.9294 0.0530806 11.4581 0.0530806 11.7838 0.374864C12.1095 0.697219 12.1095 1.22076 11.7838 1.54254L6.43538 6.83282Z" fill="white"/>
          </svg>
        </div>
        <div class="faq__item-hidden" v-html="item.text"></div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'FaqConstPage',
  props: {
    faq: {
      type: Array,
      required: false
    },
  },
  data: () => {
    return {
      faqActive: [],
    }
  },
  methods: {
    toggleDrop(question) {
      if (this.faqActive.includes(question.id)) {
        this.faqActive = this.faqActive.filter(item => item !== question.id);
      } else {
        this.faqActive.push(question.id);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";
$fontB: "Raleway-Bold";
.faq {
  padding-top: 32px;
  background-color: #BCA36A;
  color: #FFFFFF;
  box-shadow: 0px 8px 30px rgba(188, 163, 106, 0.8);
  border-radius: 14px;
  position: relative;
  width: 100%;
  margin-top: 48px;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 550px) {
    padding-top: 20px;
  }

  &__title {
    font-family: $fontB;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 8px;
    line-height: 12px;
    margin: 0 30px 22px 30px;

    @media (max-width: 550px) {
      margin: 0 16px 20px 16px;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    width: 100%;
    padding: 0 25px 0 30px;
    border-bottom: 1px solid;
    font-family: $fontR;
    transition: all 0.4s;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      background-color: #CFB67E;
      @media (max-width: 1024px) {
        background-color: transparent;
      }
    }

    &:nth-of-type(1) {
      border-top: 1px solid;
    }
    &:nth-of-type(3) {
      border-bottom: none;
    }

    @media (max-width: 550px) {
      padding: 0 16px 0 16px;
    }

    &-shown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 28px 0;
      font-family: $fontB;
      font-size: 18px;
      line-height: 24px;
      transition: 0.4s;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 117%;
      }

      @media (max-width: 550px) {
        padding: 16px 0;
      }
    }

    &-hidden {
      font-size: 18px;
      line-height: 30px;
      max-height: 0;
      overflow: hidden;
      transition: 0.4s;
      font-family: $fontM;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 125%;
        font-family: "Raleway-Regular";
      }
    }

    svg {
      transition: 0.4s;
      width: 7px;
      min-width: 7px;
      height: 12px;
    }

    &.active {

      .faq__item-hidden {
        max-height: 100vh;
        padding-bottom: 24px;

        @media (max-width: 768px) {
          padding-bottom: 35px;
        }
        @media (max-width: 550px) {
          padding-bottom: 16px;
        }
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
