<template>
  <div class="similarArticles">
    <h4 class="similarArticles__title">Схожі статті</h4>
    <div class="desc">
      <ul class="similarArticles__list">
        <li
          v-for="article of articles"
          :key="article.id"
          class="article"
        >
          <img
            :src="require(`@/assets/img_const-pages/${article.img}`)"
            class="article__img"
            alt=""
          />
          <div class="article__content">
            <h5 class="article__title">{{ article.title }}</h5>
            <a
              class="article__link"
              target="_blank"
              :href="article.link"
            >
              Читати далі
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 11.0476L15 6.05342L10 1.05924"/>
                <path d="M0 6.05334L15 6.05334" />
              </svg>
            </a>
          </div>
        </li>
      </ul>
    </div>
      <Splide
        :options="options"
        has-slider-wrapper
        @splide:active="(e) => setActiveSlide(e)"
        class="mob"
      >
        <SplideSlide
          v-for="article of articles"
          :key="article.id"
        >
            <div class="article">
              <img
              :src="require(`@/assets/img_const-pages/${article.img}`)"
              class="article__img"
              alt=""
            />
            <div class="article__content">
              <h5 class="article__title">{{ article.title }}</h5>
              <a
                class="article__link"
                target="_blank"
                :href="article.link"
              >
                Читати далі
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 11.0476L15 6.05342L10 1.05924"/>
                  <path d="M0 6.05334L15 6.05334" />
                </svg>
              </a>
            </div>
            </div>
        </SplideSlide>

        <template v-slot:controls>
        <ul class="splide__pagination">
          <li
            class="splide__pagination__page"
            :class="{ active: activeSlide === 0 }"
          >
            <div class="splide__pagination-dot"></div>
          </li>
          <li
            class="splide__pagination__page"
            :class="{ active: activeSlide === 1 }"
          >
            <div class="splide__pagination-dot"></div>
          </li>
          <li
            class="splide__pagination__page"
            :class="{ active: activeSlide === 2 }"
          >
            <div class="splide__pagination-dot"></div>
          </li>
          <li
            class="splide__pagination__page"
            :class="{ active: activeSlide === 3 }"
          >
            <div class="splide__pagination-dot"></div>
          </li>
        </ul>
      </template>
      </Splide>
    
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  name: "similarArticles",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
		return {
			options: {
			  rewind: true,
        perPage: 1,
        autoplay: false,
        pauseOnHover: false,
        arrows: false,
        pagination: false,
        gap: 0,
        start: 0,
			},
      activeSlide: 0,
		};
	},
  props: {
    articles: {
      type: Array,
      required: true,
    }
  },
  methods: {
    setActiveSlide(splide) {
      this.activeSlide = splide.index;
    },
  }
}
</script>

<style lang="scss" scoped>
$fontB: "Raleway-Bold";
.similarArticles {
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  padding: 0 10px;
  margin-bottom: 60px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @media (max-width: 768px) {
    margin-bottom: 48px;
  }
  @media (max-width: 768px) {
    padding: 0 0 0 10px;
  }

  &__title {
    font-family: $fontB;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2E2E2E;
    margin-bottom: 30px;
  }

  .article {
    border-radius: 14px;
    border: 1px solid transparent;
    overflow: hidden;
    list-style-type: none;
    height: 323px;
    background-color: #FFFFFF;

    &:hover {
      cursor: pointer;
      .article__link {
        color: #6875CF;

        @media (max-width: 1024px) {
          color: #515BA2
        }

        & svg {
          color: #6875CF;
          @media (max-width: 1024px) {
            color: #515BA2
          }
          path {
            stroke: #6875CF;
            @media (max-width: 1024px) {
              stroke: #515BA2
            }
          }
        }
      }
    }

    &__content {
      padding: 30px 32px 34px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 188px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
      color: #2E2E2E;
      font-family: $fontB;
      display: inline-block;
    }

    &__link {
      text-decoration: none;
      font-size: 13px;
      line-height: 24px;
      color: #515BA2;
      font-family: $fontB;
      display: inline-block;

      svg {
        margin: 0 10px;

        path {
          stroke: #515BA2;
        }
      }
    }
  }

  .desc {
    @media (max-width: 992px) {
      display: none;
    }

    .similarArticles__list {
      display: grid;
      grid-template-columns: repeat(4, minmax(273px, 302px));
      grid-column-gap: 26px;
      justify-content: center;

      @media (max-width: 1320px) {
        grid-column-gap: 8px;
        grid-template-columns: repeat(4, 1fr);
      }

      .article {
        @media (max-width: 992px) {
          height: fit-content;
        }

        &__content {
          @media (max-width: 1320px) {
            padding: 16px 20px 34px;
          }
        }
      }
    }
  }

  .mob {
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
  }

  .splide {
    background-color: transparent;

    &__slide {
      display: flex;
      margin-left: -16px;
      &:first-child {
        margin-left: 0;
      }
    }

    .article {
      width: 90%;
      // margin-right: 16px;
      height: fit-content;

      &__content {
        @media (max-width: 540px) {
          padding: 20px;
        }
      }
    }

    &__pagination {
      bottom: -2.2em;
      z-index: 1;

      @media (max-width: 992px) {
        bottom: -3em;
      }

      @media (max-width: 768px) {
        bottom: -1.8em;
      }

      &__page {
        width: 12px;
        height: 12px;
        background-color: transparent;
        opacity: 1;
        transition: 0.4s;
        cursor: default;

        &.active .splide__pagination-dot {
          background: #515BA2;
        }
      }

      &-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 1px solid #515BA2;
        background: transparent;
      }
    }
  }
}
</style>
