<template>
  <div class="warning" :style="{ borderColor: borderColor }">
    <svg width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.02766 41.2148C4.32239 41.2148 3.64227 41.0747 3.00617 40.7971C2.40242 40.5329 1.86606 40.1592 1.41145 39.6857C0.965823 39.2221 0.61633 38.6848 0.372854 38.091C0.125784 37.4872 0.00090073 36.8538 2.29207e-06 36.207C-0.000896145 35.2879 0.262346 34.3804 0.761877 33.5808L18.7198 3.15973L18.7324 3.13906C19.1987 2.39516 19.8501 1.78602 20.6164 1.37723C21.3433 0.99 22.1627 0.785156 22.9865 0.785156H23H23.0135C23.8373 0.785156 24.6567 0.99 25.3836 1.37723C26.1499 1.78602 26.8013 2.39516 27.2676 3.13906L27.2802 3.15973L38.7316 22.5588V22.5597C38.8538 22.7663 38.888 23.008 38.8278 23.2398C38.7676 23.4725 38.6211 23.6675 38.4145 23.7896C38.2761 23.8714 38.1189 23.9145 37.959 23.9145C37.6418 23.9145 37.3454 23.7447 37.1845 23.4725L25.742 4.08871C25.1401 3.13547 24.1401 2.58652 23 2.58203C21.8626 2.58652 20.8644 3.13367 20.2616 4.08242L2.3027 34.5053L2.29012 34.526C1.96399 35.048 1.79778 35.6131 1.79688 36.207C1.79598 37.0254 2.1275 37.8394 2.7061 38.4396C3.32422 39.0775 4.13012 39.4162 5.03574 39.418H40.9643C41.8699 39.4162 42.6749 39.0775 43.293 38.4396C43.8725 37.8394 44.204 37.0254 44.2031 36.207C44.2022 35.614 44.036 35.048 43.709 34.526L43.6973 34.5053L41.2949 30.4354C41.1727 30.2287 41.1386 29.9871 41.1988 29.7544C41.258 29.5226 41.4054 29.3267 41.612 29.2054C41.7504 29.1237 41.9076 29.0805 42.0675 29.0805C42.3847 29.0805 42.6812 29.2495 42.842 29.5217L45.2399 33.5844C45.7386 34.3822 46.0009 35.2887 46 36.207C45.9991 36.8538 45.8742 37.4872 45.6272 38.091C45.3837 38.6848 45.0342 39.2221 44.5877 39.6857C44.1339 40.1592 43.5976 40.5329 42.9938 40.7971C42.3577 41.0738 41.6776 41.2148 40.9723 41.2148H5.02766Z" :fill="imgColor"/>
      <path d="M38.2409 34.6966H7.75914C7.47847 34.6966 7.21937 34.546 7.08025 34.3019C6.94121 34.0577 6.94347 33.7577 7.08619 33.5158L22.3271 7.6879C22.4678 7.44958 22.7236 7.30338 23 7.30338C23.2765 7.30338 23.5323 7.44958 23.6729 7.6879L38.9138 33.5158C39.0566 33.7577 39.0588 34.0578 38.9197 34.3019C38.7807 34.546 38.5216 34.6966 38.2409 34.6966ZM9.12879 33.1313H36.8712L23 9.62452L9.12879 33.1313Z" :fill="imgColor"/>
      <path d="M23.0002 30.8202C21.7177 30.8202 20.6743 29.7769 20.6743 28.4944C20.6743 27.2119 21.7177 26.1685 23.0002 26.1685C24.2826 26.1685 25.326 27.2119 25.326 28.4944C25.326 29.7769 24.2826 30.8202 23.0002 30.8202ZM23.0002 27.7191C22.5727 27.7191 22.2249 28.0669 22.2249 28.4944C22.2249 28.9219 22.5727 29.2697 23.0002 29.2697C23.4276 29.2697 23.7754 28.9219 23.7754 28.4944C23.7754 28.0669 23.4276 27.7191 23.0002 27.7191Z" :fill="imgColor"/>
      <path d="M23.0002 24.8764C22.1505 24.8764 21.8096 23.8878 21.6639 23.4657C21.4825 22.9397 21.3068 22.2237 21.1417 21.3377C20.8521 19.7839 20.6743 18.1408 20.6743 17.6468C20.6743 16.3608 21.7177 15.3146 23.0002 15.3146C24.2826 15.3146 25.326 16.3608 25.326 17.6468C25.326 18.1408 25.1482 19.7839 24.8587 21.3377C24.6935 22.2237 24.5178 22.9397 24.3364 23.4657C24.1908 23.8878 23.8499 24.8764 23.0002 24.8764ZM23.0002 16.8694C22.5727 16.8694 22.2249 17.2181 22.2249 17.6468C22.2249 17.9435 22.3505 19.2297 22.5904 20.6301C22.7406 21.5064 22.8814 22.1177 23.0002 22.5412C23.1189 22.1176 23.2597 21.5064 23.4099 20.6301C23.6498 19.2297 23.7754 17.9435 23.7754 17.6468C23.7754 17.2181 23.4276 16.8694 23.0002 16.8694Z" :fill="imgColor"/>
      <path d="M40.0127 27.396C40.5089 27.396 40.9111 26.9938 40.9111 26.4976C40.9111 26.0014 40.5089 25.5991 40.0127 25.5991C39.5165 25.5991 39.1143 26.0014 39.1143 26.4976C39.1143 26.9938 39.5165 27.396 40.0127 27.396Z" fill="#ACB317"/>
    </svg>
    <p class="warning__text"><slot name="text"></slot></p>
  </div>
</template>

<script>
export default {
  name: 'warning',
  props: {
    imgColor: {
      type: String,
      require: true,
    },
    borderColor: {
      type: String,
      require: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.warning {
  padding: 14px 20px;
  border-radius: 14px;
  border: 2px solid;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 22px;
  }

  svg {
    margin-right: 27px;
    height: 42px;
    min-width: 46px;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  &__text {
    color: #2E2E2E;
    font-family: 'Raleway-Medium';
    font-size: 18px;
    line-height: 30px;

    @media (max-width: 540px) {
      font-family: 'Raleway-Regular';
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
