<template>
  <div class="v-adults" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="vac-adults/banner-desc.png" mobImg="vac-adults/banner-mob.png">
      <template v-slot:link>Вакцинація дорослих</template>
      <template v-slot:title>Вакцинація дорослих</template>
      <template v-slot:text>
        Вакцинації дорослих варто приділяти не меншу увагу, ніж вакцинації дітей. Це важливо, бо у разі захворювання батьки можуть заразити незахищену дитину.
      </template>
    </Banner>

    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Вакцинація дорослих
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                Навіщо вакцинація дорослим?
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Категорії дорослих, яким необхідні профілактичні щеплення
              </a>
            </li>
          </ul>
        </Contents>
        <section class="page-wrapper__section v-adults__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Вакцинація дорослих
          </h2>

          <p class="page-wrapper__text">
            Вакцинації дорослих варто приділяти не меншу увагу, ніж вакцинації дітей. Це важливо, бо у разі захворювання батьки можуть заразити незахищену дитину. Крім того, працівники певних професій і люди, які доглядають за хворими, ризикують заразитися інфекціями, а мандрівники можуть підхопити інфекції під час перебування в різних регіонах і країнах. Тож дорослим: батькам, працівникам певних професій та мандрівникам варто пам’ятати про необхідність вакцинації.
          </p>
        </section>

        <section class="page-wrapper__section v-adults__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            Навіщо вакцинація дорослим?
          </h2>

          <p class="page-wrapper__text">
            Імунітет проти деяких захворювань зберігається не на все життя, тому періодично необхідне повторне введення вакцин — ревакцинація.<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Батькам варто замислитися про щеплення від інфекційних захворювань, щоб захистити не тільки себе, але і свою дитину, особливо якщо вона не досягла віку для проведення рекомендованої вакцинації або внаслідок хвороби не може бути щеплена. Наприклад, від грипу, менінгококової інфекції, гепатиту та інших.
          </p>
          <p class="page-wrapper__text">
            Деякі люди, які вас оточують, можуть бути носіями збудників дифтерії, пневмокока, менінгокока або вірусу гепатиту В. Наприклад, для збереження захисного імунітету ревакцинацію від дифтерії та правця необхідно проводити дорослим кожні 10 років відповідно до Календаря профілактичних щеплень (Наказ МОЗ України від 11.10.2019 №2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень»).<sup>2</sup>
          </p>
          <p class="page-wrapper__text">
            Стосовно мандрівників, то проведення вакцинації від інфекцій варто розглянути залежно від країни.<sup>3</sup><br>
            На жаль, не всі дорослі або літні люди є абсолютно здоровими. Є й ті, хто страждає на хвороби, зокрема захворювання легень, серця, нирок, цукровий діабет, хвороби крові, імунодефіцити. Таким людям, так само як і тим, хто живе поруч або доглядає за ними, на підставі показань лікар може порекомендувати щорічно робити щеплення від грипу. Дорослим з деякими хронічними захворюваннями лікар також може рекомендувати вакцинуватися від пневмококової інфекції.<br>
            Особам, які близько контактують з носіями вірусу гепатиту В, на підставі показань лікар може рекомендувати щеплення від гепатиту В (Наказ МОЗ України від 11.10.2019 №2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень»).<sup>2,3</sup>
          </p>
          <p class="page-wrapper__text">
            Крім того, підхопити інфекцію або стати її джерелом для інших можна не тільки під час побутових контактів, але і на роботі, особливо в певних сферах, зокрема медичних та освітніх закладах. Таких працівників відносять до професійних груп ризику.<sup>3</sup>
          </p>
        </section>

        <section class="page-wrapper__section v-adults__section" id="stage-3">
          <h2 class="page-wrapper__h2-title">
            Категорії дорослих, яким необхідні профілактичні щеплення
          </h2>

          <div class="v-adults__vac vac">
            <ul class="vac__list">
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Батьки, якщо їх дитина не досягла рекомендованого віку для вакцинації <p class="vac__text" style="display: inline">або не може її пройти за станом здоров'я.<sup>5</sup></p></h5>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/vac-adults/maternity.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Дорослі, у яких підійшов<br> час ревакцинації. </h5>
                  <p class="vac__text">Для збереження імунітету<br> щеплення від правця та<br> дифтерії необхідно робити<br> кожні 10 років.<sup>2,6</sup></p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/vac-adults/revac.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">У разі епідеміологічних показників, </h5>
                  <p class="vac__text">якщо існує підвищений ризик зараження інфекційними захворюваннями (грип, кір, менінгококова інфекція).<sup>4</sup></p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/vac-adults/epid-poc.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Група ризику на підставі професійної діяльності</h5>
                  <p class="vac__text">(працівники медичних, освітніх установ, сфери обслуговування, харчових виробництв).<sup>4</sup></p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/vac-adults/prof.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">У випадку поїздки за кордон</h5>
                  <p class="vac__text">(особливо, до країн з несприятливою<br> епідеміологічною ситуацією).<sup>4,7</sup></p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/vac-adults/table.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Дорослі із хронічними захворюваннями</h5>
                  <p class="vac__text">(цукровий діабет,<br> імунодефіцити, хвороби серця й органів дихання та ін.).<sup>4</sup></p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/vac-adults/chron.svg">
                </div>
              </li>
            </ul>
          </div>

          <Warning imgColor="#515BA2" borderColor="#515BA2" class="v-adults__warning">
            <template v-slot:text>Зверніть увагу, що визначити необхідність вакцинації та її схему, а також надати відповідні рекомендації може тільки лікар.</template>
          </Warning>
        </section>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />
    <Links>
      <ol>
        <li
          class="list"
        >
         Вакцинація дорослих: проти яких захворювань необхідно робити щеплення за Календарем профілактичних щеплень (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/immunization/vakcinacija-doroslih-proti-jakih-zahvorjuvan-neobhidno-robiti-scheplennja-za-kalendarem-profilaktichnih-scheplen">Вакцинація дорослих: проти яких захворювань необхідно робити щеплення за Календарем профілактичних щеплень (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Календар профілактичних щеплень (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen">Календар профілактичних щеплень (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Про порядок проведення профілактичних щеплень в Україні та контроль якості й обігу медичних імунобіологічних препаратів (ligazakon.net) <a target="_blank" href="https://ips.ligazakon.net/document/RE19897?an=1529">Про порядок проведення профілактичних щеплень в Україні та контроль якості й обігу медичних імунобіологічних препаратів | LIGA:ZAKON (ligazakon.net)</a>
        </li>
        <li
          class="list"
        >
          Наказ МОЗ України від 11.10.2019 №2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень»<a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1182-19#Text"> Про внесення змін до Календаря ... | від 11.10.2019 № 2070 (rada.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Загальна інформація | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/imunizaciya/zagalna-informaciya">Загальна інформація | Центр громадського здоров’я (phc.org.ua)</a>
        </li>
        <li
          class="list"
        >
          Імунізація дорослих: проти яких захворювань необхідно робити щеплення | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/news/imunizaciya-doroslikh-proti-yakikh-zakhvoryuvan-neobkhidno-robiti-scheplennya">Імунізація дорослих: проти яких захворювань необхідно робити щеплення | Центр громадського здоров’я (phc.org.ua)</a>
        </li>
        <li
          class="list"
        >
          Рекомендації ВООЗ «Міжнародні подорожі та безпека здоров'я» <a target="_blank" href="https://www.who.int/publications/i/item/9789241580472">International travel and health (who.int)</a>
        </li>
      </ol>
      <p class="list">
        MAT-UA-2101723 (дата першого застосування 20.11.2021)
      </p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Вакцинація дорослих",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-for-adults#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Навіщо вакцинація дорослим?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-for-adults#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Категорії дорослих, яким необхідні профілактичні щеплення",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-for-adults#stage-3"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';
import Warning from '@/components/const-pages/warning.vue';
import Vocabulary from '@/components/const-pages/vocabulary.vue';
export default {
  name: "vaccinationAdults",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
    Warning,
    Vocabulary,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
.v-adults {
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  &__warning {
    margin-top: 28px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }

  .vac {
    border: 2px solid #515BA2;
    border-radius: 14px;
    padding: 2px 8px;
    background: transparent;
    margin-top: 28px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      list-style-type: none;
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 440px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      position: relative;
      padding-left: 22px;
      padding-top: 20px;
      padding-bottom: 11px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 768px) {
        padding-left: 8px;
        padding-right: 8px;
        justify-content: flex-start;
      }

      @media (max-width: 440px) {
        padding-bottom: 4px;
      }

      &::after {
        content: '';
        width: 1px;
        background-color: #BFBFBF;
        height: 92%;
        position: absolute;
        right: 0;
        top: 8px;
      }

      &::before {
        content: '';
        width: 92%;
        background-color: #BFBFBF;
        height: 1px;
        position: absolute;
        left: 8px;
        top: 0;

        @media (max-width: 1160px) {
          width: 95%;
        }
      }

      &:nth-of-type(3)::after {
        background-color: transparent;
        @media (max-width: 768px) {
          background-color: #BFBFBF;
        }
      }

      &:nth-of-type(2)::after,
      &:nth-of-type(4)::after {
        background-color: #BFBFBF;
        @media (max-width: 768px) {
          background-color: transparent;
        }
      }

      &:nth-of-type(6)::after {
        background-color: transparent;
      }

      &:nth-of-type(1)::after,
      &:nth-of-type(3)::after,
      &:nth-of-type(5)::after,
      &:nth-of-type(7)::after {
        @media (max-width: 440px) {
          background-color: transparent;
        }
      }
      
      &:nth-of-type(1)::before,
      &:nth-of-type(2)::before,
      &:nth-of-type(3)::before {
        background-color: transparent;
      }

      &:nth-of-type(2)::before {
        @media (max-width: 440px) {
          background-color: #BFBFBF;
        }
      }

      &:nth-of-type(3)::before,
      &:nth-of-type(4)::before {
        @media (max-width: 768px) {
          background-color: #BFBFBF;
        }
      }
    }

    &__title {
      font-family: 'Raleway-Bold';
      color: #515BA2;
      font-size: 18px;
      line-height: 24px;
      max-width: 221px;

      @media (max-width: 440px) {
        max-width: unset;
      }
    }

    &__text {
      font-family: 'Raleway-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #2E2E2E;
      margin-top: 4px;
      font-weight: 400;
      @media (max-width: 540px) {
        font-size: 16px;
      }
    }

    &__content {
      &-text {
        padding-right: 16px;
      }
      &-img {
        text-align: end;
        padding-right: 20px;

        @media (max-width: 440px) {
          padding-right: 0;
          margin-top: 12px;
        }
      }
    }
  }

  .links-wrapper {
    p.list {
      margin-top: 24px !important;

      @media (max-width: 540px) {
        margin-top: 48px !important;
      }
    }
  }
}
</style>