<template>
  <nav class="bottom-nav">
    <ul class="bottom-list">
      <li onclick="gtag('event', 'kashluk', {'event_category': 'main_menu', 'event_label': 'Кашлюк'})">
        <a class="bottom-list__item"  href="#section1" tabindex="-1">Кашлюк</a>
      </li>
      <li onclick="gtag('event', 'difteria', {'event_category': 'main_menu', 'event_label': 'Діфтерія'})">
        <a class="bottom-list__item" href="#section2" tabindex="-1">Дифтерія</a>
      </li>
      <li onclick="gtag('event', 'pravec', {'event_category': 'main_menu', 'event_label': 'Правець'})">
        <a class="bottom-list__item" href="#section3" tabindex="-1">Правець</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "meningit-header-bottom-block",
  mounted() {
    const index = window.location.href.toString().indexOf('#');
    if (index >= 0) {
      window.location.href = window.location.href.substring(index, 0);
    }

    document.querySelectorAll('.bottom-list__item').forEach(link => {
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });

    const headings = document.querySelectorAll('.anchor');
    const link = document.querySelectorAll('.bottom-list__item');

    document.addEventListener('scroll', (e) => {
      headings.forEach(ha => {
        const rect = ha.getBoundingClientRect();
        if (rect.top > 0 && rect.top < 450) {
          const location = window.location.toString().split('#')[0];
          history.replaceState(null, null, location + '#' + ha.id);

          if (ha.id === 'stage6') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[0].classList.add('active-link')
           } else if (ha.id === 'section2') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[1].classList.add('active-link')
          } else if (ha.id === 'section3') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[2].classList.add('active-link')
          } else if (ha.id === 'stage1') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
          }
        }
      });
    });
  }
}
</script>

<style scoped lang="scss">
ul {list-style-type: none;}
.bottom-nav {
  flex: 1;
  padding: 0 26px;
  @media screen and (max-width: 810px) {
    padding: 0 8px;
  }
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}
.bottom-list {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  max-width: 40%;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    justify-content: space-around;
  }
  & li:hover {
    cursor: pointer;
  }
  
  &__item {
    padding: 0 4px;
    font-size: 16px;
    line-height: 19px;
    color: #3F4C56;
    text-decoration: none;

    &.active-link {
      font-weight: 700;
    }
    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }
}
</style>
