<template>
  <div class="contents">
    <h3 class="contents__title">
      Зміст статті
    </h3>

    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
$fontB: "Raleway-Bold";
$fontR: "Raleway-Regular";

.contents {
  width: 100%;
  padding: 30px;
  border-radius: 14px;
  border: 2px solid #515BA2;

  @media (max-width: 768px) {
    padding: 32px 15px;
  }

  &__title {
    font-family: $fontB;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2E2E2E;
    margin-bottom: 25px;
  }

  &__list {
    list-style: none;
    padding: 1px 0;
  }

  &__item {
    margin-top: 10px;

    sup {
      text-decoration: none;
      font-family: $fontB;
      color: #515BA2;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    font-family: $fontB;
    color: #515BA2;
    text-decoration: none;
    position: relative;
    border-bottom: 1px solid #515BA2;
    &:after {
      position: absolute;
      bottom: -2px;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #515BA2;
      transition: all ease 0.2s;
      visibility: hidden;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    @media (max-width: 1024px) {
      text-decoration: underline;
      text-underline-position: under;
    }

    &:hover {
      color: #6875CF;
      cursor: pointer;
      border-bottom-color: transparent;

      &::after {
        background-color: #6875CF;
        width: 24px;
        visibility: visible;
        @media (max-width: 1024px) {
          color: #515BA2;
          width: 100%;
        }
      }

      @media (max-width: 1024px) {
        color: #515BA2;
      }
    }
  }
}
</style>