<template>
  <div class="read-also">
    <div class="read-also__text">
      <p>Читайте також</p>
      <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3335 14.333L21.0002 7.66634L14.3335 0.999675" stroke="#4D4957" stroke-width="1.33333"/>
        <path d="M0 7.66699L20 7.66699" stroke="#4D4957" stroke-width="1.33333"/>
      </svg>
    </div>

    <ul class="read-also__list">
      <li
        class="read-also__item"
        v-for="item of list"
        :key = item.id
        :onclick="`gtag('event', 'flu_read-also:${item.text}', {'event_category': 'read-also', 'event_label': '${item.text}'})`"
      >
        <a class="read-also__link">
          {{ item.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'read-also',
  props: {
    list: Array,
  },
}
</script>

<style lang="scss" scoped>
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";
$fontB: "Raleway-Bold";

  .read-also {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 20px;
    align-items: flex-start;
    max-width: 886px;
    padding: 0 20px;
    margin: 40px auto;
    width: 100%;
    position: relative;
    z-index: 3;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        row-gap: 15px;
      }

    &__text {
      display: flex;
      align-items: center;
      padding-top: 12px;
      font-family: $fontM;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #4D4957;

      @media (max-width: 768px) {
        padding-top: 0;
        font-size: 14px;
        line-height: 18px;
      }

      svg {
        margin-left: 8px;
      
        @media (max-width: 768px) {
          transform: rotate(90deg);
          width: 14px;
        }
      }
    }

    &__list {
      list-style: none;
    }

    &__item {
      width: fit-content;
      padding: 7px 16px;
      margin-bottom: 15px;
      cursor: pointer;
      border: 1px solid #537FCB;
      border-radius: 30px;

      @media (max-width: 768px) {
        margin-bottom: 13px;
      }

      &:last-of-type  {
        margin-bottom: 0;
      }
    }

    &__link {
      text-decoration: none;
      font-family: $fontR;
      font-size: 16px;
      line-height: 175%;
      color: #537FCB;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .section3 .read-also__item:first-of-type {
    @media (max-width: 768px) {
      max-width: 82%;
    }
  }
  .section3 .read-also__item:nth-of-type(2) {
    @media (max-width: 768px) {
      min-width: 184px;
    }
    & > .read-also__link {
      white-space: nowrap;
    }
  }
  .section4 .read-also__item:first-of-type {
    @media (max-width: 768px) {
      max-width: 84%;
    }
  }
</style>
