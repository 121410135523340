<template>
  <div class="vocabulary">
    <div class="vocabulary__image">
      <slot name="image"></slot>
    </div>
    <div class="vocabulary__text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "vocabulary",
}
</script>

<style scoped lang="scss">
$fontR: "Raleway-Regular";
$fontB: "Raleway-Bold";

.vocabulary {
  display: flex;
  padding: 22px 28px;
  border-radius: 14px;
  background: #fff;

  @media (max-width: 768px) {
    padding: 16px;
  }

  &__image {
    min-width: 60px;
    margin-right: 12px;

    @media (max-width: 768px) {
      min-width: 48px;
      margin-right: 8px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__text {
    font-family: $fontR;
    font-size: 18px;
    line-height: 30px;
    color: #2E2E2E;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  strong {
    font-family: $fontB;
  }
}
</style>