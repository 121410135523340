<template>
  <div class="recomendations" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="recomendations/banner-desc.png" mobImg="recomendations/banner-mob.png">
      <template v-slot:link>Міжнародні рекомендації</template>
      <template v-slot:title>Міжнародні рекомендації</template>
      <template v-slot:text>
        Загальні рекомендації<br><br>

        Список позицій ВООЗ щодо вакцинації та вакцин (російською мовою)<br>
        Список позицій ВООЗ щодо вакцинації та вакцин (англійською мовою)...
      </template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Загальні рекомендації
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                БЦЖ
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Вітряна віспа
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-4" class="contents__link">
                Гемофільна інфекція типу b (ХІБ)
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-5" class="contents__link">
                Гепатит А
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-6" class="contents__link">
                Гепатит В
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-7" class="contents__link">
                Грип
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-8" class="contents__link">
                Дифтерія
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-9" class="contents__link">
                Жовта гарячка
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-10" class="contents__link">
                Кашлюк
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-11" class="contents__link">
                Кір
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-12" class="contents__link">
                Кліщовий енцефаліт
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-13" class="contents__link">
                Краснуха
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-14" class="contents__link">
                Менінгококова інфекція
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-15" class="contents__link">
                Папіломавірусна інфекція або вірус папіломи людини (ВПЛ)
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-16" class="contents__link">
                Паротит епідемічний
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-17" class="contents__link">
                Пневмококова інфекція
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-18" class="contents__link">
                Поліомієліт
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-19" class="contents__link">
                Правець
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-20" class="contents__link">
                Ротавірусна інфекція
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-21" class="contents__link">
                Сказ
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-22" class="contents__link">
                Холера
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-23" class="contents__link">
                Черевний тиф
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-24" class="contents__link">
                Японський енцефаліт
              </a>
            </li>
          </ul>
        </Contents>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Загальні рекомендації
          </h2>

          <li class="page-wrapper__item">
            Список позицій ВООЗ щодо вакцинації та вакцин (російською мовою)
          </li>
          <li class="page-wrapper__item">
            Список позицій ВООЗ щодо вакцинації та вакцин (англійською мовою)
          </li>
          <li class="page-wrapper__item">
            Рекомендації ВООЗ з планової імунізації, узагальнюючі таблиці (англійською мовою)
          </li>
          <li class="page-wrapper__item">
            Список Рекомендацій Центрів з контролю і профілактики захворювань США – the Advisory Committee on Immunization Practices (ACIP) Recommendations
          </li>
          <li class="page-wrapper__item">
            Загальні Рекомендації з імунізації США «General Recommendations on Immunization» (англійською мовою)
          </li>
          <li class="page-wrapper__item">
            Рекомендації з імунізації медичних працівників США «Immunization of Healthcare Workers» (англійською мовою)
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            БЦЖ
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (січень 2004) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (січень 2004) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (березень 2010) «BCG Vaccine Recommendations» 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-3">
          <h2 class="page-wrapper__h2-title">
            Вітряна віспа
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (серпень 1998) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (червень 2014 року) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (червень 2007) «Prevention of Varicella» 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-4">
          <h2 class="page-wrapper__h2-title">
            Гемофільна інфекція типу b (ХІБ)
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2013) російською мовою 
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2013) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            ХІБ (лютий 2014 року) – «Prevention and Control of Haemophilus influenzae Type b Disease: Recommendations of the Advisory Committee on Immunization Practices (ACIP)»  
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-5">
          <h2 class="page-wrapper__h2-title">
            Гепатит А
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2012) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2012) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (травень 2006) «Prevention of Hepatitis A Through Active or Passive Immunization» 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-6">
          <h2 class="page-wrapper__h2-title">
            Гепатит В
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2009) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2009) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Діти і підлітки – «A Comprehensive Immunization Strategy to Eliminate Transmission of Hepatitis B Virus Infection in the United States» (грудень 2005) 
          </li>
          <li class="page-wrapper__item">
            Дорослі – «A Comprehensive Immunization Strategy to Eliminate Transmission of Hepatitis B Virus Infection in the United States» (грудень 2006) 
          </li>
          <li class="page-wrapper__item">
            Дорослі з цукровим діабетом – «Use of Hepatitis B Vaccination for Adults with Diabetes Mellitus» (грудень 2011) 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-7">
          <h2 class="page-wrapper__h2-title">
            Грип
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (листопад 2012) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (листопад 2012) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (вересень 2013) «Prevention and Control of Seasonal Influenza with Vaccines: United States, 2014-2015» 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-8">
          <h2 class="page-wrapper__h2-title">
            Дифтерія
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (січень 2006) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (січень 2006) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Застосування вакцини від дифтерії–правця–кашлюка безклітинної у 5-дозовому графіку вакцинації (листопад 2000) – «Use of Diphtheria Toxoid-Tetanus Toxoid-Acellular Pertussis Vaccine as a Five-Dose Series»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від дифтерії–правця (знижена кількість анатоксину) – кашлюка безклітинної (січень 2011) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis (Tdap) Vaccine»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від дифтерії–правця (знижена кількість анатоксину) кашлюка безклітинної (Tdap) у вагітних або осіб, які мають або планують близьке контактування з немовлятами молодше 12 місяців (жовтень 2011) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis Vaccine (Tdap) in Pregnant Women and Persons Who Have or Anticipate Having Close Contact with an Infant Aged &lt;12 Months»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від правця–дифтерії (знижена кількість антигену) – кашлюка безклітинної (Tdap) у вагітних (лютий 2013) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid, and Acellular Pertussis Vaccine (Tdap) in Pregnant Women »
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від правця–дифтерії (знижена кількість антигену) – кашлюка безклітинної (Tdap) у людей 65 років і старше (червень 2012) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid, and Acellular Pertussis (Tdap) Vaccine in Adults Aged 65 Years and Older»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-9">
          <h2 class="page-wrapper__h2-title">
            Жовта гарячка
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2003) російською мовою 
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2013) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (липень 2010) «Yellow Fever Vaccine»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-10">
          <h2 class="page-wrapper__h2-title">
            Кашлюк
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2010) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ ВООЗ: Переглянуті рекомендації з вибору вакцин від кашлюка (липень 2014 року) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2010) англійською і французькою мовами (березень 1997) – «Pertussis Vaccination: Use of Acellular Pertussis Vaccines Among Infants and Young Children»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від дифтерії–правця (знижена кількість анатоксину) – кашлюка безклітинної (січень 2011) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis (Tdap) Vaccine»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцин від дифтерії–правця (знижена кількість анатоксину) – кашлюка безклітинної (Tdap) у вагітних або осіб, які мають або планують близьке контактування з немовлятами молодше 12 місяців (жовтень 2011) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis Vaccine (Tdap) in Pregnant Women and Persons Who Have or Anticipate Having Close Contact with an Infant Aged &lt;12 Months»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від правця–дифтерії (знижена кількість антигену) – кашлюка безклітинної (Tdap) у вагітних (лютий 2013) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid, and Acellular Pertussis Vaccine (Tdap) in Pregnant Women»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцин від правця–дифтерії (знижена кількість антигену) – кашлюка безклітинної (Tdap) у людей 65 років і старше (червень 2012) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid, and Acellular Pertussis (Tdap) Vaccine in Adults Aged 65 Years and Older »
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-11">
          <h2 class="page-wrapper__h2-title">
            Кір
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (серпень 2009) російською мовою 
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (серпень 2009) англійською та французькою мовою
          </li>
          <li class="page-wrapper__item">
            Профілактика кору, краснухи, вродженої краснухи та паротиту (червень 2013) – «Prevention of Measles, Rubella, Congenital Rubella Syndrome, and Mumps»
          </li>
          <li class="page-wrapper__item">
            Застосування комбінованої вакцини від кору, паротиту, краснухи та вітряної віспи (травень 2010) – «Use of Combination Measles, Mumps, Rubella, and Varicella Vaccine»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-12">
          <h2 class="page-wrapper__h2-title">
            Кліщовий енцефаліт
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (червень 2011) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (червень 2011) англійською і французькою мовами
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-13">
          <h2 class="page-wrapper__h2-title">
            Краснуха
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2011) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (липень 2011) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Профілактика кору, краснухи, вродженої краснухи та паротиту (червень 2013) – «Prevention of Measles, Rubella, Congenital Rubella Syndrome, and Mumps»
          </li>
          <li class="page-wrapper__item">
            Застосування комбінованої вакцини від кору, паротиту, краснухи та вітряної віспи (травень 2010) – «Use of Combination Measles, Mumps, Rubella, and Varicella Vaccine»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-14">
          <h2 class="page-wrapper__h2-title">
            Менінгококова інфекція
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ щодо менінгококових кон'югованих вакцин групи А (лютий 2015) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ щодо менінгококових кон'югованих вакцин групи А (лютий 2015) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (листопад 2011) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (березень 2013) «Prevention and Control of Meningococcal Disease»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-15">
          <h2 class="page-wrapper__h2-title">
            Папіломавірусна інфекція або вірус папіломи людини (ВПЛ)
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2014 року) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (жовтень 2014 року) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Використання 9-валентної ВПЛ-вакцини (березень 2015) – Use of 9-Valent Human Papillomavirus (HPV) Vaccine: Updated HPV Vaccination Recommendations of the Advisory Committee on Immunization Practices
          </li>
          <li class="page-wrapper__item">
            Вакцинація від ВПЛ (серпень 2014 року) – Human Papillomavirus Vaccination: Recommendations of the Advisory Committee on Immunization Practices (ACIP)
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-16">
          <h2 class="page-wrapper__h2-title">
            Паротит епідемічний
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2007) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2007) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Профілактика кору, краснухи, вродженої краснухи та паротиту (червень 2013) – «Prevention of Measles, Rubella, Congenital Rubella Syndrome, and Mumps»
          </li>
          <li class="page-wrapper__item">
            Застосування комбінованої вакцини від кору, паротиту, краснухи та вітряної віспи (травень 2010) – «Use of Combination Measles, Mumps, Rubella, and Varicella Vaccine»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-17">
          <h2 class="page-wrapper__h2-title">
            Пневмококова інфекція
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (квітень 2012) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції щодо 23-валентної полісахаридної пневмококової вакцини (жовтень 2008) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (квітень 2012) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Документ з позиції щодо 23-валентної полісахаридної пневмококової вакцини (жовтень 2008) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Профілактика цих інфекцій у немовлят і дітей – Використання 13-ПКВ і 23-ППВ (грудень 2010) – «Prevention of Pneumococcal Disease Among Infants and Children – Use of PCV-13 & PPSV-23»
          </li>
          <li class="page-wrapper__item">
            Застосування 13-ПКВ і 23-ППВ вакцин у дітей 6-18 років з імунокомпроментуючими станами (червень 2013) – «Use of PCV-13 and PPSV-23 Vaccines Among Children Aged 6-18 Years with Immunocompromising Conditions»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з профілактики інвазивних пневмококових інфекцій у дорослих із застосуванням 23-валентної пневмококової вакцини 23-ППВ (вересень 2010) – «Updated Recommendations for Prevention of Invasive Pneumococcal Disease Among Adults Using the 23-Valent Pneumococcal Polysaccharide Vaccine (PPSV-23)»
          </li>
          <li class="page-wrapper__item">
            Застосування 13-ПКВ і 23-ППВ вакцин для дорослих з імунокомпроментуючими станами (жовтень 2012) – «Use of PCV-13 and PPSV-23 Vaccine for Adults with Immunocompromising Conditions»
          </li>
          <li class="page-wrapper__item">
            Застосування 13-ПКВ і 23-ППВ у людей ≥65 років (вересень 2014 року) – Use of 13-Valent Pneumococcal Conjugate Vaccine and 23-Valent Pneumococcal Polysaccharide Vaccine Among Adults Aged ≥65 Years: Recommendations of the Advisory Committee on Immunization Practices (ACIP )
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-18">
          <h2 class="page-wrapper__h2-title">
            Поліомієліт
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2014 року) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2014 року) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (серпень 2009) – «Updated Recommendations of the Advisory Committee on Immunization Practices (ACIP) Regarding Routine Poliovirus Vaccination»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-19">
          <h2 class="page-wrapper__h2-title">
            Правець
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (травень 2006) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (травень 2006) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Використання вакцини від дифтерії–правця–кашлюка безклітинної в 5-дозовому графіку вакцинації (листопад 2000) – «Use of Diphtheria Toxoid-Tetanus Toxoid-Acellular Pertussis Vaccine as a Five-Dose Series»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації із застосування вакцини від дифтерії–правця (знижена кількість анатоксину) – кашлюка безклітинної (січень 2011) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis (Tdap) Vaccine»
          </li>
          <li class="page-wrapper__item">
            {{`Оновлені рекомендації з використання вакцини від дифтерії–правця (знижена кількість анатоксину) – кашлюка безклітинної (Tdap) у вагітних або осіб, які мають або планують близьке контактування з немовлятами молодше 12 місяців (жовтень 2011) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis Vaccine (Tdap) in Pregnant Women and Persons Who Have or Anticipate Having Close Contact with an Infant Aged &lt;12 Months»`}}
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від правця–дифтерії (знижена кількість антигену) – кашлюка безклітинної (Tdap) у вагітних (лютий 2013) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid, and Acellular Pertussis Vaccine (Tdap) in Pregnant Women»
          </li>
          <li class="page-wrapper__item">
            Оновлені рекомендації з використання вакцини від правця–дифтерії (знижена кількість антигену) – кашлюка безклітинної (Tdap) у людей 65 років і старше (червень 2012) – «Updated Recommendations for Use of Tetanus Toxoid, Reduced Diphtheria Toxoid, and Acellular Pertussis (Tdap) Vaccine in Adults Aged 65 Years and Older»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-20">
          <h2 class="page-wrapper__h2-title">
            Ротавірусна інфекція
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (січень 2013) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (січень 2013) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (лютий 2009) – «Prevention of Rotavirus Gastroenteritis Among Infants & Children»
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-21">
          <h2 class="page-wrapper__h2-title">
            Сказ
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (серпень 2010) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (серпень 2010) англійською та французькою мовою
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (березень 2010) «Use of a Reduced (4-Dose) Vaccine Schedule for Postexposure Prophylaxis to Prevent Human Rabies» 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-22">
          <h2 class="page-wrapper__h2-title">
            Холера
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (березень 2010) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (березень 2010) англійською і французькою мовами
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-23">
          <h2 class="page-wrapper__h2-title">
            Черевний тиф
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2008) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2008) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (березень 2015) «Updated Recommendations for the Use of Typhoid Vaccine – Advisory Committee on Immunization Practices, United States,–2015» 
          </li>
        </ul>

        <ul class="page-wrapper__section page-wrapper__text wi-flu__section" id="stage-24">
          <h2 class="page-wrapper__h2-title">
            Японський енцефаліт
          </h2>

          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (серпень 2006) російською мовою
          </li>
          <li class="page-wrapper__item">
            Документ з позиції ВООЗ (лютий 2015) англійською і французькою мовами
          </li>
          <li class="page-wrapper__item">
            Рекомендації США (березень 2010) – «Japanese Encephalitis Vaccines»
          </li>
        </ul>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <p class="list">MAT-UA-2101723 (дата першого застосування 20.11.2021)</p>

    <MedText />

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Загальні рекомендації",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "БЦЖ",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Вітряна віспа",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-3"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Гемофільна інфекція типу b (ХІБ)",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-4"
      },{
        "@type": "ListItem",
        "position": 5,
        "name": "Гепатит А",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-5"
      },{
        "@type": "ListItem",
        "position": 6,
        "name": "Гепатит B",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-6"
      },{
        "@type": "ListItem",
        "position": 7,
        "name": "Грип",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-7"
      },{
        "@type": "ListItem",
        "position": 8,
        "name": "Дифтерія",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-8"
      },{
        "@type": "ListItem",
        "position": 9,
        "name": "Жовта гарячка",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-9"
      },{
        "@type": "ListItem",
        "position": 10,
        "name": "Кашлюк",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-10"
      },{
        "@type": "ListItem",
        "position": 11,
        "name": "Кір",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-11"
      },{
        "@type": "ListItem",
        "position": 12,
        "name": "Кліщовий енцефаліт",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-12"
      },{
        "@type": "ListItem",
        "position": 13,
        "name": "Краснуха",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-13"
      },{
        "@type": "ListItem",
        "position": 14,
        "name": "Менінгококова інфекція",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-14"
      },{
        "@type": "ListItem",
        "position": 15,
        "name": "Папіломавірусна інфекція або вірус папіломи людини (ВПЛ)",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-15"
      },{
        "@type": "ListItem",
        "position": 16,
        "name": "Паротит епідемічний",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-16"
      },{
        "@type": "ListItem",
        "position": 17,
        "name": "Пневмококова інфекція",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-17"
      },{
        "@type": "ListItem",
        "position": 18,
        "name": "Поліомієліт",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-18"
      },{
        "@type": "ListItem",
        "position": 19,
        "name": "Правець",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-19"
      },{
        "@type": "ListItem",
        "position": 20,
        "name": "Ротавірусна інфекція",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-20"
      },{
        "@type": "ListItem",
        "position": 21,
        "name": "Сказ",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-21"
      },{
        "@type": "ListItem",
        "position": 22,
        "name": "Холера",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-22"
      },{
        "@type": "ListItem",
        "position": 23,
        "name": "Черевний тиф",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-23"
      },{
        "@type": "ListItem",
        "position": 24,
        "name": "Японський енцефаліт",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/international-recomendations#stage-24"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';

export default {
  name: "recomendations",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    MedText,
    SimilarArticles,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
.recomendations {
  background: #f8f8f8;
  //overflow-x: hidden;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  p.list {
    text-align: end;
    color: #ACACAC;
    font-family: 'Raleway-Regular';
    padding-right: 10px;
    max-width: 1300px;
    margin: 100px auto 0 auto;
    font-size: 12px;
  }

  .page-wrapper__item {
    list-style-type: none;
    margin-left: 26px;
    position: relative;
    &::before {
      content: "•";
      color: #2E2E2E;
      left: -16px;
      font-size: 20px;
      position: absolute;
    }
  }

  .bottom-block {
    margin-top: 14px;

    /*@media (max-width: 768px) {
      margin-top: 80px;
    }*/
  }
}
</style>
