import { render, staticRenderFns } from "./vaccination-adults.vue?vue&type=template&id=a846fc56&scoped=true&"
import script from "./vaccination-adults.vue?vue&type=script&lang=js&"
export * from "./vaccination-adults.vue?vue&type=script&lang=js&"
import style0 from "./vaccination-adults.vue?vue&type=style&index=0&id=a846fc56&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a846fc56",
  null
  
)

export default component.exports