<template>
  <div class="links-wrapper">
      <div class="content">
        <div
          class="show-hide-btn"
          @click="showLinks = !showLinks"
          v-bind:class="[{ active: showLinks }]"
        >
          Джерела
          <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.43538 6.83282C6.21024 7.05573 5.8465 7.05573 5.62078 6.83282L0.272368 1.54254C-0.0533546 1.22076 -0.0533546 0.697219 0.272368 0.374864C0.598092 0.0530806 1.12673 0.0530806 1.45246 0.374864L6.02837 4.90041L10.6031 0.374864C10.9294 0.0530806 11.4581 0.0530806 11.7838 0.374864C12.1095 0.697219 12.1095 1.22076 11.7838 1.54254L6.43538 6.83282Z" fill="#2E2E2E"/>
          </svg>

        </div>
        <div v-bind:class="[{ active: showLinks }]" class="hide-content">
          <slot></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Links",
  data: () => ({
    showLinks: false,
  }),
}
</script>

<style scoped lang="scss">
$fontR: "Raleway-Regular";
$fontB: "Raleway-Bold";

.links-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 3;

  .content {
    max-width: 1300px;
    width: 100%;
    padding: 30px 10px 30px 10px;

    .show-hide-btn {
      cursor: pointer;
      color: #2E2E2E;
      font-family: $fontB;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      font-size: 8px;
      line-height: 12px;
      display: flex;
      align-items: center;
      margin: 10px 0;

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }

      svg {
        transition: transform .2s ease-in;
        margin-left: 7px;
        display: block;
        @media (max-width: 768px) {
          margin-left: 17px;
        }
      }
    }

    .hide-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height .5s linear;

      &.active {
        max-height: 1220px;
        height: 100%;
        overflow: visible;
        @media (max-width: 768px) {
          max-height: fit-content;
        }
      }

      .list {
        color: #3F4C56;
        font-family: $fontR;
        font-size: 12px;
        margin-bottom: 8px;

        @media screen and (max-width: 425px) {
          font-size: 12px;
        }

        a {
          text-decoration: none;
          // border-bottom: 1px solid;
          color: rgba(83, 127, 203, 0.75);
        }

        span {
          font-family: $fontB;
        }
      }

      p.list {
        text-align: end;
        color: #ACACAC;
        margin-top: 12px;
        font-size: 12px;
      }

      ol {
        list-style: decimal;
        padding-left: 22px;
      }
    }
  }
}
</style>