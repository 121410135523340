<template>
  <div class="banner">
    <img class="banner__img desc" :src="require(`@/assets/img_const-pages/${descImg}`)"/>
    <div class="banner__content">
      <div class="banner__links">
        <a class="banner__link">Головна / </a>
        <a class="banner__link">Статті / </a>
        <a class="banner__link current" href="#"><slot name="link"></slot></a>
      </div>
      <h1 class="banner__title"><slot name="title"></slot></h1>
      <div class="banner__divider"></div>
      <img class="banner__img mob" :src="require(`@/assets/img_const-pages/${mobImg}`)"/>
      <p class="banner__text"><slot name="text"></slot></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'banner',
  props: {
    descImg: {
      type: String,
      required: false
    },
    mobImg: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
$fontB: "Raleway-Bold";
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";

.banner {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 180px;
  margin-top: 50px;
  max-width: 100vw;

  @media (min-width: 1500px) {
    padding-bottom: 20%;
  }
  @media (max-width: 768px) {
    height: auto;
    padding-bottom: 0;
    margin-top: 40px;
  }

  &__img {
    position: absolute;
    object-fit: cover;
    object-position: top;
    height: 100%;
    width: 100%;
    z-index: -1;

    &.mob {
      display: none;
      @media (max-width: 768px) {
        display: block;
        position: relative;
        z-index: 1;
        width: 100vw;
        transform: translateX(-10px);
      }

      // @media (max-width: 768px) {
      //   transform: translateX(-16px);
      // }
    }

    &.desc {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__content {
    max-width: 1300px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
  }

  &__title {
    font-family: $fontB;
    font-size: 40px;
    line-height: 47px;
    color: #FFFFFF;
    width: 40vw;
    max-width: 572px;
    @media (max-width: 1080px) {
      font-size: 32px;
      line-height: 39px;
      width: 44vw;
    }
    @media (max-width: 768px) {
      color: #515BA2;
      width: 100%;
    }
    @media (max-width: 400px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  &__divider {
    width: 17px;
    height: 1px;
    background-color: #FFFFFF;
    margin-top: 48px;
    margin-bottom: 40px;
    @media (max-width: 1080px) {
      margin-top: 24px;
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      background-color: #515BA2;
      margin-top: 16px;
      margin-bottom: 50px;
      width: 48px;
    }
    @media (max-width: 400px) {
      margin-top: 8px;
      margin-bottom: 25px;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontM;
    color: #FFFFFF;
    width: 40vw;
    max-width: 572px;
    @media (max-width: 1080px) {
      font-size: 18px;
      line-height: 24px;
      width: 44vw;
    }
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
      color: #2E2E2E;
      width: 100%;
      margin-top: 32px;
      margin-bottom: 64px;
    }
    @media (max-width: 400px) {
      font-size: 18px;
      line-height: 25px;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  &__links {
    margin-top: 104px;
    margin-bottom: 47px;
    @media (max-width: 1080px) {
      margin-top: 52px;
      margin-bottom: 24px;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &__link {
    font-family: $fontB;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    @media (max-width: 768px) {
      color: rgba(81, 91, 162, 0.5);
      width: 100%;
    }

    &.current {
      color: #FFFFFF;
      @media (max-width: 768px) {
        color: #515BA2;
      }
    }
  }
}

</style>
