import Vue from 'vue'
import Vuex from 'vuex'
import {locale} from "moment/moment";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cookie: true,
  },
  getters: {
    getCookie(state) {
      return state.cookie
    }
  },
  mutations: {
    cookie(state) {
      state.cookie = !state.cookie;
      localStorage.setItem("cookie", "true")
    }
  },
  actions: {
    cookieAccept(context, links) {
      context.commit('cookie');
    }
  },
  modules: {}
})
