<template>
  <div class="infection" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="infection-in-school/banner-desc.png" mobImg="infection-in-school/banner-mob.png">
      <template v-slot:link>Що потрібно знати про дитячі інфекції у школі</template>
      <template v-slot:title>Що потрібно знати про дитячі інфекції у школі</template>
      <template v-slot:text>Ваша дитина пішла у школу: як допомогти їй швидко адаптуватися до нової реальності?</template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Турбота про здоров'я дитини в перші шкільні роки – це важливий і відповідальний етап для батьків і дітей
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                Від яких інфекцій дитина повинна бути щеплена до 6-7 років?
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Чим небезпечні кашлюк, дифтерія та правець?
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-4" class="contents__link">
                Навіщо потрібна ревакцинація перед вступом до школи?<sup>12</sup>
              </a>
            </li>
          </ul>
        </Contents>
        <section class="page-wrapper__section infection__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Турбота про здоров'я дитини в перші шкільні роки — це важливий і відповідальний етап для батьків і дітей.
          </h2>

          <p class="page-wrapper__text">
            Спостереження за першокласниками показали, що адаптація до школи триває від 2-3 тижнів до півроку і залежить від багатьох причин.
          </p>

          <img class="infection__img" src="@/assets/img_const-pages/infection-in-school/turbota.png" alt="" />

          <p class="page-wrapper__text">
            У цей період важливо, щоб організм дитини був підготовлений до нових умов, адже в момент контакту з новими людьми ризик зараження захворюваннями, що передаються повітряно-крапельним шляхом, найвищий.<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Вакцинація покликана допомогти запобігти цьому.
          </p>
          <p class="page-wrapper__text">
            Тому своєчасна допомога імунітету насамперед спрямована на захист від інфекцій, з якими ми стикаємося в повсякденному житті.
          </p>
          <Warning imgColor="#515BA2" borderColor="#515BA2" class="schema-vac">
            <template v-slot:text>!! Важливо пам'ятати, що визначити правильну схему вакцинації та у разі необхідності дати відповідні рекомендації може тільки лікар.</template>
          </Warning>
        </section>
        <section class="page-wrapper__section infection__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            Від яких інфекцій дитина повинна бути щеплена до 6-7 років?
          </h2>

          <p class="page-wrapper__text">
            Наразі в Україні обов'язковою є вакцинація дітей від 10 небезпечних хвороб: <sup>2,3</sup>
          </p>

          <div class="infection__vac vac">
            <ul class="vac__list">
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Туберкульоз</h5>
                  <p class="vac__text">1 щеплення</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Гепатит В</h5>
                  <p class="vac__text">3 щеплення</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol3.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Гемофільна <br> інфекція типу b</h5>
                  <p class="vac__text">3 щеплення</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol3.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Поліомієліт</h5>
                  <p class="vac__text">5 щеплень</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol5.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Кашлюк</h5>
                  <p class="vac__text">4 щеплення</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol4.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Дифтерія</h5>
                  <p class="vac__text">5 щеплень</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol5.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Правець</h5>
                  <p class="vac__text">5 щеплень</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol5.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">Кір, епідемічний паротит, краснуха</h5>
                  <p class="vac__text">2 щеплення</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol2.svg">
                </div>
              </li>
            </ul>
          </div>

          <p class="page-wrapper__text">
            Перед вступом до школи та у шкільні роки дітям рекомендується проводити ревакцинацію від таких інфекцій:
          </p>

          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">Правець,</li>
            <li class="page-wrapper__item">Дифтерія,</li>
            <li class="page-wrapper__item">Кір,</li>
            <li class="page-wrapper__item">Епідемічний паротит,</li>
            <li class="page-wrapper__item">Краснуха,</li>
            <li class="page-wrapper__item">Поліомієліт</li>
          </ul>
        </section>

        <section id="stage-3" class="infection__section">
          <div class="disease">
            <div class="disease__wrapper">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30" cy="30" r="29" stroke="#515BA2" stroke-width="2"/>
                <rect x="31" y="23.7999" width="8.8" height="26.2" rx="4.4" stroke="#515BA2" stroke-width="2"/>
                <rect x="22.9444" y="19.3426" width="8.8" height="24.055" rx="4.4" transform="rotate(26.6834 22.9444 19.3426)" stroke="#515BA2" stroke-width="2"/>
                <rect x="41.9386" y="12.6483" width="6.82129" height="17.9339" rx="3.41064" transform="rotate(105.579 41.9386 12.6483)" stroke="#515BA2" stroke-width="2"/>
              </svg>
              <h4 class="disease__title">Кашлюк</h4>
            </div>
            <p class="disease__text page-wrapper__text">Кашлюк — гостра повітряно-крапельна бактеріальна інфекція, що проявляється тривалими і болісними нападами спазматичного кашлю і інтоксикацією.<sup>4</sup></p>
          </div>

          <p class="page-wrapper__text">
            Заразитися кашлюком дитина може тільки від хворої людини.<sup>7</sup><br>
            <span></span>
            Інфекція передається повітряно-крапельним шляхом під час:<sup>4</sup><br>
            <span></span>
            Чхання, Кашлю, Сміху
          </p>

          <Warning imgColor="#BCA36A" borderColor="#BFBFBF" class="cashluc-warning">
            <template v-slot:text>Якщо серед 100 нещеплених буде 1 людина, заражена кашлюком, близько 90 осіб захворіють!<sup>7</sup></template>
          </Warning>

          <Warning imgColor="#BCA36A" borderColor="#BFBFBF">
            <template v-slot:text>Хворий на кашлюк є джерелом інфекції для оточуючих з першого дня і до 30 діб хвороби<sup>8</sup></template>
          </Warning>

          <p class="page-wrapper__text">
            <strong>В Україні протягом останніх років значно збільшилась захворюваність на кашлюк, особливо серед дітей дошкільного та шкільного віку.<sup>9</sup></strong>
            Причиною цього є зниження поствакцинального імунітету, що сприяє відновленню сприйнятливості до кашлюку, починаючи з 5-7 років.<br>
            <span></span>
            Найбільше схильні до захворювання діти, проте у школі джерелами інфекції часто бувають дорослі: вчителі або обслуговуючий персонал.
          </p>

          <Warning imgColor="#BCA36A" borderColor="#BFBFBF" class="flu-warning">
            <template v-slot:text>Кашель може тривати більше 3 місяців<sup>4</sup><br> Дитина може перебувати на домашньому або лікарняному режимі 21 день</template>
          </Warning>

          <h3 class="page-wrapper__subtitle">Симптоми</h3>
          <p class="page-wrapper__text after-subtitle"> Перший період захворювання триває 2 тижні.<sup>4</sup></p>

          <div class="symptoms symptoms-top">
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Підвищується<br> температура</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/temp.svg">
            </div>
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Спостерігається <br> загальна слабкість</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/bed.svg">
            </div>
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Схоже на класичну<br> ГРВІ з рясними <br> виділеннями з носа</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/grvi.svg">
            </div>
          </div>

            <div class="symptoms-bottom symptoms">
            <div class="symptoms-bottom__item symptoms__item">
              <div class="symptoms-bottom__wrapper">
                <h3 class="symptoms__title symptoms-bottom__title">Потім кашель <br> посилюється і набуває<br> спазматичного характеру</h3>
                <p class="symptoms__text symptoms-bottom__text">Болісні напади кашлю нерідко<br> провокують блювання.<sup>8</sup></p>
              </div>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/flu.svg">
            </div>
            <div class="symptoms-bottom__item symptoms__item">
              <div class="symptoms-bottom__wrapper">
                <h3 class="symptoms-bottom__title symptoms__title">Найбільшу небезпеку<br> становлять ускладнення<br> кашлюку, серед яких</h3>
                <p class="symptoms-bottom__text symptoms__text">пневмонія, судоми, порушення<br> функції легень.</p>
              </div>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/pnev.svg">
            </div>
          </div>

          <img class="infection__img" src="@/assets/img_const-pages/infection-in-school/cashluk.png" alt="" />

          <p class="page-wrapper__text">
            <strong>Діти 1-го року життя залишаються найвразливішою групою, у якій спостерігається найвищий рівень захворюваності і частота ускладнень.<sup>7</sup></strong>
          </p>
          <p class="page-wrapper__text">
            За даними експертів, багато дітей отримують повний курс первинної вакцинації від кашлюку несвоєчасно — лише до досягнення віку 12 місяців. До отримання вакцини організм новонародженого не здатний ефективно боротися з інфекцією, і найбільша кількість летальних випадків спостерігається серед дітей до 1 року.<sup>11</sup><br>
            <span></span>
            У ~80% випадків основним джерелом зараження кашлюком для малюків до 1 року є діти старшого віку, підлітки та дорослі (старші брати і сестри, батьки, близькі родичі).<sup>10</sup>
          </p>

          <div class="recommend  recommend-revac">
            <img class="recommend__img" src="@/assets/img_const-pages/infection-in-school/dod-revac.svg"/>
            <p class="recommend__text">Додаткова ревакцинація проти кашлюку дає змогу захистити дитину від небезпечної інфекції та перешкоджає передаванню кашлюку найбільш вразливій групі — дітям до 1 року, зменшуючи ризик їх зараження!<br> Запобігти хворобі можна за допомогою щеплення.<sup>9,11</sup></p>
          </div>

          <h3 class="page-wrapper__subtitle">Діагностика кашлюку</h3>
          <p class="page-wrapper__text after-subtitle">
            У разі появи кашлю слід звертатися до лікаря для виключення ГРВІ, бронхіту та інших захворювань. Запідозривши кашлюк, лікар візьме аналіз крові на антитіла до збудника кашлюку і/або мазок з носоглотки на кашлюк. Може знадобитися рентгенографія легень.<br>
            <span></span>
            Пам'ятайте! На кашлюк можуть захворіти люди будь-якого віку і стати джерелом інфекції для дитини!
          </p>

          <div class="disease disease-dift">
            <div class="disease__wrapper">
              <svg width="60" height="63" viewBox="0 0 60 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30" cy="30" r="29" stroke="#515BA2" stroke-width="2"/>
                <path d="M25.2188 18.837C27.8598 19.5401 30.5707 17.9691 31.2738 15.3281C31.9769 12.6871 30.4059 9.97615 27.7649 9.27306C25.1239 8.56997 22.4129 10.141 21.7098 12.782" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.9067 24.3225C9.67399 24.8009 8 26.7856 8 29.1612C8 31.8942 10.2155 34.1097 12.9485 34.1097C15.6815 34.1097 17.8971 31.8942 17.8971 29.1612C17.8971 28.6056 17.8055 28.0713 17.6366 27.5727" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.5436 13.0132C18.3829 15.0902 14.772 18.7431 12.1675 24.2125M24.9295 18.7431C21.2715 20.9438 18.7364 24.9082 17.6369 27.3379" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.4659 27.4823C28.035 26.2333 25.0519 27.1915 23.8029 29.6225C22.554 32.0534 23.5122 35.0365 25.9431 36.2855C28.3741 37.5344 31.3572 36.5762 32.6061 34.1453" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M44.6407 24.9549C46.9241 24.9625 48.9821 23.3795 49.4876 21.0583C50.0691 18.3878 48.3757 15.7516 45.7053 15.1701C43.0349 14.5886 40.3987 16.282 39.8171 18.9524C39.6989 19.4953 39.6747 20.0368 39.7336 20.5599" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.8181 33.9547C36.3483 32.5977 40.6538 29.7969 44.3625 25.0069M30.7289 27.6356C34.7714 26.2636 38.0919 22.9294 39.6833 20.7893" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.3881 42.331C23.0346 39.9567 20.0126 39.1291 17.6383 40.4827C15.264 41.8362 14.4365 44.8581 15.79 47.2324C17.1435 49.6067 20.1655 50.4343 22.5398 49.0807" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M37.6405 47.9592C39.5487 49.2133 42.1373 49.012 43.8289 47.3441C45.7751 45.4253 45.7973 42.2922 43.8786 40.346C41.9598 38.3998 38.8267 38.3776 36.8805 40.2964C36.4848 40.6865 36.1687 41.1267 35.9322 41.597" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22.8211 49.0371C26.5192 49.8296 31.6555 49.8364 37.3789 47.8511M24.5242 42.6032C28.6595 43.663 33.2624 42.6849 35.7645 41.7621" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <h4 class="disease__title">Дифтерія</h4>
            </div>
            <p class="disease__text page-wrapper__text">Дифтерія<sup>5</sup> — це тяжка і небезпечна для життя бактеріальна інфекція, що спричиняється дифтерійною паличкою. Хвороба протікає у вигляді гострого запалення ротоглотки, але нерідко зачіпає гортань, бронхи, шкіру та інші органи.</p>
          </div>

          <h3 class="page-wrapper__subtitle peredacha">Основні шляхи передачі:</h3>
          <div class="symptoms symptoms-top">
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Повітряно-крапельний</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/pov-crap.svg">
            </div>
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Предмети та іграшки</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/predmety.svg">
            </div>
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Посуд</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/posud.svg">
            </div>
          </div>

          <h3 class="page-wrapper__subtitle uskladnennia">Ускладнення:</h3>
          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">Початкову стадію можна переплутати з ангіною</li>
            <li class="page-wrapper__item"> Небезпечна ураженням серця, нирок і нервової системи, може спричинити задуху</li>
            <li class="page-wrapper__item">Летальність до 10%</li>
          </ul>

          <div class="disease">
            <div class="disease__wrapper">
              <svg width="60" height="63" viewBox="0 0 60 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30" cy="30" r="29" stroke="#515BA2" stroke-width="2"/>
                <path d="M25.2188 18.837C27.8598 19.5401 30.5707 17.9691 31.2738 15.3281C31.9769 12.6871 30.4059 9.97615 27.7649 9.27306C25.1239 8.56997 22.4129 10.141 21.7098 12.782" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.9067 24.3225C9.67399 24.8009 8 26.7856 8 29.1612C8 31.8942 10.2155 34.1097 12.9485 34.1097C15.6815 34.1097 17.8971 31.8942 17.8971 29.1612C17.8971 28.6056 17.8055 28.0713 17.6366 27.5727" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.5436 13.0132C18.3829 15.0902 14.772 18.7431 12.1675 24.2125M24.9295 18.7431C21.2715 20.9438 18.7364 24.9082 17.6369 27.3379" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.4659 27.4823C28.035 26.2333 25.0519 27.1915 23.8029 29.6225C22.554 32.0534 23.5122 35.0365 25.9431 36.2855C28.3741 37.5344 31.3572 36.5762 32.6061 34.1453" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M44.6407 24.9549C46.9241 24.9625 48.9821 23.3795 49.4876 21.0583C50.0691 18.3878 48.3757 15.7516 45.7053 15.1701C43.0349 14.5886 40.3987 16.282 39.8171 18.9524C39.6989 19.4953 39.6747 20.0368 39.7336 20.5599" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.8181 33.9547C36.3483 32.5977 40.6538 29.7969 44.3625 25.0069M30.7289 27.6356C34.7714 26.2636 38.0919 22.9294 39.6833 20.7893" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.3881 42.331C23.0346 39.9567 20.0126 39.1291 17.6383 40.4827C15.264 41.8362 14.4365 44.8581 15.79 47.2324C17.1435 49.6067 20.1655 50.4343 22.5398 49.0807" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M37.6405 47.9592C39.5487 49.2133 42.1373 49.012 43.8289 47.3441C45.7751 45.4253 45.7973 42.2922 43.8786 40.346C41.9598 38.3998 38.8267 38.3776 36.8805 40.2964C36.4848 40.6865 36.1687 41.1267 35.9322 41.597" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22.8211 49.0371C26.5192 49.8296 31.6555 49.8364 37.3789 47.8511M24.5242 42.6032C28.6595 43.663 33.2624 42.6849 35.7645 41.7621" stroke="#515BA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <h4 class="disease__title">Правець</h4>
            </div>
            <p class="disease__text page-wrapper__text">Правець<sup>6</sup> — вкрай небезпечне інфекційне захворювання бактеріальної природи. Збудник, правцева паличка, живе в кишечнику тварин і після потрапляння в ґрунт трансформується у спори.</p>
          </div>

          <h3 class="page-wrapper__subtitle peredacha">Основні шляхи передачі:</h3>
          <div class="symptoms symptoms-top">
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Контактні шляхи</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/tap.svg">
            </div>
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Забруднення рани</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/spot.svg">
            </div>
            <div class="symptoms__item symptoms-top__item">
              <h3 class="symptoms__title symptoms-top__title">Пластир</h3>
              <img class="symptoms__img" src="@/assets/img_const-pages/infection-in-school/patch.svg">
            </div>
          </div>

          <h3 class="page-wrapper__subtitle uskladnennia">Ускладнення:</h3>
          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">Тяжкий перебіг з ураженням нервової системи, судомами</li>
            <li class="page-wrapper__item">Летальність від 10% до 70%</li>
          </ul>

          <div class="recommend">
            <img class="recommend__img" src="@/assets/img_const-pages/infection-in-school/dod-revac.svg"/>
            <p class="recommend__text">Слід регулярно (кожні 10 років) проводити ревакцинацію від дифтерії та правця згідно з національним календарем щеплень.<sup>2</sup></p>
          </div>
        </section>

        <section id="stage-4" class="page-wrapper__section before-school__section">
          <h2 class="page-wrapper__h2-title">
            Навіщо потрібна ревакцинація перед вступом до школи?<sup>12</sup>
          </h2>
          <p class="page-wrapper__text">Похід у перший клас — велике навантаження для дитини, стрес і період адаптації ослаблюють її імунітет.</p>
          <h3 class="page-wrapper__subtitle">Ревакцинація дітей дошкільного та шкільного віку допомагає:</h3>
          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">захистити вашу дитину від небезпечних інфекцій;</li>
            <li class="page-wrapper__item">запобігти пропускам занять, уникнути необхідності брати лікарняний для догляду за дитиною і бути відсутнім на роботі;</li>
            <li class="page-wrapper__item">знизити ризик зараження інфекціями найвразливішої ​​групи дітей – малюків першого року життя.</li>
          </ul>
          <p class="page-wrapper__text">Зменшення ризику інфікування є важливою умовою для здорового розвитку ваших дітей. Завжди краще запобігти можливості захворіти, ніж чекати і сподіватися, що проблема обійде вас стороною.</p>
          <p class="page-wrapper__text"> Представлена ​​інформація не замінює консультацію лікаря, будь ласка, зверніться до Вашого педіатра.</p>

          <a class="center-vac" href="#"> Карта центрів вакцинації
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 11L15 6L10 1" stroke="white"/>
              <path d="M0 6L15 6" stroke="white"/>
            </svg>
          </a>
        </section>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />
    <Links>
      <ol>
        <li
          class="list"
        >
          Liang JL, et al. Prevention of Pertussis, Tetanus, and Diphtheria with Vaccines in the United States: Recommendations of the Advisory Committee on Immunization Practices (ACIP)MMWR. U.S. Department of Health and Human Services Centers for Disease Control and Prevention. 2018;67:1-44.
        </li>
        <li
          class="list"
        >
          Календар профілактичних щеплень (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen">https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          В Україні оновлено Національний календар профілактичних щеплень | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/news/v-ukraini-onovleno-nacionalniy-kalendar-profilaktichnikh-scheplen">https://phc.org.ua/news/v-ukraini-onovleno-nacionalniy-kalendar-profilaktichnikh-scheplen</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Як вберегти дитину від кашлюка? (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/news/jak-vberegti-ditinu-vid-kashljuka">https://moz.gov.ua/article/news/jak-vberegti-ditinu-vid-kashljuka</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Дифтерія | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/inshi-infekciyni-zakhvoryuvannya/difteriya">https://phc.org.ua/kontrol-zakhvoryuvan/inshi-infekciyni-zakhvoryuvannya/difteriya</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Правець | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/inshi-infekciyni-zakhvoryuvannya/pravec">https://phc.org.ua/kontrol-zakhvoryuvan/inshi-infekciyni-zakhvoryuvannya/pravec</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          World Health Organization. Weekly Epidemiol Rec 2015;90:433-458. <a target="_blank" href="http://www.who.int/wer/2015/wer9035.pdf">http://www.who.int/wer/2015/wer9035.pdf</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Кашлюк у дітей. З турботою про дитину, 2016;1(55)  <a target="_blank" href="https://extempore.info/component/content/article/9-joornal/562-kashlyuk-u-ditey.html?Itemid=357">https://extempore.info/component/content/article/9-joornal/562-kashlyuk-u-ditey.html?Itemid=357</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Кашлюк у дітей | Блоги БДМУ (bsmu.edu.ua) <a target="_blank" href="https://www.bsmu.edu.ua/blog/2773-kashlyuk-u-ditey/">https://www.bsmu.edu.ua/blog/2773-kashlyuk-u-ditey/</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Skoff TH et al. Sources of Infant Pertussis Infection in the United States. Pediatrics. 2015;136(4):635-41.
        </li>
        <li
          class="list"
        >
          Про захворювання та вакцини | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/imunizaciya/pro-zakhvoryuvannya-ta-vakcini">https://phc.org.ua/kontrol-zakhvoryuvan/imunizaciya/pro-zakhvoryuvannya-ta-vakcini</a> Остання дата доступу 29.03.2021
        </li>
        <li
          class="list"
        >
          Невакциновані діти не можуть відвідувати школи та дитячі садки (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/news/nevakcinovani-diti-ne-mozhut-vidviduvati-shkoli-ta-ditjachi-sadki">https://moz.gov.ua/article/news/nevakcinovani-diti-ne-mozhut-vidviduvati-shkoli-ta-ditjachi-sadki</a> Остання дата доступу 29.03.2021
        </li>
      </ol>
      <span class="list">
        MAT-UA-2101723<br>
        (дата першого застосування 20.11.2021)
      </span>
      <p class="list">MAT-UA-2101723 (дата першого застосування 20.11.2021)</p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Турбота про здоров'я дитини в перші шкільні роки – це важливий і відповідальний етап для батьків і дітей",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/infection-in-school#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Від яких інфекцій дитина повинна бути щеплена до 6-7 років?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/infection-in-school#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Чим небезпечні кашлюк, дифтерія та правець",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/infection-in-school#stage-3"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Навіщо потрібна ревакцинація перед вступом до школи?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/infection-in-school#stage-4"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';
import Warning from '@/components/const-pages/warning.vue';

export default {
  name: "infectionInSchool",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
    Warning,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
$fontM: "Raleway-Medium";
.infection {
  //max-width: 100vw;
  width: 100%;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  .page-wrapper__item {
    list-style-type: none;
    margin-left: 20px;
    position: relative;
    &::before {
      content: "•";
      color: #2E2E2E;
      left: -16px;
      font-size: 25px;
      position: absolute;
    }
  }

  .page-wrapper__text {
    span {
      @media (max-width: 768px) {
        height: 16px;
        display: block;
      }
    }
  }

  .schema-vac {
    margin-top: 32px;
  }

  a sup {
    text-decoration: none;
  }

  &__img {
    width: 100%;
    margin-top: 48px;
    @media (max-width: 768px) {
      margin-top: 32px;
    }
  }

  .after-subtitle {
    margin-top: 4px;
  }

  .cashluc-warning {
    margin-top: 24px;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
  .flu-warning {
    margin-top: 24px;
    margin-bottom: 48px;

    @media (max-width: 768px) {
      margin-bottom: 32px;
    }
  }
}

.center-vac {
  font-family: 'Raleway-Bold';
  color: #FFFFFF;
  background: #515BA2;
  box-shadow: 0px 8px 30px rgba(81, 91, 162, 0.8);
  border-radius: 14px;
  padding: 24px 22px;
  text-decoration: none;
  margin-top: 24px;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;

  &:hover {
    cursor: pointer;
    background: #6875CF;
  }
  &:active {
    box-shadow: none;
    background: #515BA2;
  }

  @media (max-width: 768px) {
    &:hover,
    &:active {
      background: #515BA2;
      box-shadow: none;
    }
  }

  @media (max-width: 540px) {
    margin-top: 32px;
    padding-right: 14px;
    width: 100%;
    text-align: center;
  }

  svg {
    margin: 0 4px;
    @media (max-width: 540px) {
      margin: 0 1px;
    }
  }
}

.recommend {
  padding: 16px 19px 16px 26px;
  border-radius: 14px;
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #BFBFBF;
  border-radius: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &-revac {
    margin-bottom: 48px;

    @media (max-width: 768px) {
      margin-bottom: 32px;
    }
  }

  &__text {
    font-family: $fontM;
    font-size: 18px;
    line-height: 30px;
    color: #2E2E2E;
    @media (max-width: 768px) {
      margin-top: 12px;
    }
  }

  &__img {
    margin-right: 26px;
  }
}

.symptoms {
  background: #FFFFFF;
  border-radius: 14px;
  padding: 16px 0;
  margin-top: 22px;
  display: grid;

  &.symptoms-top {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      background: transparent;
      padding-bottom: 0;
    }
  }

  &.symptoms-bottom {
    grid-template-columns: repeat(2, 1fr);
    width: 67%;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      background: transparent;
      margin-top: 0;
      width: 100%;
      padding-top: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 0 32px 0 24px;

    @media (max-width: 768px) {
      background: #FFFFFF;
      border-radius: 14px;
      padding: 16px;
      margin-bottom: 8px;
      flex-direction: row;
      align-items: center;
    }

    &:nth-of-type(1)::after,
    &:nth-of-type(2)::after {
      content: '';
      width: 1px;
      background-color: #BFBFBF;
      height: 100%;
      position: absolute;
      right: 0;

      @media (max-width: 768px) {
        background-color: transparent;
      }
    }
    &:last-child::after,
    &:last-child::after {
      background-color: transparent;
    }
  }

  &__title {
    color: #515BA2;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Raleway-Bold';
  }

  &__text {
    font-family: 'Raleway-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #2E2E2E;
  }

  &__img {
    align-self: flex-end;
    margin-top: 10px;

    @media (max-width: 768px) {
      margin-top: 0;
      align-self: center;
    }
  }
}

.before-school__section {
  .page-wrapper__subtitle {
    margin-top: 35px;
    margin-bottom: -28px;

    @media (max-width: 768px) {
      margin-top: 28px;
      margin-bottom: 0;
      line-height: 24px;
    }
  }
}

.uskladnennia {
  margin-top: 36px;
  margin-bottom: -24px;

  @media (max-width: 768px) {
    margin-top: 28px;
    margin-bottom: 0;
  }
}

.peredacha {
  margin-top: 28px;
}

.disease {
  background: #FFFFFF;
  border-radius: 14px;
  padding: 22px 28px;
  margin-top: 72px;

  &-dift {
    margin-bottom: 28px;
  }

  &__title {
    font-family: 'Raleway-Bold';
    font-size: 30px;
    line-height: 38px;
    color: #2E2E2E;
    margin: 0 12px;
  }

  &__wrapper {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
  }

  & .page-wrapper__text {
    margin-top: 0;
  }
}
.vac {
  border: 2px solid #515BA2;
  border-radius: 14px;
  padding: 2px 8px;
  margin-top: 28px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  @media (max-width: 540px) {
    padding: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style-type: none;
    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    position: relative;
    padding-left: 22px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
      padding-left: 8px;
      padding-right: 8px;
      justify-content: flex-start;
    }

    &::after {
      content: '';
      width: 1px;
      background-color: #BFBFBF;
      height: 90%;
      position: absolute;
      right: 0;
      top: 8px;
    }

    &::before {
      content: '';
      width: 90%;
      background-color: #BFBFBF;
      height: 1px;
      position: absolute;
      right: 8px;
      left: 8px;
      top: 0;
    }

    &:nth-of-type(4)::after,
    &:nth-of-type(8)::after {
      background-color: transparent;
      @media (max-width: 1160px) {
        background-color: transparent;
      }
    }

    &:nth-of-type(2)::after,
    &:nth-of-type(6)::after {
      background-color: #BFBFBF;
      @media (max-width: 1160px) {
        background-color: transparent;
      }
    }
    
    &:nth-of-type(1)::before,
    &:nth-of-type(2)::before,
    &:nth-of-type(3)::before,
    &:nth-of-type(4)::before {
      background-color: transparent;
    }

    &:nth-of-type(3)::before,
    &:nth-of-type(4)::before {
      @media (max-width: 1160px) {
        background-color: #BFBFBF;
      }
    }
  }

  &__title {
    font-family: 'Raleway-Bold';
    color: #515BA2;
    font-size: 18px;
    line-height: 24px;
  }

  &__text {
    font-family: 'Raleway-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #2E2E2E;
    margin-top: 4px;
  }

  &__content {
    &-img {
      text-align: end;
      padding-right: 20px;

      @media (max-width: 768px) {
        padding-right: 0;
        text-align: start;
        margin-top: 12px;
      }

      img {
        @media (max-width: 540px) {
          max-width: 120px;
          height: 24px;
        }
      }
    }
  }
}
</style>
