<template>
  <div class="social">
    <a href="#" class="social__item" target="_blank">
      <img src="@/assets/img_const-pages/fb.svg">
    </a>
    <a href="#" class="social__item" target="_blank">
      <img src="@/assets/img_const-pages/telegram.svg">
    </a>
    <a href="#" class="social__item" target="_blank">
      <img src="@/assets/img_const-pages/viber.svg">
    </a>
    <a href="#" class="social__item" target="_blank">
      <img src="@/assets/img_const-pages/whatsApp.svg">
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialNetwork',
}
</script>

<style lang="scss" scoped>
.social {
  display: grid;
  grid-template-columns: repeat(4, 40px);
  grid-column-gap: 20px;
  margin-top: 60px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin: 48px auto;
  }

  @media (max-width: 550px) {
    justify-content: center;
  }

  &__item {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    &:nth-of-type(1) {
      background-color: #415A93;

      &:hover {
        box-shadow: 0px 6px 10px -5px #415A93;
        @media (max-width: 1024px) {
          box-shadow: none;
        }
      }
      
    }

    &:nth-of-type(2) {
      background-color: #57A4DB;

      &:hover {
        box-shadow: 0px 6px 10px -5px #57A4DB;
        @media (max-width: 1024px) {
          box-shadow: none;
        }
      }
    }

    &:nth-of-type(3) {
      background-color: #755599;

      &:hover {
        box-shadow: 0px 6px 10px -5px #755599;
        @media (max-width: 1024px) {
          box-shadow: none;
        }
      }
    }

    &:nth-of-type(4) {
      background-color: #72B86C;

      &:hover {
        box-shadow: 0px 6px 10px -5px #72B86C;
        @media (max-width: 1024px) {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
