<template>
  <div id="app">
    <router-view/>

  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "app",
  data: () => ({}),

  beforeMount() {
    if (localStorage.getItem('cookie')) {
      this.cookieAccept();
    }
  },

  methods: {
    ...mapActions([
      'cookieAccept'
    ])
  },

  mounted() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // window.addEventListener("resize", () => {
    //
    //   let vh = window.innerHeight * 0.01;
    //
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    //
    // });
  }
};
</script>

<style lang="scss">
html {
  height: 100%;
  margin-top: 66px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}

@font-face {
  font-family: 'Raleway-Regular';
  src: url('assets/fonts/Raleway-Regular.woff2') format('woff2'),
  url('assets/fonts/Raleway-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('assets/fonts/Raleway-Medium.woff2') format('woff2'),
  url('assets/fonts/Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('assets/fonts/Raleway-Bold.woff2') format('woff2'),
  url('assets/fonts/Raleway-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('assets/fonts/Raleway-SemiBold.woff2') format('woff2'),
  url('assets/fonts/Raleway-SemiBold.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Raleway-SemiBoldItalic';
  src: url('assets/fonts/Raleway-SemiBoldItalic.woff2') format('woff2'),
  url('assets/fonts/Raleway-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-Thin';
  src: url('assets/fonts/Raleway-Thin.woff2') format('woff2'),
  url('assets/fonts/Raleway-Thin.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('assets/fonts/Raleway-Light.woff2') format('woff2'),
  url('assets/fonts/Raleway-Light.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";
$fontB: "Raleway-Bold";
$fontSB: "Raleway-SemiBold";

body {
  padding-bottom: 15vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
    scrollbar-width: none
}

* {
  @media screen and (max-width: 1024px) {
    cursor: none !important;
    outline: none !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

* {
  scroll-behavior: smooth;
  user-select: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* sub,
* sup {
  font-size: 60%;
  line-height: 0;
}
</style>
