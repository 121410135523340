<template>
  <div class="preparation" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="preparation/banner-desc.png" mobImg="preparation/banner-mob.png">
      <template v-slot:link>Підготовка дитини до щеплення</template>
      <template v-slot:title>Підготовка дитини до щеплення</template>
      <template v-slot:text>
        Необхідність вакцинації, підбір потрібної вакцини для дитини, процес підготовки до щеплення і спостереження після щеплення — для вирішення цих та інших питань необхідно звернутися до лікаря.
      </template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Підготовка дитини до щеплення
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                Чи можна робити щеплення?
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Чи є у вашої дитини підтверджена виражена алергічна реакція?
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-4" class="contents__link">
                Аналізи і додаткові дозволи лікарів перед вакцинацією
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-5" class="contents__link">
                У день щеплення
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-6" class="contents__link">
                Через день після щеплення
              </a>
            </li>
          </ul>
        </Contents>
        <ul class="page-wrapper__section wi-flu__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Підготовка дитини до щеплення
          </h2>

          <p class="page-wrapper__text">
            Необхідність вакцинації, підбір потрібної вакцини для дитини, процес підготовки до щеплення і спостереження після щеплення — для вирішення цих та інших питань необхідно звернутися до лікаря.
          </p>
          <p class="page-wrapper__text">
            Якщо у вас є карта профілактичних щеплень (спеціальний документ для записів щеплень, зроблених вашій дитині, довідка форми № 063/о, де повинна бути інформація про проведення вакцинації чи ревакцинації, а також здійснення туберкулінових проб), візьміть її з собою, щоб лікар вписав туди дані про нове щеплення.<sup>1</sup> Якщо щеплення до цього не робилися або такої карти немає, то попросіть лікаря її видати.<br>
            <span></span>
            Ці записи можуть стати в нагоді пізніше, щоб у разі потреби показати необхідні щеплення, які має ваша дитина, перед вступом до дитячого садка або школи чи у випадку переїзду або зміни лікаря.
          </p>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            Чи можна робити щеплення?
          </h2>

          <p class="page-wrapper__text">
            Показання та протипоказання щодо щеплення визначає лікар.<sup>2</sup><br>
            <span></span>
            Перед вакцинацією лікар проведе ретельне опитування з метою виявлення попередніх захворювань, у тому числі хронічних, наявності реакцій або ускладнень на попереднє введення препарату, алергічних реакцій на лікарські засоби, продукти; дізнається про контакт з інфекційними хворими, а також терміни попередніх щеплень.<sup>2</sup>
          </p>

          <Warning imgColor="#515BA2" borderColor="#515BA2">
            <template v-slot:text>Пам'ятайте, що у разі гострих захворювань, що супроводжуються підвищенням температури вище 38,0 °C, щеплення, як правило, необхідно буде відкласти до повного одужання.<sup>3</sup></template>
          </Warning>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-3">
          <h2 class="page-wrapper__h2-title">
            Чи є у вашої дитини підтверджена виражена алергічна реакція?
          </h2>

          <p class="page-wrapper__text">
            Якщо у дитини є алергічні реакції, потрібно заздалегідь обговорити з лікарем про необхідність додаткових заходів.
          </p>
          <img src="@/assets/img_const-pages/preparation/allergy.png" alt="" class="page-wrapper__img">
          <Warning imgColor="#515BA2" borderColor="#515BA2">
            <template v-slot:text>Пам'ятайте, що не варто вводити нові продукти харчування за кілька днів до і в перші дні після вакцинації. Якщо дитина перебуває на грудному вигодовуванні, не включайте до свого раціону нові продукти.<sup>2</sup></template>
          </Warning>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-4">
          <h2 class="page-wrapper__h2-title">
            Аналізи і додаткові дозволи лікарів перед вакцинацією
          </h2>

          <p class="page-wrapper__text">
            Лікар може визначити, що до проведення щеплення можуть знадобитися аналізи або рекомендації інших фахівців.
          </p>
          <p class="page-wrapper__text">
            Наприклад, якщо педіатру незрозумілий характер змін нервової системи, він може направити дитину до невролога, після чого ухвалить рішення щодо проведення вакцинації та вибору вакцин.<sup>2</sup>
          </p>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-5">
          <h2 class="page-wrapper__h2-title">
            У день щеплення
          </h2>

          <p class="page-wrapper__text">
            Ви маєте право отримати у вашого лікаря інформацію про вакцину, необхідність вакцинації, можливі реакції на щеплення та наслідки відмови від вакцинації.<sup>2</sup>
          </p>
          <p class="page-wrapper__text">
            Зі свого боку лікар поставить вам запитання, згадані у попередніх розділах, щоб визначити, чи має ваша дитина якісь протипоказання, і чи потрібні особливі заходи безпеки для проведення вакцинації.<sup>2,4</sup>
          </p>
          <p class="page-wrapper__text">
            Перед вакцинацією лікар надасть форму, яку необхідно заповнити (форма № 063-2/о «Інформована згода та оцінка стану здоров’я особи або дитини одним з батьків, законним представником дитини на проведення щеплення або туберкулінодіагностики»), яка використовується по всій території України.<sup>4,5</sup><br>
            <span></span>
            Не соромтеся ставити запитання лікарю. Можна уточнити, зокрема якою вакциною буде щеплена дитина, коли та які реакції можуть виникнути, і в яких випадках необхідно звертатися за медичною допомогою. 
          </p>
          <p class="page-wrapper__text">
            Не слід лякати малюка уколами і лікарями. Це може залишити негативний відбиток на все життя. Якщо дитина старшого віку, то краще пояснити їй, що будуть робити лікарі, а головне – для чого. Під час щеплення можна тримати малюка на руках, щоб попередити несподівані рухи дитини. Медичний працівник, який здійснює вакцинацію, не повинен сам тримати дитину. Не варто поспішати залишати поліклініку, зазвичай лікар або медсестра просить батьків посидіти близько 30 хвилин неподалік від кабінету. Це допоможе заспокоїти дитину і надати допомогу в разі виникнення реакцій на введення вакцини.
          </p>
          <p class="page-wrapper__text">
            Обговоріть з лікарем можливість застосування і дозування дитячого жарознижувального засобу, а також симптоми, у разі яких обов'язково потрібно зателефонувати лікарю.<sup>2,4</sup>
          </p>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-6">
          <h2 class="page-wrapper__h2-title">
            Через день після щеплення
          </h2>

          <p class="page-wrapper__text">
            Уточніть у лікаря, яким чином можна купати дитину після щеплення.<br>
            <span></span>
            Лікар може рекомендувати стежити за температурою тіла малюка в перші дні після вакцинації  та розповість про можливість застосування і дозування дитячого жарознижувального засобу у разі потреби.
          </p>
          <Warning imgColor="#515BA2" borderColor="#515BA2">
            <template v-slot:text>Якщо вас непокоїть, як виглядає або як поводить себе дитина після щеплення, обов’язково зверніться до лікаря.<sup>2,4</sup></template>
          </Warning>
        </ul>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />
    <Links>
      <ol>
        <li
          class="list"
        >
          Форма первинної облікової документації N 063/о "Карта профілактичних щеплень" (rada.gov.ua) <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/za688-06">Форма первинної облікової документ... | від 10.01.2006 № 1 (rada.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Положення про організацію та проведення профілактичних щеплень із змінами і доповненнями, внесеними наказами Міністерства охорони здоров'я України від 11 серпня 2014 року № 551, від 1 лютого 2019 року № 280 <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1160-11#n4">Положення про організацію і пров... | від 16.09.2011 № 595 (rada.gov.ua),</a> <a href="https://zakon.rada.gov.ua/laws/show/z1238-14#Text">Положення про організацію і пров... | від 11.08.2014 № 551 (rada.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Наказ МОЗ України від 11.10.2019 р. № 2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень» <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1182-19#Text">Про внесення змін до Календаря ... | від 11.10.2019 № 2070 (rada.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Все, що варто знати про вакцинацію | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://www.phc.org.ua/news/vse-scho-varto-znati-pro-vakcinaciyu">Все, що варто знати про вакцинацію | Центр громадського здоров’я (phc.org.ua)</a>
        </li>
        <li
          class="list"
        >
          Про затвердження форми первинної облікової документації N 063-2/о "Інформована згода та оцінка стану здоров'я особи або дитини одним з батьків або іншим законним представником дитини на проведення щеплення або туберкулінодіагностики" (rada.gov.ua) <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/za594-10">Про затвердження форми первинно... | від 31.12.2009 № 1086 (rada.gov.ua)</a>
        </li>
      </ol>
      <p class="list">
        MAT-UA-2101723 (дата першого застосування 20.11.2021)
      </p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Підготовка дитини до щеплення",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/preparation-for-vaccination#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Чи можна робити щеплення?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/preparation-for-vaccination#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Чи є у вашої дитини підтверджена виражена алергічна реакція?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/preparation-for-vaccination#stage-3"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Аналізи і додаткові дозволи лікарів перед вакцинацією",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/preparation-for-vaccination#stage-4"
      },{
        "@type": "ListItem",
        "position": 5,
        "name": "У день щеплення",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/preparation-for-vaccination#stage-5"
      },{
        "@type": "ListItem",
        "position": 6,
        "name": "Через день після щеплення",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/preparation-for-vaccination#stage-6"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';
import Warning from '@/components/const-pages/warning.vue';

export default {
  name: "preparation",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
    Warning,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
$fontB: "Raleway-Bold";
$fontR: "Raleway-Regular";

.preparation {
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  .page-wrapper__text span {
    @media (max-width: 768px) {
      height: 16px;
      display: block;
    }
  }

  .links-wrapper {
    p.list {
      margin-top: 24px !important;
    }
  }

  .warning {
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
}
</style>