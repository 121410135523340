<template>
  <nav class="bottom-nav">
    <ul class="bottom-list">
      <li onclick="gtag('event', 'flu_hto-v-zoni-ryzyku', {'event_category': 'main_menu', 'event_label': 'Хто в зоні ризику'})">
        <a class="bottom-list__item" href="#stage2" tabindex="-1">Хто в зоні ризику?</a>
      </li>
      <li onclick="gtag('event', 'flu_hryp-hrvi', {'event_category': 'main_menu', 'event_label': 'Грип та ГРВІ'})">
        <a class="bottom-list__item" href="#stage3" tabindex="-1">Грип та ГРВІ</a>
      </li>
      <li onclick="gtag('event', 'flu_vaccinacia-vachliva', {'event_category': 'main_menu', 'event_label': 'Вакцинація важлива'})">
        <a class="bottom-list__item" href="#stage4" tabindex="-1">Вакцинація важлива</a>
      </li>
      <li onclick="gtag('event', 'flu_dia-vacciny', {'event_category': 'main_menu', 'event_label': 'Дія вакцини'})">
        <a class="bottom-list__item" href="#stage5" tabindex="-1">Дія вакцини</a>
      </li>
      <li onclick="gtag('event', 'flu_chasti-pytannia', {'event_category': 'main_menu', 'event_label': 'Часті запитання'})">
        <a class="bottom-list__item" href="#stage6" tabindex="-1">Часті запитання</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "header-bottom-block",
  mounted() {
    const index = window.location.href.toString().indexOf('#');
    if (index >= 0) {
      window.location.href = window.location.href.substring(index, 0);
    }

    document.querySelectorAll('.bottom-list__item').forEach(link => {
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });

    const headings = document.querySelectorAll('.anchor');
    const link = document.querySelectorAll('.bottom-list__item');

    document.addEventListener('scroll', (e) => {
      headings.forEach(ha => {
        const rect = ha.getBoundingClientRect();
        if (rect.top > 0 && rect.top < 450) {
          const location = window.location.toString().split('#')[0];
          history.replaceState(null, null, location + '#' + ha.id);

          if (ha.id === 'stage2') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[0].classList.add('active-link')

           } else if (ha.id === 'stage3' || ha.id === 'stage3_1') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[1].classList.add('active-link')
            
          } else if (ha.id === 'stage4') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[2].classList.add('active-link')
          } else if (ha.id === 'stage5') {
            link.forEach((a) => {
                a.classList.remove('active-link')
            })
            link[3].classList.add('active-link')
            
          } else if (ha.id === 'stage6') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[4].classList.add('active-link')
          } else if (ha.id === 'stage1') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
          }
        }
      });
    });
  }
}
</script>

<style scoped lang="scss">
ul {list-style-type: none;}
.bottom-nav {
  flex: 1;
  padding: 0 26px;
  position: relative;

  
  @media screen and (max-width: 810px) {
    padding: 0 8px;
  }
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    ::-moz-scrollbar{
      display: none;
    }

    /* ползунок скроллбара */
    ::-webkit-scrollbar-thumb {
      display: none;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: transparent;
    }
  }
}
.bottom-list {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: 576px) {
    width: max-content;
  }
  & li:hover {
    cursor: pointer;
  }
  &__item {
    padding: 0 4px;
    font-size: 16px;
    line-height: 19px;
    color: #3F4C56;
    text-decoration: none;

    @media (max-width: 768px) {
      font-family:"Raleway-Regular";
    }
    &.active-link {
      color: #2479B7;
      font-weight: 700;
    }
    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }
}
</style>
