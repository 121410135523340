<template>
  <div class="header">
    <div class="header__top"><div class="header__top--content"><img src="../assets/img/sanofiLogo.svg"></div></div>
    <slot name="header-marquee" ></slot>
    <div class="header__middle" :style="{ backgroundColor: bgColor }">
      <div class="header__middle--content">
        <div class="header__middle--logo"><img src="../assets/img/vaccineLogo.svg" alt="vaccineshub"></div>
        <nav class="header__middle--nav">
          <ul class="header__middle--list top-list">
            <li onclick="gtag('event', 'flu', {'event_category': 'main_menu', 'event_label': 'Грип'})">
              <router-link to="/flu" class="top-list__item disease">Грип</router-link>
            </li>
            <li onclick="gtag('event', 'meningit', {'event_category': 'main_menu', 'event_label': 'Менінгіт'})">
              <router-link to="/meningitis" class="top-list__item disease">Менінгіт</router-link>
            </li>
            <li onclick="gtag('event', 'vaccination-before-shool', {'event_category': 'main_menu', 'event_label': 'Вакцинація перед школою'})">
              <router-link to="/pertussis" class="top-list__item disease">Вакцинація перед школою</router-link>
            </li>
            <!--<li
              class="item-vac"
              @mouseover="hover = true"
              @mouseleave="hover = false"
              onclick="gtag('event', 'vacсination', {'event_category': 'main_menu', 'event_label': 'Вакцинація'})"
            >
              <router-link to="/flu" class="top-list__item">Вакцинація</router-link>
              <div class="item-vac__wrapper">
                <ul v-if="hover" class="list-vac">
                  <li
                    v-for="vac of vaccinerList"
                    :key="vac.id"
                    class="list-vac__item"
                    :onclick="`gtag('event', 'vacсination-${vac.tile}', {'event_category': 'main_menu', 'event_label': '${vac.title}'})`"
                  >
                    <router-link :to="vac.path">{{ vac.title }}</router-link>
                  </li>
              </ul>
              </div>
            </li>
            <li onclick="gtag('event', 'center-vacсination', {'event_category': 'main_menu', 'event_label': 'Центр вакцинацій'})">
              <router-link to="#" class="top-list__item">Центр вакцинацій</router-link>
            </li>
            <li onclick="gtag('event', 'hvoroby', {'event_category': 'main_menu', 'event_label': 'Хвороби'})">
              <router-link to="#" class="top-list__item">Хвороби</router-link>
            </li>
            <li onclick="gtag('event', 'statti', {'event_category': 'main_menu', 'event_label': 'Статті'})">
              <router-link to="#" class="top-list__item">Статті</router-link>
            </li>-->
          </ul>
        </nav>
        <div class="header__middle--menu-icon">
          <img  v-if="!openMenu" @click="openMenu = !openMenu" src="../assets/img/menuIcon.svg">
          <img v-if="openMenu" @click="openMenu = !openMenu" src="../assets/img/closeIcon.svg">
        </div>
        <div v-if="openMenu" class="header__mobile mobile" :style="{ background: mobBg }">
          <div class="header__mobile--circle-left"></div>
          <div class="header__mobile--circle-right"></div>
          <nav class="mobile__nav">
            <ul class="mobile__list">
              <li class="mobile__item" onclick="gtag('event', 'flu', {'event_category': 'main_menu', 'event_label': 'Грип'})">
                <router-link to="/flu" class="mobile__link disease">
                  Грип
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                        fill=""/>
                  </svg>
                </router-link></li>
              <li class="mobile__item" onclick="gtag('event', 'meningit', {'event_category': 'main_menu', 'event_label': 'Менінгіт'})">
                <router-link to="/meningitis" class="mobile__link disease">
                  Менінгіт
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                        fill=""/>
                  </svg>
                </router-link>
              </li>
              <li onclick="gtag('event', 'vaccination-before-shool', {'event_category': 'main_menu', 'event_label': 'Вакцинація перед школою'})" class="mobile__item">
                <router-link to="/pertussis" class="mobile__link disease">
                  Вакцинація перед школою
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                        fill=""/>
                  </svg>
                </router-link>
              </li>
              <!--<li @click="openVaccinerList = !openVaccinerList" class="item-vac-mobile mobile__item" onclick="gtag('event', 'vacсination', {'event_category': 'main_menu', 'event_label': 'Вакцинація'})">
                <router-link to="#" class="mobile__link">
                  Вакцинація
                  <span class="open-arrow" :class="{ active: openVaccinerList }">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 0.75L6 5.75L11 0.75" stroke="white" stroke-width="0.769231"/>
                    </svg>
                  </span>
                </router-link>
                <div v-if="openVaccinerList" class="item-vac-mobile__wrapper">
                  <ul class="list-vac-mobile">
                    <li
                      v-for="vac of vaccinerList"
                      :key="vac.id"
                      class="list-vac-mobile__item"
                      :onclick="`gtag('event', 'vacсination-${vac.title}', {'event_category': 'main_menu', 'event_label': '${vac.title}'})`"
                    >
                      <router-link :to="vac.path" class="list-vac-mobile__link">{{ vac.title }}</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="mobile__item" onclick="gtag('event', 'center-vacсination', {'event_category': 'main_menu', 'event_label': 'Центр вакцинацій'})">
                <router-link to="#" class="mobile__link">Центр вакцинацій</router-link>
              </li>
              <li class="mobile__item" onclick="gtag('event', 'hvoroby', {'event_category': 'main_menu', 'event_label': 'Хвороби'})">
                <router-link to="#" class="mobile__link">Хвороби</router-link>
              </li>
              <li class="mobile__item" onclick="gtag('event', 'statti', {'event_category': 'main_menu', 'event_label': 'Статті'})">
                <router-link to="#" class="mobile__link">Статті</router-link>
              </li>-->
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="header__bottom">
      <div class="header__bottom--content">
        <div class="header__bottom--logo"></div>
        <slot name="header-bottom-block"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "main-header",
  data: () => {
    return {
      hover: false,
      openMenu: false,
      openVaccinerList: false,
      vaccinerList: [
        {
          id: 1,
          title: 'Вакцинація немовлят',
          path: '#',
        },
        {
          id: 2,
          title: 'Вакцинація дітей',
          path: '#',
        },
        {
          id: 3,
          title: 'Вакцинація дорослих',
          path: '#',
        },
        {
          id: 4,
          title: 'Вакцинація мандрівників',
          path: '#',
        },
        {
          id: 5,
          title: 'Міфи про вакцинацію',
          path: '#',
        },
        {
          id: 6,
          title: 'Основи вакцинації',
          path: '#',
        },
        {
          id: 7,
          title: 'Календар щеплень',
          path: '#',
        },
        {
          id: 8,
          title: 'Сумісність щеплень',
          path: '#',
        }
      ]
    }
  },
  props: {
    bgColor: {
      type: String,
      required: false
    },
    mobBg: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";
$fontB: "Raleway-Bold";
$fontT: 'Raleway-Thin';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.list-vac {
  display: block;
  width: 260px;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  &::before{
    content: '';
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    position: absolute;
    top: -8px;
    left: calc(260px/2 - 16px/2);
    transform: rotate(45deg);
  }

  &__item {
    margin-top: 30px;
    color: #6872BA;
    font-size: 16px;
    line-height: 19px;
    font-family: $fontR;
    transition: all .2s ease;
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      font-family: $fontB;
      color: #515BA2;
      transform: translateX(10px);
    }
  }
}

ul {list-style-type: none;}
a {text-decoration: none;}
.header {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  max-width: 100vw;
  // background: rgba(255, 255, 255, 0.6);

  // &__marquee {
  //   width: 100%;
  //   height: 30px;
  //   background: #FFFFFF;
  //   color: red;
  //   text-transform: uppercase;
  //   font-family: 'Roboto', sans-serif;
  //   font-weight: 400;
  //   z-index: 11;
  //   strong {
  //     font-weight: 700;
  //   }
  // }

  &__top {
    padding: 14px 0;
    background: #fff;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 10;
    @media (max-width: 460px) {
      padding: 12px 0;
    }

    &--content {
      max-width: 1300px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 10px;

      & img {
        @media (max-width: 460px) {
          max-width: 144px;
        }
      }
    }
  }

  &__middle {
    background: hsla(0,0%,100%,.2);
    transition: background-color .2s ease;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 10;
    padding: 6px 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media (max-width: 768px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media (max-width: 460px) {
      padding: 2px 0;
    }

    &--content {
      max-width: 1300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px;
      @media (max-width: 768px) {
        padding: 6px 20px;
      }
      @media (max-width: 460px) {
        padding: 10px 20px;
      }
    }

    &--logo {
      margin-right: 10%;
      @media (max-width: 992px) {
        margin-right: 1%;
      }

      & > img {
        @media (max-width: 460px) {
          max-width: 115px;
        }
      }
    }

    &--list {
      display: flex;
      //justify-content: space-between;
      justify-content: flex-end;
      li {
        margin-left: 10%;
      }
    }

    &--nav {
      flex: 1;
      padding: 0 26px;
      @media screen and (max-width: 810px) {
        padding: 0 8px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    &--menu-icon {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
    }
    .top-list {
      .item-vac {
        position: relative;
        &__wrapper {
          background-color: transparent;
          padding: 25px 0;
          position: absolute;
          z-index: 10;
          right: calc(50% - 260px/2);
        }
      }

      &__item {
        list-style-type: none;
        color: #fff;
        font-family: $fontR;
        font-size: 16px;
        line-height: 19px;
        padding: 4px;
        @media (max-width: 992px) {
          font-size: 14px;
        }

        &.disease {
          font-family: $fontB;
        }
      }

      & li:hover {
        cursor: pointer;
      }
    }
  }

  &__bottom {
    transition: background-color .2s ease;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: -1;
    align-self: center;
    background: rgba(255, 255, 255, 0.6);

    &:before {
      content: '';
      position: absolute;
      background: rgba(255, 255, 255, 0.6);
      height: 100%;
      width: 100%;
      left: 0;
      top: -100%;
    }

    &--content {
      max-width: 1300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
    }

    &--logo {
      margin-right: 10%;
      width: 186px;
      @media (max-width: 992px) {
        margin-right: 1%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    .bottom-nav {
      padding: 16px 0;

      @media (max-width: 460px) {
        padding: 10px 0;
      }
    }
  }

  &__mobile {
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 70px;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling:touch;
    height: calc(100vh - 120px);
    max-height: 100vh;
    font-family: 'Roboto', sans-serif;
    @media (max-width: 460px) {
      top: 49px;
    }
    @media (max-width: 440px) {
      height: calc(100vh - 150px);
    }
    @media (max-width: 330px) {
      padding-top: 20px;
    }

    &--circle-left {
      position: absolute;
      width: 138px;
      height: 138px;
      left: calc(50% - 138px/2 - 155px);
      bottom: -70px;
      background: radial-gradient(60.7% 60.7% at 34.66% 39.3%, #F5CEDA 0%, #C096B2 100%);
      opacity: 0.75;
      filter: blur(90px);
    }

    &--circle-right {
      position: absolute;
      width: 320px;
      height: 320px;
      bottom: 56px;
      left: calc(50% - 320px/2 + 204px);
      background: radial-gradient(60.7% 60.7% at 34.66% 39.3%, #FFE0EA 0%, #C096B2 100%);
      opacity: 0.75;
      filter: blur(142.721px);
    }
  }
}

.mobile {
  &__list {
    text-align: center;
    position: relative;
  }

  &__item {
    background: transparent;
      &:hover,
      &:active {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
      }
  }

  &__link {
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    font-family: 'Roboto', sans-serif;

    &.disease {
      font-weight: 700;
    }

    & > .open-arrow {
      margin: 0 16px;
      padding-bottom: 4px;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .list-vac-mobile {
    background-color: #fff;
    padding: 10px 50px;
    text-align: start;
    overflow-y: scroll;
    -webkit-overflow-scrolling:touch;
    //height: 90vh;
    max-height: 370px;

    &__item {
      padding: 10px 0;
      font-family: 'Raleway-Light';
      font-size: 16px;
      line-height: 19px;
      transition: all .2s ease;
      &:focus,
      &:active {
        font-family: $fontB;
        transform: translateX(10px);
      }
    }

    &__link {
      color: #515BA2;
    }
  }
}

</style>
