<template>
  <div class="ua-recomendations" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="ua-recom/banner-desc.png" mobImg="ua-recom/banner-mob.png">
      <template v-slot:link>Українські рекомендації Нормативні документи</template>
      <template v-slot:title>Українські рекомендації<br> Нормативні документи</template>
      <template v-slot:text>
        Закони України щодо епідемічного благополуччя:
        <div style="height: 16px"></div>
        Закон України «Про захист населення від інфекційних хвороб» від 6.04.2000 р. № 1645-III<sup>1</sup>
      </template>
    </Banner>

    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Закони України щодо епідемічного благополуччя 
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                Нормативна база з розвитку імунопрофілактики
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Фармаконагляд
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-4" class="contents__link">
                Нормативна база щодо щеплень, імунобіологічних препаратів
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-5" class="contents__link">
                Рішення засідань оперативного штабу МОЗ України з реагування на ситуації з<br> поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-6" class="contents__link">
                Нормативна база щодо поліомієліту, дифтерії, правця, грипу та ГРВІ, сказу, кліщового енцефаліту
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-7" class="contents__link">
                Накази, форми
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-8" class="contents__link">
                Методичні вказівки
              </a>
            </li>
          </ul>
        </Contents>
        <section class="page-wrapper__section ua-recomendations__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Закони України щодо епідемічного благополуччя:
          </h2>

          <p class="page-wrapper__text">
            Закон України «Про захист населення від інфекційних хвороб» від 6.04.2000 р. № 1645-III<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Закон України «Про забезпечення санітарного та епідемічного благополуччя населення» від 24.02.1994 р. № 4004-XII<sup>2</sup>
          </p>
        </section>

        <section class="page-wrapper__section ua-recomendations__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            Нормативна база з розвитку імунопрофілактики:
          </h2>

          <p class="page-wrapper__text">
            Наказ МОЗ України від 28.02.2020 № 585 «Про створення Національної технічної групи експертів з питань імунопрофілактики»<sup>3</sup>
          </p>
          <p class="page-wrapper__text">
            Резолюція круглого столу «Стратегія розвитку імунопрофілактики та захисту населення від інфекційних хвороб», що відбувся 23 грудня 2019 р. в м. Київ<sup>4</sup>
          </p>
          <p class="page-wrapper__text">
            Розпорядження Кабінету Міністрів України «Про схвалення Стратегії розвитку імунопрофілактики та захисту населення від інфекційних хвороб, яким можна запобігти шляхом проведення імунопрофілактики, на період до 2022 року та затвердження плану заходів щодо її реалізації» від 27.11.2019 р. № 1402-р<sup>5</sup>
          </p>
        </section>

        <section class="page-wrapper__section ua-recomendations__section" id="stage-3">
          <h2 class="page-wrapper__h2-title">
            Фармаконагляд:
          </h2>

          <p class="page-wrapper__text">
            Наказ МОЗ від 26.09.2016 № 996 «Про внесення змін до деяких наказів Міністерства охорони здоров’я України» (Положення про центральну і регіональні групи оперативного реагування на несприятливі події після імунізації/туберкулінодіагностики)<sup>6</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ від 27.12.2006 № 898 «Про затвердження Порядку здійснення фармаконагляду»<sup>7</sup>
          </p>
        </section>

        <section class="page-wrapper__section ua-recomendations__section" id="stage-4">
          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо щеплень:
          </h2>

          <p class="page-wrapper__text">
            Наказ МОЗ України від  11.10.2019 № 2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень»<sup>8</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 16.09.2011 № 595 «Положення про організацію і проведення профілактичних щеплень»<sup>9</sup><br>
            Рекомендації з проведення вакцинації в Україні в умовах дефіциту імунобіологічних препаратів та їх нерегулярного постачання (додаток до листа МОЗ України від 16.06.2016 № 05.2-11/15133)<sup>10</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо імунобіологічних<br> препаратів:
          </h2>

          <p class="page-wrapper__text">
            Наказ МОЗ України від 19.10.2020 № 2357 «Про затвердження Плану заходів щодо ефективного управління вакцинами»<sup>11</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України 18.05.2018 № 948 «Про затвердження Методики розрахунку потреби в медичних імунобіологічних препаратах (вакцинах) та медичних виробах, які використовуються для профілактичних щеплень, та Порядку визначення потреби в медичних імунобіологічних препаратах (вакцинах) та медичних виробах, які використовуються для профілактичних щеплень»<sup>12</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 16.09.2011 № 595 «Про порядок проведення профілактичних щеплень в Україні та контроль якості й обігу медичних імунобіологічних препаратів»<sup>13</sup>
          </p>
          <p class="page-wrapper__text">
            Закон України від 4.04.1996 № 123/96-ВР «Про лікарські засоби»<sup>14</sup>
          </p>
        </section>
        
        <section class="page-wrapper__section ua-recomendations__section" id="stage-5">
          <h2 class="page-wrapper__h2-title">
            Рішення засідань оперативного штабу МОЗ України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом<br> вакцинації:
          </h2>

          <p class="page-wrapper__text">
            Наказ МОЗ України від 15.12.2020 № 2914 «Про введення в дію Рішення оперативного штабу Міністерства охорони здоров’я України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації»<sup>15</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 25.10.2019 № 2164 «Про введення в дію Рішення оперативного штабу Міністерства охорони здоров’я України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації»<sup>16</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 07.06.2019 № 1319 «Про створення оперативного штабу Міністерства охорони здоров’я України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації»<sup>17</sup>
          </p>
        </section>

        <section class="page-wrapper__section ua-recomendations__section" id="stage-6">
          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо поліомієліту:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 26.04.2017 № 458 «Про затвердження Заходів щодо підтримки Україною статусу країни, вільної від поліомієліту, на 2017–2020 роки»<sup>18</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 14.07.1998 № 196 «Про посилення заходів щодо попередження захворювань на поліомієліт в Україні»<sup>19</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо кору, краснухи:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 11.09.2017 № 1082 «Про проведення заходів щодо імунізації населення проти кору в Україні»<sup>20</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ ЦГЗ України від 28.08.2017 № 221-дт «Про затвердження оперативного плану» (щодо забезпечення виконання наказу МОЗ України від 05.07.2017 № 763)<sup>21</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 23.04.2019 № 954 «Про затвердження Порядку проведення епідеміологічного нагляду за кором, краснухою та синдромом вродженої краснухи»<sup>22</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо дифтерії:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 03.07.2020 № 1510 «Про затвердження Порядку проведення епідеміологічного нагляду за дифтерією»<sup>23</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо правця:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 05.08.1999 №198 «Про вдосконалення профілактики, діагностики та лікування правця»<sup>24</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо грипу та ГРВІ:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 17.05.2019 № 1126 «Про затвердження Порядку організації проведення епідеміологічного нагляду за грипом та гострими респіраторними вірусними інфекціями, заходів з готовності в міжепідемічний період і реагування під час епідемічного сезону захворюваності на грип та ГРВІ»<sup>25</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо сказу:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 15.04.2004 № 205 «Про удосконалення заходів профілактики захворювань людей на сказ»<sup>26</sup>
          </p>

          <h2 class="page-wrapper__h2-title">
            Нормативна база щодо кліщового енцефаліту:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 13.05.2013 № 369 «Про затвердження Методичних рекомендацій Неспецифічна профілактика трансмісивних природно-вогнищевих інфекцій, що передаються іксодовими кліщами»<sup>27</sup>
          </p>
        </section>

        <section class="page-wrapper__section ua-recomendations__section" id="stage-7">
          <h2 class="page-wrapper__h2-title">
            Накази, форми:
          </h2>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 31.12.2009 № 1086 «Про затвердження форми первинної облікової документації N 063-2/о "Інформована згода та оцінка стану здоров'я особи або дитини одним з батьків або іншим законним представником дитини на проведення щеплення або туберкулінодіагностики" та Інструкції щодо її заповнення»<sup>28</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 10.01.2006 № 1 «Інструкція щодо заповнення форми первинної облікової документації N 063/о "Карта профілактичних щеплень"»<sup>29</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 30.07.2020 № 1726 «Про затвердження Порядку ведення обліку, звітності та епідеміологічного нагляду (спостереження) за інфекційними хворобами та Переліку інфекційних хвороб, що підлягають реєстрації»<sup>30</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ від 02.06.2009 № 378 «Про затвердження форм звітності з інфекційних і паразитарних захворювань, щеплень проти окремих інфекційних хвороб та інструкцій щодо їх заповнення»<sup>31</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 25.07.2008 № 413 «Про затвердження обліково-звітних форм планування і виконання профілактичних щеплень „УкрВак 08“»<sup>32</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 10.02.2006 № 1 «Про затвердження Форм первинної облікової документації з інфекційної, дерматовенерологічної, онкологічної захворюваності та інструкцій щодо їх заповнення»<sup>33</sup>
          </p>
          <p class="page-wrapper__text">
            Наказ МОЗ України від 08.06.2015 № 325 «Про затвердження Державних санітарно-протиепідемічних правил і норм щодо поводження з медичними відходами»<sup>34</sup>
          </p>
        </section>

        <section class="page-wrapper__section ua-recomendations__section" id="stage-8">
          <h2 class="page-wrapper__h2-title">
            Методичні вказівки:
          </h2>
          <p class="page-wrapper__text">
            Методичні вказівки для медичних працівників кабінетів (пунктів) щеплень, виїзних (тимчасових) та мобільних бригад, затверджено Наказом МОЗ України від 18.04.2008 № 212<sup>35</sup>
          </p>
          <p class="page-wrapper__text">
            Методичні вказівки «Організація і проведення імунологічного моніторингу за інфекціями, які контролюються засобами специфічної профілактики (дифтерія, правець, кашлюк та кір)», затверджено Наказом МОЗ України від 04.07.2006 № 441<sup>36</sup>
          </p>
        </section>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />
    <Links class="links">
      <ol>
        <li
          class="list"
        >
          Про захист населення від інфекційних хвороб (rada.gov.ua) <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/1645-14#Text">https://zakon.rada.gov.ua/laws/show/1645-14#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про забезпечення санітарного та епідемічного благополуччя населення (rada.gov.ua) <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/4004-12#Text">https://zakon.rada.gov.ua/laws/show/4004-12#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про створення Національної технічної групи експертів з питань імунопрофілактики <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0585282-20#Text">https://zakon.rada.gov.ua/rada/show/v0585282-20#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Резолюція круглого столу «Стратегія розвитку імунопрофілактики та захисту населення від інфекційних хвороб» <a target="_blank" href="https://bzv.org.ua/2019/12/24/rezolyutsiya/">https://bzv.org.ua/2019/12/24/rezolyutsiya/</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про схвалення Стратегії розвитку імунопрофілактики (rada.gov.ua) <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/1402-2019-%D1%80#Text">https://zakon.rada.gov.ua/laws/show/1402-2019-%D1%80#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про внесення змін до деяких наказів Міністерства охорони здоров'я України <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1649-16#Text">https://zakon.rada.gov.ua/laws/show/z1649-16#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Порядку здійснення фармаконагляду <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0073-07#Text">https://zakon.rada.gov.ua/laws/show/z0073-07#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1182-19#Text">https://zakon.rada.gov.ua/laws/show/z1182-19#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Положення про організацію і проведення профілактичних щеплень <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1238-14#Text">https://zakon.rada.gov.ua/laws/show/z1238-14#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Рекомендації з проведення вакцинації в Україні <a target="_blank" href="https://phc.org.ua/sites/default/files/users/user90/rekomendatsii-MOZ-vaccinatsiya.pdf">https://phc.org.ua/sites/default/files/users/user90/rekomendatsii-MOZ-vaccinatsiya.pdf</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Плану заходів щодо ефективного управління вакцинами <a target="_blank" href="https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-19102020--2357-pro-zatverdzhennja-planu-zahodiv-schodo-efektivnogo-upravlinnja-vakcinami">https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-19102020--2357-pro-zatverdzhennja-planu-zahodiv-schodo-efektivnogo-upravlinnja-vakcinami</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Методики розрахунку потреби в медичних імунобіологічних препаратах <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0664-18#Text">https://zakon.rada.gov.ua/laws/show/z0664-18#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про порядок проведення профілактичних щеплень в Україні <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1159-11#Text">https://zakon.rada.gov.ua/laws/show/z1159-11#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про лікарські засоби <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/123/96-%D0%B2%D1%80#Text">https://zakon.rada.gov.ua/laws/show/123/96-%D0%B2%D1%80#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про введення в дію рішення оперативного штабу Міністерства охорони здоров’я України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації <a target="_blank" href="https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-15122020--2914-pro-vvedennja-v-diju-rishennja-operativnogo-shtabu-ministerstva-ohoroni-zdorov%e2%80%99ja-ukraini-z-reaguvannja-na-situacii-z-poshirennja-infekcijnih-hvorob-jakim-mozhna-zapobigti-shljahom-vakcinacii">https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-15122020--2914-pro-vvedennja-v-diju-rishennja-operativnogo-shtabu-ministerstva-ohoroni-zdorov%e2%80%99ja-ukraini-z-reaguvannja-na-situacii-z-poshirennja-infekcijnih-hvorob-jakim-mozhna-zapobigti-shljahom-vakcinacii</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про введення в дію Рішення оперативного штабу Міністерства охорони здоров’я України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації <a target="_blank" href="https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-25102019--2164-pro-vvedennja-v-diju-rishennja-operativnogo-shtabu-ministerstva-ohoroni-zdorov%e2%80%99ja-ukraini-z-reaguvannja-na-situacii-z-poshirennja-infekcijnih-hvorob-jakim-mozhna-zapobigti-shljahom-vakcinacii">https://moz.gov.ua/article/ministry-mandates/nakaz-moz-ukraini-vid-25102019--2164-pro-vvedennja-v-diju-rishennja-operativnogo-shtabu-ministerstva-ohoroni-zdorov%e2%80%99ja-ukraini-z-reaguvannja-na-situacii-z-poshirennja-infekcijnih-hvorob-jakim-mozhna-zapobigti-shljahom-vakcinacii</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про створення оперативного штабу Міністерства охорони здоров'я України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v1319282-19#Text">https://zakon.rada.gov.ua/rada/show/v1319282-19#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Заходів щодо підтримки Україною статусу країни, вільної від поліомієліту, на 2017–2020 роки <a target="_blank" href="http://consultant.parus.ua/?doc=0AO5EF5603">http://consultant.parus.ua/?doc=0AO5EF5603</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про посилення заходів щодо попередження захворювань на поліомієліт в Україні <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0196282-98#Text">https://zakon.rada.gov.ua/rada/show/v0196282-98#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про проведення заходів щодо імунізації населення проти кору в Україні <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v1082282-17#Text">https://zakon.rada.gov.ua/rada/show/v1082282-17#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження оперативного плану <a target="_blank" href="https://phc.org.ua/sites/default/files/users/user90/Nakaz_PHC_28.08.2017_221-dt.pdf">https://phc.org.ua/sites/default/files/users/user90/Nakaz_PHC_28.08.2017_221-dt.pdf</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Порядку проведення епідеміологічного нагляду за кором, краснухою та синдромом вродженої краснухи <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0519-19#Text">https://zakon.rada.gov.ua/laws/show/z0519-19#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Порядку проведення епідеміологічного нагляду за дифтерією <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1011-20#n21">https://zakon.rada.gov.ua/laws/show/z1011-20#n21</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про вдосконалення профілактики, діагностики та лікування правця <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0198282-99#Text">https://zakon.rada.gov.ua/rada/show/v0198282-99#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Порядку організації проведення епідеміологічного нагляду за грипом та гострими респіраторними вірусними інфекціями <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0595-19#Text">https://zakon.rada.gov.ua/laws/show/z0595-19#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про удосконалення заходів профілактики захворювань людей на сказ <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0205282-04#Text">https://zakon.rada.gov.ua/rada/show/v0205282-04#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Методичних рекомендацій Неспецифічна профілактика трансмісивних природно-вогнищевих інфекцій, що передаються іксодовими кліщами <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0369282-13#Text">https://zakon.rada.gov.ua/rada/show/v0369282-13#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження форми первинної облікової документації N 063-2/о <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0594-10#Text">https://zakon.rada.gov.ua/laws/show/z0594-10#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Інструкція щодо заповнення форми первинної облікової документації N 063/о <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0688-06#Text">https://zakon.rada.gov.ua/laws/show/z0688-06#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Порядку ведення обліку, звітності та епідеміологічного нагляду (спостереження) за інфекційними хворобами та Переліку інфекційних хвороб, що підлягають реєстрації <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1332-20#Text">https://zakon.rada.gov.ua/laws/show/z1332-20#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження форм звітності з інфекційних і паразитарних захворювань, щеплень проти окремих інфекційних хвороб та інструкцій щодо їх заповнення <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0525-09#Text">https://zakon.rada.gov.ua/laws/show/z0525-09#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження обліково-звітних форм планування і виконання профілактичних щеплень „УкрВак 08“ <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0413282-08#Text">https://zakon.rada.gov.ua/rada/show/v0413282-08#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Форм первинної облікової документації з інфекційної, дерматовенерологічної, онкологічної захворюваності та інструкцій щодо їх заповнення <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0686-06#Text">https://zakon.rada.gov.ua/laws/show/z0686-06#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Про затвердження Державних санітарно-протиепідемічних правил і норм щодо поводження з медичними відходами <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z0959-15#Text">https://zakon.rada.gov.ua/laws/show/z0959-15#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Методичні вказівки для медичних працівників кабінетів (пунктів) щеплень, виїзних (тимчасових) та мобільних бригад <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0212282-08#Text">https://zakon.rada.gov.ua/rada/show/v0212282-08#Text</a> Остання дата доступу 15.04.2021
        </li>
        <li
          class="list"
        >
          Організація і проведення імунологічного моніторингу за інфекціями, які контролюються засобами специфічної профілактики (дифтерія, правець, кашлюк та кір) <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0441282-06#Text">https://zakon.rada.gov.ua/rada/show/v0441282-06#Text</a> Остання дата доступу 15.04.2021
        </li>
      </ol>
      <p class="list">
        MAT-UA-2101723 (дата першого застосування 20.11.2021)
      </p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Закони України щодо епідемічного благополуччя",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Нормативна база з розвитку імунопрофілактики",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Фармаконагляд",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-3"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Нормативна база щодо щеплень, імунобіологічних препаратів",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-4"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Рішення засідань оперативного штабу МОЗ України з реагування на ситуації з поширення інфекційних хвороб, яким можна запобігти шляхом вакцинації",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-5"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Нормативна база щодо поліомієліту, дифтерії, правця, грипу та ГРВІ, сказу, кліщового енцефаліту",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-6"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Накази, форми",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-7"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Методичні вказівки",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/ukrainian-recomendations#stage-8"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';
import Warning from '@/components/const-pages/warning.vue';
import Vocabulary from '@/components/const-pages/vocabulary.vue';
export default {
  name: "uaRecomendations",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
    Warning,
    Vocabulary,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
.ua-recomendations {
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  .banner__text {
    @media (max-width: 768px) {
      div {
        height: 0px !important;
      }
    }
  }

  .links-wrapper {
    p.list {
      margin-top: 24px !important;

      @media (max-width: 540px) {
        margin-top: 48px !important;
      }
    }
  }

  #stage-5 .page-wrapper__h2-title {
    max-width: 762px;
  }

  .contents {
    padding-right: 40px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }
}
</style>