<template>
  <div class="risk-area">
    <div
      v-for="risk of risks"
      :key="risk.id"
      class="risk-area__item"
      id="risk-area__item"
      tabindex="-1"
    >
      <div class="scale">
        <img :src="require(`../../assets/img_flu/${risk.photo}.png`)" class="risk-area__item--photo">
        <div class="risk-area__item--wrapper">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="risk-area__item--icon" >
            <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3336C29.1203 20.8668 30 17.9667 30 15C29.9957 11.0231 28.414 7.21026 25.6019 4.39815C22.7897 1.58604 18.9769 0.00430135 15 0V0ZM15 27.5C12.5277 27.5 10.111 26.7669 8.05538 25.3934C5.99976 24.0199 4.39761 22.0676 3.45151 19.7835C2.50542 17.4995 2.25788 14.9861 2.74019 12.5614C3.22251 10.1366 4.41302 7.90932 6.16117 6.16117C7.90933 4.41301 10.1366 3.2225 12.5614 2.74018C14.9861 2.25787 17.4995 2.50541 19.7836 3.45151C22.0676 4.3976 24.0199 5.99976 25.3934 8.05537C26.7669 10.111 27.5 12.5277 27.5 15C27.4964 18.3141 26.1782 21.4914 23.8348 23.8348C21.4914 26.1782 18.3141 27.4964 15 27.5ZM21.25 15C21.25 15.3315 21.1183 15.6495 20.8839 15.8839C20.6495 16.1183 20.3315 16.25 20 16.25H16.25V20C16.25 20.3315 16.1183 20.6495 15.8839 20.8839C15.6495 21.1183 15.3315 21.25 15 21.25C14.6685 21.25 14.3505 21.1183 14.1161 20.8839C13.8817 20.6495 13.75 20.3315 13.75 20V16.25H10C9.66849 16.25 9.35054 16.1183 9.11612 15.8839C8.8817 15.6495 8.75001 15.3315 8.75001 15C8.75001 14.6685 8.8817 14.3505 9.11612 14.1161C9.35054 13.8817 9.66849 13.75 10 13.75H13.75V10C13.75 9.66848 13.8817 9.35054 14.1161 9.11612C14.3505 8.8817 14.6685 8.75 15 8.75C15.3315 8.75 15.6495 8.8817 15.8839 9.11612C16.1183 9.35054 16.25 9.66848 16.25 10V13.75H20C20.3315 13.75 20.6495 13.8817 20.8839 14.1161C21.1183 14.3505 21.25 14.6685 21.25 15Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="for-desc">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="153px" height="40px" viewBox="0 0 153 40" enable-background="new 0 0 153 40" xml:space="preserve">  <image id="image0" width="153" height="40" x="0" y="0"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJkAAAAoCAQAAABHw5DhAAAN8UlEQVRo3u2aW4wk11nHf9/p+6wZ
      7872uJ2iFop1YSMKI4UOGAsj2iI4EKREWGqDFQmCY4EsiIS4WSAUWsoTIg9E5iUvgMQDURaUkEBQ
      eAgLBgmBhwfCoCQ0ob1u92rcnfGym3V2vTP98XCu1d4ouwkmCHJKXfdTVd/v/L9LlRoEQVSQiVFB
      QN22ij0G9gwNZ2LcmrFrmImZGMzE0MBMDAZj940bGBrjBg3MuDFq0qBJc9yg4eZ2X5ji1qjp+jVo
      1o41R83kWAO/Hu6fTir+eexzextqVsjEeCsJBOqTtx4Q3KlEdPVTN/epQzUxAZ+ZuAmDTEyAlEzj
      dE9zZM1uevMdQLdGk4Y9SnNz6Y4HeO6KhgaNibE/3DBNwuBhVCZGLRqDqASMJkhF6oKxTBJQkUbC
      NdGO1rsYTSEakofxCrOYElVtYBs3Rg7LqEmTFgFbHY1HScuflQBr0Rw7nMNWAGiv7O4RIH7JSWWc
      +EJNX0FHEVxUYfS6oCJ18ks4G42d3Jg4tDVk2PE1frzH0aWsuR5hhOEQjLzxLZrD1rAV9wxbAUwK
      tWV7h6s0aI4TDXt4Htq44dA0iE7rNGZDiW54VxKixKtwYmo+GAXn5CoTf5ofBfFo/PjEW+L1ZXw8
      SRQWgQWnGnmzk8mBsmseiv81wzkO8bA19Hi9C7u46Fzb/0wYqBDr0qd2jisuBht1aDSVg8Tjoa/U
      Y9gmjNi1vlfiJcYbLuhG3DnUyEerRCtDh6pqp9D8nlGT1tBNo2aCsIZ4mIJsjppD68KtsNZIgY19
      8qiHktfYOYlpbANwVOXEEJ3Sh8b6ha2Ckq71QJsEddKM1vTKSd1qGOD4JW3aFkHVHkYQ/ng7mbc8
      Sren7ZDeLA46VY9DXo6D6bKqX3qnrc8T2ycmpBSPMHU+n0lSuk7AvlNEFeJDHMVR4ojW3ZzpTYfB
      b7ed4W3atB0sC8/ua9Gq2lXcDsdsv6qdgvQq9NFu6AZqHLQdHDc6aLDDysGJIkTkmHlxoCexDoho
      Nt2yFqtC91Fz7PJiqrBRiFbDEJ6HHlYAZ9VkTa/atGmVnQilalfJ0q/H42Wn7LjtTuXxWfcNKhyl
      8MJk9ThqRs1FH4kwvT/Vwwwbto4bgpkwERTGck5hgm37AnBObek20X2BcyDomHOMATgnKCDo0HyD
      ngdGXJE9RnpehuwxZE+GCnsAVEK8tvr1in1XHkIJ5PK+U2ff2Ni+88OAVlyXKSVTYg+k0n0qYB8Y
      KlyTru65Y9gzdQScZyh7iqBD2XP7wT7/OZCxujU3hzFLOa/IiPMKY6jvd+f5ki/ytDLdKEZtRVTL
      gEHyPsP5IBwdy+klcTDadOhYnZQdOmGrU3boPNj7hzOv/OL6haO/ffnt/mjZoUvH9ivdz/eJTurv
      UgsBNr7F2BiqvFhMJwV0yPDjVGMbyW1iY5n1U3uZmGO874eOG4XnqCb9JGx7R2lvTB06pTM4wgpT
      l+6Dvb+6++pT66WqXj36xOL76RZdunRjPzplp3AA4776fcpO1R4mGbbmsEkJvZk2Yp5PUIaYnYBL
      ODbTIiHp1Ig19yawJAP67BZCu487dl56MCmkDh2LpejSK7pP33nwyI1Pqqrqen14/Q+eu4cu3byH
      A0eXbtEtuhF+HVqIgUk+tviqts23NxnqZiiOa28j9XffUGk2aGCFOI46atjO45r71YrKWGyGwiG6
      RZrlrClRH2UAVXYchi5dejhgL4xu/Jkeq23H64uv/s5fZvQ8VKc4u+zQLTsen8fmkHWqTX3HUiWG
      j6ZXX0gfzbpl8Y0ivPxbGYXSILx+JJCSVxxXMGzW7jaKtOMo+0dPxt+5XYrJAyi69OgV3bz3M3Vg
      Dtq193/sG+nRzXt5r+jSywPA+nXo1ODdxF3DE8aSp+UL5+i0NYANXyTXvayBKeQ8I2bA0FzkX2VE
      QcHM5Z8R5xnKRTkte1SyBGDIxZCfKoElFXdxF9flECjlM1KYz7n0VphL9mWXbbnkvpvkckFOckly
      uSA5l7kkP9F++v43vLv1NgyxiWw1vrk4sfP3+0dztrkgOWu5k7VsywUByOUCd8hloBC4BFJy6POv
      QMXSZeWlu+QSGMqeQEbGRWaCs2UmQy4KzMyQjItq3yZnknEfBR+XmWM0guRl19MNXxCSmscVkxul
      ZLtqu1qpU7WrdtnB/zrBhbpFt+jmPXrBDbfo2Snv5T228t47tj/7wBf/sKYw347Wz19976+dZivb
      opf36Pml7e816hWYxksf66r2hvK8xny0jb5i453N9KHkDt7lPxIkLy/JCwterBZgOw3o1U3yYHDA
      dog1HRe6eza0Y6H1si162ZZFwBZb9H7h5PMP3Th3U2DOPV95/5/k2Va2hZsGJyz2ouud1uHrxgAQ
      c3K9lNlw0xjtNtar+GtVtbLZjHwR6MrNIX57yBVBhoJUIFVwl9R17G8qJaUgJYV3LCkkF2ROzkzg
      yDlSJgsQyIBMEHhn5+e/K3u6+WjNJdNm5K7uYz/6K8/sHAuSMRBoKCCZzCSTzMxlba/uvv0dCYIc
      hc85U0qQEth3z+ltuR6+slaCVFTOolKQ6wL7CvtUss81GQJ7ckUqEVr+2YbsgWCrZa5JV2FPAhzd
      WJMSgClQMqUEppLLnEJnEWzolwks7BoLBnIAwLs6v/ndb/jV5uhLArNtrS9dO/env/34odtWfx2/
      nYlb10JhJjlzW+sL4COzJlaAIiVTLWWqFuG+vOYcQd37iZ1raaYqtGDIntgD8TYw1D1BLI6pe9DK
      vUihSCEwAwqaOvWPJlDozAdgzZn7lxghgIJMF7Ir79n+8becftp865cBBqB65cbfvfTMmWetQQMO
      kmMOniIZC2u0xruihTtx5pAUMktewCrdj56jpUzDhrMbKOnovqClTNVEYBWAVFIBFXBN3Dj4joLZ
      d596CxPzEkzdLY8EcuuEMjADQdb+07AB5AAYALCQ+8wfZe98V/+3zL23AAxEttsPD37jhUeQAciB
      DNyVYCALQQaCAev2OZnJBHLJgVyOBDuYIAWYmUAhhbPgqimBQkrK4B2FIIWDVwJT9gVKmYII7VSC
      VqCRLlKqdz3ECdy1XOZekZpEuGQtamEAHMguRg8EYNyeVOVTrbfJiVvAFdvRevHFD37sdx+/5F+x
      YVeWigwUDoKqvNPmOicTMArzRJWJ6ghuS6nTJPwUzBSBUqeULhbPFIdM6iK0ZttTp647MRpYh3O4
      cv8oArnOJWNBBiwEHcgBu7Jk11ZFDmtfTvOz2+94uP9L5jtvSV/1ttbPX//kix/43udWDtlAYS1L
      0F1ZajKAmnbLWOC/ulhkM68nDeCcwxZiYRU0FaZWNOKhFgid0oVw8EKcBj3lMlc7z2Xub2BRSa6w
      FqOwlmNpqA3qmS5CJIuK64uoLScfar3v7P1PbP0kJ2q59zaavnr86S/83kc//FMvo30Rh2YJdnB0
      ANgQcKD1julGnuiu0JmVgT/HRcAZiEXqRVMwQ8rO1JlXqo9JBTNBE2CguTR1ZtfF55VMFmQsbu6c
      mmRN10p5z+m3/sjOu+XW4teX0drzH/i25/rASpE+ostUW25p492BusGMWVZz5oJmslCLzyatHGjq
      kczJgbkiaGaMziNiFTo1s4J5hR5JU2Emhc4kx3bKmdfrMrV50EUtQXcljHftiqX89InH3vRNT7Tf
      /JXrq9aOdXn94//0zPd9rq+rsLPPil2n5+CIDDgQFM04loYe+7ytgGYCsEhzu2uxcMnEuKCDgtAF
      q6eCI5knVUnOWozOSTrUUCHeBeL+XcDoWlRWiunrij4rOSW/vPXYm8480flBTnyV+qo1ffX405d/
      /0Mfeeqwr+DB9VnRZ6XQl5XusmSXpXPXLAAb2HQB1k8iqKR69L90Pwg9Lzm7jFrKIMEUArurrgYc
      bGglKEv6rIAdgUM9ZV4fXJ6avnr82Ssf/NRf/MC/O+XIDkZXtaJ0lyUDl6sHuhaXjkIbAAcaoThU
      /jN9cq2BHKjQswK0wXyhSK5rWYBkupAB1un8OlG84h5GAO1LHGNYSV9XIGfl10/+0AN3P95+fXAF
      bNxYv/jKJy788UPPvcyOHrLDIam7WuUlmFz4cEsNxZB3Zcl0Iagrk2wZ7qGK0LPvfFZDVlf2lQYb
      AYAEVtoEhb4A1hXcby2HnJIne0/cd+btnbc278G8jrgitmP9z6N/vvLRD33kqZd39BDYAQ4BtM9a
      Dm0V5+Kc9wjvuvVsaoXig86AgyTlgWRbRq0bHovNLI61U1PSwd1MZVV72j5rMbqWQ/q6krPyw70n
      v6V4+I63NN8oW5j/llB/6+CO1i9df/bzf/6Pe4++6E3c4dCpp+8HV5E+uCzbxxZADl2wE2zpYis/
      bBmuA2Rw4iC4maupZKAHrqr2nZdOU7uouEArNm4c6o7AIUjVeO/gwe/ZfqD9UKOQLTXyPwkrbWuO
      15fX//LKXx88+zf/9uQlQE+JdVmiwwra9ylDXYDB2ghL+iKqskoLY7F1oHAiheXvuuuWSxfM/c/d
      LLSz8kjnx+66956T93e+o/XtjYIt8X85+lo3VeVYrxxPb3zq2v7qM/v/8ejCmr6D/yDi8dWsU2RX
      l15rTocr4lx+7vSbT97bP5V3T687zW3pAUnoWdfC0NotxciJ5o65w5wxd8s2Pf9nmP8VqF6DDkUV
      OV5/YT3XlV49OtDLR9fsV7d1Yq23zoR1Ewikc1lfpieo96LbM1puu8fXGl+cf8VN9Ku8wP+/9vqn
      //9z7evIbrt9Hdltt/8CMiRB8I5kbAIAAAAASUVORK5CYII=" />
        </svg>
      </div>
      <div class="risk-area__item--name">{{ risk.name }}</div>
      <div class="risk-area__item--desc" :class="{
        first: risk.id === 0,
        second: risk.id === 1,
        third: risk.id === 2,
        fourth: risk.id === 3,
        fivth: risk.id === 4
       }">
        <div class="risk-area__item--mobile-cross" @click="loseFocus()">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M19.9998 9.99996C19.7654 9.76562 19.4475 9.63397 19.1161 9.63397C18.7846 9.63397 18.4667 9.76562 18.2323 9.99996L14.9998 13.2325L11.7673 9.99996C11.5316 9.77226 11.2158 9.64626 10.8881 9.64911C10.5603 9.65196 10.2468 9.78342 10.015 10.0152C9.78328 10.2469 9.65182 10.5605 9.64897 10.8882C9.64613 11.216 9.77212 11.5317 9.99982 11.7675L13.2323 15L9.99982 18.2325C9.77212 18.4682 9.64613 18.784 9.64897 19.1117C9.65182 19.4395 9.78328 19.753 10.015 19.9847C10.2468 20.2165 10.5603 20.3479 10.8881 20.3508C11.2158 20.3536 11.5316 20.2277 11.7673 20L14.9998 16.7675L18.2323 20C18.4681 20.2277 18.7838 20.3536 19.1116 20.3508C19.4393 20.3479 19.7528 20.2165 19.9846 19.9847C20.2163 19.753 20.3478 19.4395 20.3507 19.1117C20.3535 18.784 20.2275 18.4682 19.9998 18.2325L16.7673 15L19.9998 11.7675C20.2342 11.533 20.3658 11.2152 20.3658 10.8837C20.3658 10.5522 20.2342 10.2344 19.9998 9.99996Z" fill="#F15252"/>
            <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3336C29.1203 20.8668 30 17.9667 30 15C29.9957 11.0231 28.414 7.21026 25.6019 4.39815C22.7897 1.58604 18.9769 0.00430135 15 0V0ZM15 27.5C12.5277 27.5 10.111 26.7669 8.05538 25.3934C5.99976 24.0199 4.39761 22.0676 3.45151 19.7835C2.50542 17.4995 2.25788 14.9861 2.74019 12.5614C3.22251 10.1366 4.41302 7.90932 6.16117 6.16117C7.90933 4.41301 10.1366 3.2225 12.5614 2.74018C14.9861 2.25787 17.4995 2.50541 19.7836 3.45151C22.0676 4.3976 24.0199 5.99976 25.3934 8.05537C26.7669 10.111 27.5 12.5277 27.5 15C27.4964 18.3141 26.1782 21.4914 23.8348 23.8348C21.4914 26.1782 18.3141 27.4964 15 27.5Z" fill="#F15252"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="30" height="30" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div class="risk-area__item--title">{{ risk.name }}</div>
        <div class="risk-area__item--text" v-html="risk.descriptions"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "risk-area-block",
  data: () => {
    return {
      risks: [
        {
          id: 0,
          name: 'Діти до 5 років',
          photo: 'children',
          descriptions: 'Імунітет у дитини ще формується, водночас перебування в колективі у дошкільних закладах спричиняє поширення інфекції. Тому ризик зараження вірусом грипу у дітей є досить високим. Відповідно насамперед діти потребують захисту. Серед українців, які <strong>перехворіли на ГРВІ та грип у минулому сезоні, 63% були дітьми.</strong> Тому, окрім вакцинацій згідно з Календарем профілактичних щеплень, можна подбати і про захист від грипу, особливо перед вступом до нового колективу<sup>2</sup>.'
        },
        {
          id: 1,
          name: 'Вагітні',
          photo: 'pregnant',
          descriptions: 'Вагітні також є однією з найбільш уразливих груп до вірусу грипу та його ускладнень. <strong>У цієї категорії стаціонарне лікування грипу відбувається в 4-5 разів частіше, ніж у інших пацієнтів.</strong> Тому вагітним рекомендується профілактика грипу за допомогою вакцинації, а головне вона є безпечною в будь-якому триместрі<sup>2,4</sup>.'
        },
        {
          id: 2,
          name: 'Люди віком 60 років і старше',
          photo: 'oldest',
          descriptions: 'З віком імунітет слабшає і гірше справляється з грипом. Тому з-поміж людей похилого віку, госпіталізованих внаслідок грипу, понад 30% мали погіршення стану після виписки. Ця група потребує особливої турботи та захисту від інфекцій<sup>2,5</sup>.'
        },
        {
          id: 3,
          name: 'Люди з хронічними захворюваннями',
          photo: 'chronic',
          descriptions: 'Особи з хронічними станами, такими як бронхіальна астма, цукровий діабет або хвороби серця, мають підвищений ризик тяжкого перебігу грипу та навіть смертності від грипу. У цих пацієнтів грип збільшує ризик розвитку інфаркту міокарда та інсульту, тому профілактика грипу так важлива серед даної категорії людей<sup>2,6</sup>.'
        },
        {
          id: 4,
          name: 'Професійна група ризику',
          photo: 'profGroup',
          descriptions: 'Люди певних професій мають багато контактів з іншими людьми, тому у них відзначають підвищений ризик розвитку грипу, зокрема у медичних працівників, вчителів, продавців тощо<sup>3</sup>.'
        }
      ],
    }
  },
  methods: {
    loseFocus() {
      [...document.querySelectorAll('.risk-area__item')].forEach(item => item.blur());
    }
  }
}
</script>

<style scoped lang="scss">
$fontM: "Raleway-Medium";
.risk-area {
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  &__item {
    position: relative;
    @media screen and (max-width: 768px) {
      max-width: 250px;
      align-self: center;
      justify-self: center;
      margin-top: 40px;
    }
    &:nth-child(5) {
      & .risk-area__item--photo {
        border-right: transparent;
        @media (max-width: 768px) {
          border-right: none;
          border-bottom: 2px solid #FFFFFF;
        }
      }
      & > .risk-area__item--wrapper {
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
        @media screen and (max-width: 768px) {
          border-top-left-radius: 60px;
          border-bottom-right-radius: 0;
        }
      }
      & .risk-area__item--title {
        @media screen and (max-width: 768px) {
          max-width: 205px;
        }
      }
      & .for-desc {
        @media (max-width: 768px) {
          top: 202px;
        }
      }
    }
    &:nth-child(5) > .scale {
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-right-radius: 60px;
        border-bottom-left-radius: 60px;
      }
    }
    &:nth-child(1) {
      @media (max-width: 768px) {
        margin-top: 0;
      }
      & > .scale {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
        @media screen and (max-width: 768px) {
          border-top-left-radius: 60px;
          border-top-right-radius: 60px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    &:nth-child(3) {
      & .risk-area__item--title {
        @media (max-width: 768px) {
          max-width: 240px;
        }
      }
      & > .for-desc {
        @media screen and (max-width: 768px) {
          top: 68%;
        }
      }
    }
    &:nth-child(4) > .for-desc {
        @media screen and (max-width: 768px) {
          top: 68%;
        }
      }
    &:hover {
      cursor: pointer;
    }
    &--photo {
      width: 100%;
      height: 230px;
      transition: 1s;
      position: relative;
      object-fit: cover;
      border-right: 2px solid #fff;
      @media (max-width: 768px) {
        border-right: none;
        border-bottom: 2px solid #FFFFFF;
      }
    }
    &--icon {
      position: absolute;
      bottom: 18px;
      right: 18px;
    }
    &--wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 50%;
      display: flex;
      align-items: center;
      z-index: 2;
      transition: 1s;
      background: linear-gradient(317.81deg, rgba(46, 123, 189, 0.5) 1.2%, rgba(46, 123, 189, 0) 35.23%);
      @media (max-width: 768px) {
        bottom: 2px;
      }
    }
    &--desc {
      display: none;
      position: absolute;
      top: 230px;
      z-index: 3;
      background-color: #fff;
      padding: 28px 48px;
      color: #3F4C56;
      font-size: 16px;
      line-height: 22px;
      width: 100%;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.12);
      border-radius: 62px;
      width: max-content;
      transition: 1s;
      @media screen and (max-width: 992px) {
        padding: 20px;
        border-radius: 32px;
      }
      @media screen and (max-width: 768px) {
        padding: 35px;
        border-radius: 32px;
      }
      &.first {
        max-width: 40vw;
        left: 0;
        @media screen and (max-width: 768px) {
          max-width: 320px;
          left: -35px;
        }
      }
      &.second {
        max-width: 29vw;
        left: 0;
        @media screen and (max-width: 768px) {
          max-width: 320px;
          left: -35px;
        }
      }
      &.third {
        max-width: 26vw;
        left: 0;
        @media screen and (max-width: 768px) {
          max-width: 320px;
          left: -35px;
        }
      }
      &.fourth {
        max-width: 33vw;
        right: 0;
        @media screen and (max-width: 768px) {
          max-width: 320px;
          left: -35px;
        }
      }
      &.fivth {
        max-width: 25vw;
        right: 0;
        @media screen and (max-width: 768px) {
          max-width: 320px;
          left: -35px;
        }
      }
    }
    &:hover > &--desc {
      @media screen and (min-width: 769px) {
        display: block;
        cursor: default;
      }
    }

    &:focus > &--desc {
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &:hover > .scale > img {
      @media screen and (min-width: 769px) {
        transform: scale(1.1);
      }
    }

    &:focus > .scale > img {
      @media screen and (max-width: 768px) {
        transform: scale(1.1);
      }
    }

    &:hover > .scale::before {
      @media screen and (min-width: 769px) {
        opacity: 1;
      }
    }

    &:focus > .scale::before {
      @media screen and (max-width: 768px) {
        opacity: 1;
      }
    }

    &:hover > .scale > &--wrapper {
      @media screen and (min-width: 769px) {
        display: none;
      }
    }

    &:focus > .scale > &--wrapper {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &:hover > .for-desc {
      @media screen and (min-width: 769px) {
        display: block;
      }
    }

    &:focus > .for-desc {
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    
    .scale {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      height: 230px;
      max-width: 256px;
      box-shadow: 0px 0px 40px rgba(46, 123, 189, 0.2);
      position: relative;
      &::before {
        content: "";
        width: 50%;
        height: 50%;
        z-index: 3;
        transition: 1s;
        background: linear-gradient(46.45deg, rgba(241, 82, 82, 0.5) 10.36%, rgba(241, 82, 82, 0) 51.12%);
        position: absolute;
        bottom: 0;
        opacity: 0;
      }
    }
    &--name {
      text-align: center;
      font-size: 21px;
      line-height: 25px;
      font-family: 'Raleway-SemiBold';
      color: #537FCB;
      margin-top: 32px;
      @media screen and (max-width: 1288px) {
        font-size: 18px;
        margin-top: 16px;
        line-height: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
    &--title {
      font-family: $fontM;
      font-size: 30px;
      line-height: 28px;
      color: #F15252;
      margin-bottom: 22px;
      @media screen and (max-width: 992px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    &--mobile-cross {
      display: none;
      position: absolute;
      z-index: 5 !important;
      top: 16px;
      right: 16px;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    & > .for-desc {
      position: absolute;
      z-index: 4;
      top: 62%;
      display: none;
      left: calc(50% - 76.5px);
      
      @media screen and (max-width: 1288px) {
        left: calc(50% - 100px / 2);
        top: 70%;
      }
      @media screen and (max-width: 992px) {
        left: calc(50% - 80px / 2);
      }
      @media screen and (max-width: 885px) {
        top: 66%;
      }
      @media screen and (max-width: 768px) {
        left: 20px;
        top: 74%;
      }
      
      & > svg {
        max-width: none;
        @media screen and (max-width: 1288px) {
          max-width: 100px;
        }
        @media screen and (max-width: 992px) {
          max-width: 80px;
        }
      }
    }
  }
}
</style>
