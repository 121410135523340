<template>
  <div class="top-block">
    <div class="line1">
      <div class="content">
        <div class="logo">
          <svg viewBox="0 0 231 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M58.0637 6.71135C54.6926 6.71135 51.9987 9.54373 51.9987 13.115C51.9987 16.6093 54.708 19.3955 58.1561 19.3955C61.5734 19.3955 64.2826 16.6093 64.2826 13.115C64.298 9.43598 61.635 6.71135 58.0637 6.71135ZM58.1715 4.40234C62.9896 4.40234 66.8072 8.2199 66.8072 13.0996C66.8072 17.8408 62.9126 21.7199 58.1715 21.7199C53.3995 21.7199 49.505 17.8562 49.505 13.0996C49.505 8.2353 53.3226 4.40234 58.1715 4.40234Z"
                  fill="#4D5CA6"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.8187 6.46464C4.26397 6.46464 2.90935 7.54218 3.09407 9.15848C3.17104 9.85119 3.50969 10.2822 4.187 10.7286C4.37172 10.8518 4.75655 11.0057 5.23375 11.175C5.72634 11.3597 6.31129 11.5445 6.92702 11.76C8.1431 12.191 9.38996 12.6836 9.92873 13.1762C10.8369 14.0228 11.3757 15.1157 11.3757 16.4242C11.3757 19.6414 8.78962 21.7811 5.74173 21.7811C2.66305 21.7811 0.0769669 19.6568 0 16.5473H2.40137C2.70923 18.6716 4.04846 19.6106 5.74173 19.6106C7.31185 19.6106 8.91277 18.6254 8.91277 16.7936C8.91277 15.8084 8.43557 15.0541 7.54276 14.5616C7.29646 14.4538 6.95781 14.3153 6.55758 14.1767C6.15735 14.0382 5.69555 13.8843 5.21836 13.7303C4.27936 13.4225 3.27879 13.0838 2.64766 12.7144C1.43158 12.0063 0.646522 10.7286 0.646522 9.25084C0.646522 6.38768 2.83238 4.30957 5.92645 4.30957C8.3586 4.30957 10.8523 6.20296 11.0063 8.92758H8.57411C8.45096 7.44982 7.2041 6.46464 5.8187 6.46464Z"
                  fill="#4D5CA6"/>
            <path d="M87.6345 4.55664H85.1561V21.5663H87.6345V4.55664Z" fill="#4D5CA6"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M72.6103 4.55664H80.6457V6.83486H73.4416V12.3611H80.6457V14.6239H73.4416V21.5509H70.9633V6.20373C70.9633 5.28013 71.7021 4.55664 72.6103 4.55664Z"
                  fill="#4D5CA6"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.7124 8.51238L34.697 21.5506H32.2186V6.1264C32.2186 5.17201 32.9883 4.40234 33.9427 4.40234C34.5584 4.40234 35.0664 4.66403 35.4051 5.21819C37.868 9.22047 42.409 16.7632 42.9016 17.5945L42.917 4.55628H45.3954L45.38 19.9804C45.38 20.9348 44.6103 21.7045 43.6559 21.7045C43.0402 21.7045 42.5322 21.4428 42.1935 20.8887C39.7306 16.8864 35.2049 9.34362 34.7124 8.51238Z"
                  fill="#4D5CA6"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.0429 7.68113L18.5337 14.5466H23.552L21.0429 7.68113ZM17.7179 16.7632L15.9631 21.5506H13.3616L19.4574 5.46449C19.6883 4.84875 20.3194 4.40234 21.0275 4.40234C21.7356 4.40234 22.3667 4.84875 22.5976 5.46449L28.7088 21.5506H26.1227L24.3678 16.7632H17.7179Z"
                  fill="#4D5CA6"/>
            <path
                d="M229.885 9.11288C225.682 2.94013 220.957 8.3586 216.231 7.68129C211.536 7.01938 211.367 0.569555 217.154 0.0307868C217.293 0.0153934 217.432 0.0153934 217.57 0C223.343 0.0615735 228.222 3.86374 229.885 9.11288Z"
                fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M229.992 9.46736C230.316 10.6065 230.485 11.8072 230.485 13.054C230.485 20.2581 224.635 26.1076 217.431 26.1076C214.26 26.1076 211.366 24.9839 209.104 23.1059C209.996 23.4599 211.043 23.6139 211.951 23.6139C223.773 23.6601 218.724 7.94341 229.992 9.46736Z"
                  fill="#4D5CA6"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M212.66 22.0122C211.028 23.0128 208.427 22.7049 206.626 20.242C206.225 19.6878 205.887 19.1337 205.61 18.5795C204.809 16.8862 204.378 15.0083 204.378 13.0225C204.378 12.4991 204.409 11.9758 204.471 11.4678C207.318 15.3007 210.597 15.193 212.675 16.4398C213.599 17.0094 214.215 18.0407 214.215 19.2106C214.215 20.3959 213.599 21.4427 212.66 22.0122Z"
                  fill="#BDBD00"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M97.9326 21.5341H99.6413V14.6686H103.105C106.907 14.6686 109.201 12.7907 109.201 9.65041C109.201 7.91096 108.446 6.38701 107.076 5.52498C105.983 4.81689 104.783 4.52441 102.674 4.52441H97.9326V21.5341ZM99.6413 13.1447V6.06375H102.104C104.182 6.06375 104.629 6.10993 105.491 6.49477C106.738 7.07971 107.477 8.23422 107.477 9.63502C107.477 10.8049 106.922 11.8978 105.999 12.4828C105.229 12.9754 104.336 13.1447 102.812 13.1447H99.6413Z"
                  fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M109.17 21.5341H110.925L113.418 15.7924H121.315L123.763 21.5341H125.625L118.36 4.52441H116.451L109.17 21.5341ZM114.065 14.2684L117.405 6.46398L120.653 14.2684H114.065Z"
                  fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M126.918 16.7641C127.041 19.7966 129.181 21.8285 132.198 21.8285C135.046 21.8285 137.37 19.5811 137.37 16.8103C137.37 15.4095 136.739 14.0241 135.692 13.1313C134.938 12.5002 134.553 12.3462 132.537 11.7767C130.012 11.0686 129.196 10.3143 129.196 8.77496C129.196 7.06629 130.474 5.74246 132.121 5.74246C133.83 5.74246 135.077 6.98933 135.215 8.82114H137.001C137.001 6.21966 134.938 4.20312 132.306 4.20312C129.535 4.20312 127.488 6.14269 127.488 8.82114C127.488 9.99104 127.919 11.0686 128.688 11.8228C129.381 12.5155 130.12 12.8696 131.875 13.3006C134.523 13.9933 135.677 15.0555 135.677 16.8719C135.677 18.7037 134.122 20.2892 132.291 20.2892C130.274 20.2892 128.996 18.9962 128.75 16.7487H126.918V16.7641Z"
                  fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M143.051 21.5341H144.759V6.06375H148.654V4.52441H139.156V6.06375H143.051V21.5341Z" fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M151.579 21.5341H160.876V19.9948H153.272V13.7143H160.615V12.1749H153.272V6.06375H160.876V4.52441H151.579V21.5341Z"
                  fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M164.494 4.52441V15.1305C164.494 19.2867 166.71 21.842 170.281 21.842C173.853 21.842 176.069 19.3021 176.069 15.1305V4.52441H174.361V15.1305C174.361 18.2707 172.806 20.1795 170.297 20.1795C167.772 20.1795 166.218 18.2707 166.218 15.1305V4.52441H164.494Z"
                  fill="#D5B078"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M180.349 21.5341H182.058V6.06375H185.028C186.583 6.06375 187.522 6.23308 188.353 6.69488C189.446 7.32601 190.185 8.63445 190.185 9.9121C190.185 11.5592 189.246 12.9292 187.661 13.4834C186.845 13.7912 186.322 13.8374 183.735 13.8374L189.523 21.5341H191.648L186.86 15.192C189.816 15.0227 191.909 12.8676 191.909 10.0352C191.909 7.77242 190.632 5.86364 188.569 5.017C187.753 4.70913 186.429 4.52441 184.69 4.52441H180.349V21.5341Z"
                  fill="#D5B078"/>
          </svg>

        </div>
      </div>

    </div>
    <div class="line2" v-bind:class="[{ active: activeColor }]">
      <div class="content">
        <div class="item item1">
          <div class="logo">
            <svg width="157" height="31" viewBox="0 0 157 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path
                    d="M14.7233 10.6288L9.24632 25.6397H5.58111L0 10.6288H4.49821L7.41371 19.9587L10.2667 10.6288H14.7233Z"
                    fill="#515BA2"/>
                <path
                    d="M23.2407 23.2143H18.1802L17.3889 25.6615H13.1614L18.7841 10.6506H22.6368L28.3845 25.6615H24.0529L23.2407 23.2143ZM20.7 15.3265L19.1382 19.9805H22.2827L20.7 15.3265Z"
                    fill="#515BA2"/>
                <path
                    d="M35.9439 26.0111C31.5915 26.0111 28.3428 22.6244 28.3428 18.1451C28.3428 13.6222 31.5915 10.2573 35.9439 10.2573C39.6924 10.2573 42.5871 12.8574 43.1702 16.6593H39.1718C38.672 15.1298 37.4433 14.1247 35.9439 14.1247C33.9239 14.1247 32.4245 15.829 32.4245 18.1451C32.4245 20.4612 33.9031 22.1437 35.9439 22.1437C37.5266 22.1437 38.7761 21.0512 39.1718 19.3906H43.191C42.6704 23.3454 39.7341 26.0111 35.9439 26.0111Z"
                    fill="#515BA2"/>
                <path
                    d="M51.9586 26.0111C47.6061 26.0111 44.3574 22.6244 44.3574 18.1451C44.3574 13.6222 47.6061 10.2573 51.9586 10.2573C55.7071 10.2573 58.6018 12.8574 59.1849 16.6593H55.1864C54.6866 15.1298 53.458 14.1247 51.9586 14.1247C49.9385 14.1247 48.4391 15.829 48.4391 18.1451C48.4391 20.4612 49.9177 22.1437 51.9586 22.1437C53.5413 22.1437 54.7908 21.0512 55.1864 19.3906H59.2057C58.6851 23.3454 55.7487 26.0111 51.9586 26.0111Z"
                    fill="#515BA2"/>
                <path d="M61.1006 10.6288H65.099V25.6397H61.1006V10.6288Z" fill="#515BA2"/>
                <path
                    d="M80.6138 10.6288V25.6397H77.3651L71.6799 17.4896V25.6397H67.8689V10.6288H71.3259L76.8237 18.4947V10.6288H80.6138Z"
                    fill="#515BA2"/>
                <path
                    d="M92.7132 22.0563V25.6397H83.3835V10.6288H92.5257V14.2121H87.2987V16.419H91.9843V19.8057H87.2987V22.0563H92.7132Z"
                    fill="#515BA2"/>
                <path
                    d="M128.074 10.3447V25.3556H124.096V19.5872H119.806V25.3556H115.829V10.3447H119.806V15.7635H124.096V10.3447H128.074Z"
                    fill="#ACB317"/>
                <path
                    d="M130.677 19.5435V10.3447H134.696V19.4561C134.696 20.9201 135.592 21.8596 136.904 21.8596C138.216 21.8596 139.111 20.9201 139.111 19.4561V10.3447H143.13V19.5435C143.13 23.2362 140.527 25.7271 136.883 25.7271C133.259 25.7271 130.677 23.2362 130.677 19.5435Z"
                    fill="#ACB317"/>
                <path
                    d="M154.813 17.4241C156.167 18.1014 157 19.4343 157 21.073C157 23.4984 155.001 25.3556 152.356 25.3556H145.775V10.3447H152.231C154.772 10.3447 156.625 11.9616 156.625 14.1903C156.625 15.5231 155.896 16.7249 154.813 17.4241ZM153.106 20.5923C153.106 19.8057 152.543 19.2376 151.689 19.2376H149.544V21.947H151.689C152.543 21.947 153.106 21.4008 153.106 20.5923ZM152.876 14.8676C152.876 14.081 152.377 13.5566 151.544 13.5566H149.544V16.1786H151.544C152.377 16.1786 152.876 15.6542 152.876 14.8676Z"
                    fill="#ACB317"/>
                <path
                    d="M101.251 6.95795H102.001V15.545C102.001 16.6812 102.709 17.6426 103.688 17.9703V23.8917C103.688 24.4161 104.104 24.8531 104.604 24.8531C105.104 24.8531 105.521 24.4161 105.521 23.8917V18.014C106.583 17.73 107.353 16.7467 107.353 15.545V6.95795H108.02C108.519 6.95795 108.936 6.52095 108.936 5.99655C108.936 5.47215 108.519 5.03515 108.02 5.03515H107.353H105.708V2.435H106.354C106.853 2.435 107.27 1.998 107.27 1.47361C107.27 0.949206 106.853 0.512207 106.354 0.512207H103.23C102.73 0.512207 102.314 0.949206 102.314 1.47361C102.314 1.998 102.73 2.435 103.23 2.435H103.896V5.03515H102.022H101.272C100.772 5.03515 100.356 5.47215 100.356 5.99655C100.356 6.52095 100.752 6.95795 101.251 6.95795ZM105.521 15.545C105.521 15.8946 105.25 16.1786 104.917 16.1786H104.417C104.084 16.1786 103.813 15.8946 103.813 15.545V6.95795H105.521V15.545Z"
                    fill="#BCA36A"/>
                <path
                    d="M108.207 19.6965C107.728 19.5435 107.228 19.8276 107.082 20.3301C106.937 20.8327 107.207 21.3571 107.686 21.51C109.831 22.1874 111.206 23.4765 111.206 24.8312C111.206 26.8851 108.186 28.6113 104.625 28.6113C101.064 28.6113 98.0443 26.8851 98.0443 24.8312C98.0443 23.4765 99.4187 22.1874 101.564 21.51C102.043 21.3571 102.313 20.8327 102.168 20.3301C102.022 19.8276 101.522 19.5435 101.043 19.6965C98.0651 20.6142 96.2117 22.6025 96.2117 24.8531C96.2117 28.0432 99.8977 30.5341 104.625 30.5341C109.352 30.5341 113.038 28.0432 113.038 24.8531C113.038 22.6025 111.185 20.6142 108.207 19.6965Z"
                    fill="#BCA36A"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="157" height="30" fill="white" transform="translate(0 0.534058)"/>
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>
        <div class="item item2">
          <span onclick="gtag('event', 'koklyush', {'event_category': 'main_menu', 'event_label': 'Коклюш'})">
          <router-link class="link" to="/pertussis">Вакцинація перед школою</router-link>
          </span>
          <span onclick="gtag('event', 'shcho_take_meningit', {'event_category': 'main_menu', 'event_label': 'Що таке менінгіт'})">
          <router-link class="link" to="/#stage2">Що таке менінгіт</router-link>
          </span>
          <span onclick="gtag('event', 'meninngokovyi_meningit', {'event_category': 'main_menu', 'event_label': 'Менінгококовий менінгіт'})">
          <router-link class="link" to="/#stage4">Менінгококовий менінгіт</router-link>
          </span>
          <span onclick="gtag('event', 'hto_mozhe_zahvority', {'event_category': 'main_menu', 'event_label': 'Хто може захворіти'})">
          <router-link class="link" to="/#stage6">Хто може захворіти</router-link>
          </span>
          <span onclick="gtag('event', 'zahyst_vid_infekcii', {'event_category': 'main_menu', 'event_label': 'Захист від інфекції'})">
          <router-link class="link" to="/#stage9">Захист від інфекції</router-link>
          </span>
        </div>

        <div class="icon-menu">

          <svg v-if="!openMenu" @click="openMenu = !openMenu" class="open" width="26" height="18" viewBox="0 0 26 18"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 1H1" stroke="rgba(143, 96, 136, 1)" stroke-width="2" stroke-linecap="round"/>
            <path d="M21 9H6" stroke="rgba(143, 96, 136, 1)" stroke-width="2" stroke-linecap="round"/>
            <path d="M25 17H1" stroke="rgba(143, 96, 136, 1)" stroke-width="2" stroke-linecap="round"/>
          </svg>

          <svg v-if="openMenu" @click="openMenu = !openMenu" class="close" width="18" height="18" viewBox="0 0 18 18"
               fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5146 1.51456L1.51465 16.5145" stroke="rgba(143, 96, 136, 1)" stroke-width="2"
                  stroke-linecap="round"/>
            <path d="M16.5146 16.5146L1.51465 1.51468" stroke="rgba(143, 96, 136, 1)" stroke-width="2"
                  stroke-linecap="round"/>
          </svg>

        </div>
      </div>
    </div>

    <div v-if="openMenu" class="drop-list">
      <div class="color">
        <div
            @click="openMenu = !openMenu; "
            onclick="gtag('event', 'koklyush', {'event_category': 'main_menu', 'event_label': 'Коклюш'})"
            class="link">
          <router-link to="/pertussis2">Вакцинація перед школою
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                  fill="#EDD1E2"/>
            </svg>
          </router-link>
        </div>
        <div
            @click="openMenu = !openMenu; "
            onclick="gtag('event', 'shcho_take_meningit', {'event_category': 'main_menu', 'event_label': 'Що таке менінгіт'})"
            class="link">
          <router-link to="/#stage2">Що таке менінгіт
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                  fill="#EDD1E2"/>
            </svg>
          </router-link>
        </div>
        <div
            @click="openMenu = !openMenu; "
            onclick="gtag('event', 'meninngokovyi_meningit', {'event_category': 'main_menu', 'event_label': 'Менінгококовий менінгіт'})"
            class="link">
          <router-link to="/#stage4">Менінгококовий менінгіт
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                  fill="#EDD1E2"/>
            </svg>
          </router-link>
        </div>
        <div
            @click="openMenu = !openMenu;"
            onclick="gtag('event', 'hto_mozhe_zahvority', {'event_category': 'main_menu', 'event_label': 'Хто може захворіти'})"
            class="link">
          <router-link to="/#stage6">Хто може захворіти
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                  fill="#EDD1E2"/>
            </svg>
          </router-link>
        </div>
        <div
            onclick="gtag('event', 'zahyst_vid_infekcii', {'event_category': 'main_menu', 'event_label': 'Захист від інфекції'})"
            @click="openMenu = !openMenu;"
            class="link">
          <router-link to="/#stage9">Захист від інфекції
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.85066 5.29289C6.46014 4.90237 5.82697 4.90237 5.43645 5.29289C5.04592 5.68342 5.04592 6.31658 5.43645 6.70711L6.85066 5.29289ZM14.9282 15.7846C15.4804 15.7846 15.9282 15.3369 15.9282 14.7846L15.9282 5.78463C15.9282 5.23234 15.4805 4.78463 14.9282 4.78462C14.3759 4.78462 13.9282 5.23234 13.9282 5.78462L13.9282 13.7846L5.92816 13.7846C5.37587 13.7846 4.92816 14.2323 4.92816 14.7846C4.92816 15.3369 5.37587 15.7846 5.92815 15.7846L14.9282 15.7846ZM5.43645 6.70711L14.221 15.4917L15.6353 14.0775L6.85066 5.29289L5.43645 6.70711Z"
                  fill="#EDD1E2"/>
            </svg>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "top-block",
  data() {
    return {
      openMenu: false,
      activeColor: false
    }
  },
  mounted() {
    document.addEventListener('scroll', (e) => {
      if (document.querySelector("#app").getBoundingClientRect().top < -100) {
        this.activeColor = true;
      } else {
        this.activeColor = false;
      }
    });
  }
}
</script>

<style scoped lang="scss">
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";
$fontB: "Raleway-Bold";
.top-block {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;

  display: flex;
  align-items: center;
  flex-direction: column;

  .line1 {
    width: 100%;
    padding: 20px 0;
    background: white;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 768px) {
      padding: 15px 0;
    }

    .content {
      max-width: 1300px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 10px;
    }

    .logo {
      max-width: 230px;
      width: 100%;

      @media screen and (max-width: 768px) {
        max-width: 170px;
      }

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .line2 {
    background: rgba(255, 255, 255, .2);

    transition: background-color .2s ease;

    &.active {
      background: rgba(255, 255, 255, .75);
    }

    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;

    logo {
      svg {
        display: block;
      }
    }

    .content {
      max-width: 1300px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;

      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }

      .item {


        &.item1 {
          margin-right: 20px;
        }

        &.item2 {
          display: flex;
          align-items: center;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }


        .link {
          text-decoration: none;
          font-family: $fontR;
          font-size: 16px;
          color: #3F4C56;
          margin: 0 20px;

          &.router-link-active {
            color: #602F58;
            font-weight: bold;

            svg {
              stroke: #602F58;

              path {
                fill: #602F58;
              }
            }
          }

          @media screen and (max-width: 1024px) {
            font-size: 14px;
            margin: 0 10px;
          }
        }
        .link_kok {
          text-decoration: none;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          border: 1px solid;
          border-radius: 10px;
          font-family: $fontR;
          font-size: 16px;
          color: #3F4C56;
          margin: 0 20px;

          &.router-link-active {
            color: #602F58;
            font-weight: bold;

            svg {
              stroke: #602F58;

              path {
                fill: #602F58;
              }
            }
          }

          @media screen and (max-width: 1024px) {
            font-size: 14px;
            margin: 0 10px;
          }
        }
      }

      .icon-menu {
        display: none;
        @media screen and (max-width: 768px) {
          display: flex;
        }
        align-items: center;
        justify-content: center;

        svg {
          display: block;
        }
      }
    }
  }

  .drop-list {
    position: fixed;
    z-index: 1;
    min-height: calc(100vh - 50px);
    min-height: calc((var(--vh, 1vh) * 100) - 50px);
    top: 50px;

    width: 100%;
    background: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 769px) {
      display: none;
    }

    .color {
      width: 100%;
      background: linear-gradient(180deg, rgba(80, 84, 117, 0.5) 0%, rgba(125, 58, 115, .5) 101.47%);

      min-height: calc(100vh - 50px);
      min-height: calc((var(--vh, 1vh) * 100) - 50px);

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .link {
      margin: 10px 0;

      a {
        color: white;
        font-family: $fontR;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: flex-end;

        &.router-link-active {
          color: #602F58;

          svg {
            stroke: #602F58;

            path {
              fill: #602F58;
            }
          }
        }
      }
    }
  }
}
</style>