<template>
  <div class="wi-flu" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="what-is-flu/banner-desc.png" mobImg="what-is-flu/banner-mob.png">
      <template v-slot:link>Що таке грип?</template>
      <template v-slot:title>Що таке грип?</template>
      <template v-slot:text>Грип — це гостре вірусне інфекційне захворювання, яке передається повітряно-крапельним шляхом, характеризується гострим початком, гарячкою (температура тіла 38 °C і вище), загальною інтоксикацією та ураженням дихальних шляхів.<sup>1</sup></template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#what-is" class="contents__link">
                Що таке грип?
              </a>
            </li>
            <li class="contents__item">
              <a href="#symptoms" class="contents__link">
                Симптоми грипу
              </a>
            </li>
            <li class="contents__item">
              <a href="#cure" class="contents__link">
                Лікування грипу
              </a>
            </li>
            <li class="contents__item">
              <a href="#prevent" class="contents__link">
                Профілактика грипу
              </a>
            </li>
          </ul>
        </Contents>
        <section class="page-wrapper__section wi-flu__section" id="what-is">
          <h2 class="page-wrapper__h2-title">
            Що таке грип?
          </h2>

          <p class="page-wrapper__text">
            Грип — це гостре вірусне інфекційне захворювання, яке передається повітряно-крапельним шляхом, характеризується гострим початком, гарячкою (температура тіла 38 °C і вище), загальною інтоксикацією та ураженням дихальних шляхів.<sup>1</sup>
          </p>
        </section>

        <section class="page-wrapper__section wi-flu__section" id="symptoms">
          <h2 class="page-wrapper__h2-title">
            Симптоми грипу
          </h2>

          <p class="page-wrapper__text">
            Грип розпочинається гостро з різкого підвищення температури тіла (до 38 °C – 40 °C) і супроводжується сухим кашлем або першінням у горлі та симптомами загальної інтоксикації: ознобом, болем у м'язах, головним болем, болем в очних яблуках. Нежить зазвичай виникає через 3 дні після зниження температури тіла. Кашель може супроводжуватися болем за грудиною.<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            У разі легкого перебігу захворювання симптоми зберігаються протягом 3-5 днів, і хворий зазвичай одужує. Проте кілька днів зберігається відчуття вираженої втоми, особливо в осіб старшого віку.<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Тяжкий перебіг грипу супроводжується ураженням нижніх дихальних шляхів з розвитком пневмонії та (або) ознаками дихальної недостатності: з'являється задишка або утруднене дихання в спокої (у дітей до 5 років спостерігається втягнення грудної клітини або свистяче дихання в спокої), ціаноз носогубного трикутника. У разі тяжкого перебігу грипу можуть розвиватися набряк легень, судинний колапс, набряк мозку, геморагічний синдром, а також приєднуватися вторинні бактеріальні ускладнення.<sup>1,2</sup>
          </p>
          <p class="page-wrapper__text">
            Грип являє собою велику небезпеку через розвиток серйозних ускладнень, особливо у дітей до 5 років, вагітних, осіб з хронічними захворюваннями серця, легень, метаболічним синдромом, людей старше 60 років та інших.<sup>1,3</sup>
          </p>
        </section>


        <section class="page-wrapper__section wi-flu__section" id="cure">
          <h2 class="page-wrapper__h2-title">
            Лікування грипу
          </h2>

          <p class="page-wrapper__text">
            При наявності симптомів рекомендується залишатися вдома, щоб мінімізувати ризик інфікування інших людей. Потрібно стежити за своїм станом і в разі його погіршення звернутися до лікаря. Лікування зазвичай симптоматичне та спрямоване на пом'якшення симптомів грипу, таких як висока температура, нежить.<sup>3</sup>
          </p>
        </section>

        <section class="page-wrapper__section wi-flu__section" id="prevent">
          <h2 class="page-wrapper__h2-title">
            Профілактика грипу
          </h2>

          <p class="page-wrapper__text">
            Найбільш ефективний метод профілактики грипу – це вакцинація. Щорічне щеплення рекомендується усім людям, крім немовлят до 6 місяців. Вакцинація проти грипу вбереже від зараження вірусами та значно зменшить ризик захворіти.<sup>4</sup>
          </p>
          <p class="page-wrapper__text">
            Перш за все щеплення рекомендоване людям, які входять до груп ризику: 
          </p>

          <ul class="wi-flu__grids">
            <li class="wi-flu__item">
              <img src="@/assets/img_const-pages/what-is-flu/icon-1.svg" alt="">
              діти молодшого віку (з 6 місяців)
            </li>
            <li class="wi-flu__item">
              <img src="@/assets/img_const-pages/what-is-flu/icon-2.svg" alt="">
              люди похилого віку (>60 років)
            </li>
            <li class="wi-flu__item">
              <img src="@/assets/img_const-pages/what-is-flu/icon-3.svg" alt="">
              вагітні
            </li>
            <li class="wi-flu__item">
              <img src="@/assets/img_const-pages/what-is-flu/icon-4.svg" alt="">
              медичні працівники
            </li>
            <li class="wi-flu__item">
              <img src="@/assets/img_const-pages/what-is-flu/icon-5.svg" alt="">
              особи із хронічними захворюваннями
            </li>
            <li class="wi-flu__item">
              <img src="@/assets/img_const-pages/what-is-flu/icon-6.svg" alt="">
              особи, які живуть або доглядають за людьми групи підвищеного ризику.
            </li>
          </ul>

          <p class="page-wrapper__text">
            Бажано вакцинуватися до початку циркуляції вірусів грипу (у вересні-жовтні). Однак щеплення можна робити й пізніше, навіть якщо вже зафіксовано сезонні спалахи грипу.<sup>4</sup>
          </p>
          <p class="page-wrapper__text">
            Також слід притримуватися профілактичних заходів. Вони допоможуть запобігти поширенню вірусів грипу:
          </p>
          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">
              Уникайте близького контакту з хворими;
            </li>
            <li class="page-wrapper__item">
              Якщо захворіли, то намагайтеся якнайрідше перебувати поряд із іншими людьми, щоб не заразити їх;
            </li>
            <li class="page-wrapper__item">
              При кашлі чи чханні прикривайте ніс та рот серветкою;
            </li>
            <li class="page-wrapper__item">
              Часто мийте руки з милом;
            </li>
            <li class="page-wrapper__item">
              Намагайтеся не торкатися обличчя, особливо зони очей, носа та рота.<sup>4</sup>
            </li>
          </ul>
          <p class="page-wrapper__text">
            Якщо у вас з'явились симптоми грипу, зверніться по допомогу до свого лікаря. Він надасть кваліфіковані поради та призначить лікування.
          </p>
        </section>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />

    <Links>
      <ol>
        <li
          class="list"
        >
          Як відрізнити грип від ГРВІ (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/health/jak-vidrizniti-grip-vid-grvi">Як відрізнити грип від ГРВІ (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Печінка А.М., Дземан М.І. Гострі респіраторні захворювання: питання клінічної діагностики та лікування. Укр. мед. Часопис. 2010;5(79):94-103.
        </li>
        <li
          class="list"
        >
          Грипп (who.int) <a target="_blank" href="https://www.who.int/ru/news-room/fact-sheets/detail/influenza-(seasonal)">Грипп (who.int)</a>
        </li>
        <li
          class="list"
        >
          Три кроки для профілактики грипу (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/health/tri-kroki-dlja-profilaktiki-gripu">Три кроки для профілактики грипу (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Наказ МОЗ України від 11.08.2014 № 551 «Про удосконалення проведення профілактичних щеплень в Україні» Про удосконалення проведення про... | від 11.08.2014 № 551 (rada.gov.ua)
        </li>
      </ol>
      <p class="list">MAT-UA-2101723 (дата першого застосування 20.11.2021)</p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Що таке грип?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-flu#what-is"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Симптоми грипу",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-flu#symptoms"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Лікування грипу",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-flu#cure"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Профілактика грипу",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-flu#prevent"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';

export default {
  name: "whatIsFlu",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
$fontB: "Raleway-Bold";
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";

.wi-flu {
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  &__grids {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 26px;
    list-style: none;
    margin-top: 30px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 70px 1fr;
    align-items: center;
    padding: 12px 27px;
    border: 2px solid #BCA36A;
    border-radius: 14px;
    font-size: 18px;
    line-height: 30px;
    font-family: $fontM;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 140%;
      grid-template-columns: 50px 1fr;
      grid-column-gap: 16px;
    }
  }

  .page-wrapper__item {
    list-style-type: none;
    margin-left: 26px;
    position: relative;
    &::before {
      content: "•";
      color: #2E2E2E;
      left: -16px;
      font-size: 25px;
      position: absolute;
    }
  }
}
</style>