<template>
  <div class="wi-vaccination" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="chto-take-privivka/banner-desc.png" mobImg="chto-take-privivka/banner-mob.png">
      <template v-slot:link>Що таке вакцинація</template>
      <template v-slot:title>Що таке вакцинація</template>
      <template v-slot:text>Це введення в організм спеціального лікарського препарату з метою створення захисту проти серйозного інфекційного захворювання та його наслідків.<sup>1</sup></template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#what-is" class="contents__link">
                Що ж таке вакцинація?
              </a>
            </li>
            <li class="contents__item">
              <a href="#health" class="contents__link">
                Правило № 1. Здоров'я
              </a>
            </li>
            <li class="contents__item">
              <a href="#lifestyle" class="contents__link">
                Правило № 2. Спосіб життя
              </a>
            </li>
            <li class="contents__item">
              <a href="#vaccine" class="contents__link">
                Правило № 3. Вакцина
              </a>
            </li>
          </ul>
        </Contents>

        <section class="page-wrapper__section wi-vaccination__section" id="what-is">
          <h2 class="page-wrapper__h2-title">
            Що ж таке вакцинація?
          </h2>

          <p class="page-wrapper__text">
            Це введення в організм спеціального лікарського препарату з метою створення захисту проти серйозного інфекційного захворювання та його наслідків.<sup>1 </sup>
          </p>
          <p class="page-wrapper__text">
            Для того щоб вакцинація допомогла убезпечити вас або вашу дитину від інфекції та для зниження ймовірності розвитку побічних реакцій, важливо дотримуватися рекомендацій лікаря, який може визначити необхідність вакцинації, підібрати схему вакцинації та дати необхідні рекомендації.<sup>1</sup> 
          </p>
          <p class="page-wrapper__text">
            Варто також пам’ятати декілька простих правил.
          </p>
        </section>

        <section class="page-wrapper__section wi-vaccination__section" id="health">
          <h2 class="page-wrapper__h2-title">
            Правило № 1. Здоров'я
          </h2>

          <p class="page-wrapper__text">
            Переконайтеся, що у вас або вашої дитини немає підвищення температури тіла вище 38<sup>0</sup>C, гострих інфекційних захворювань, загострення хронічних хвороб.<sup>2</sup>
          </p>
        </section>


        <section class="page-wrapper__section wi-vaccination__section" id="lifestyle">
          <h2 class="page-wrapper__h2-title">
            Правило № 2. Спосіб життя
          </h2>

          <p class="page-wrapper__text">
            Не піддавайте організм додатковому стресу за кілька днів до процедури та після неї. Небажано вживати нову для вас екзотичну їжу або вводити новий прикорм малюкові.<sup>3</sup>
          </p>
        </section>

        <section class="page-wrapper__section wi-vaccination__section" id="vaccine">
          <h2 class="page-wrapper__h2-title">
            Правило № 3. Вакцина
          </h2>

          <p class="page-wrapper__text">
            Дізнайтеся в лікаря всю інформацію про тип вакцини, її складові, виробника, очікувані побічні ефекти, можливі заходи їх профілактики та протипоказання, а також про інші подібні вакцини.<sup>3</sup>
          </p>
        </section>

        <ReadAlsoCard :content="readAlso"/>

        <p class="page-wrapper__text">
          Не забувайте ці правила! Дізнатися більше про вакцинацію можна в розділах:
        </p>
        <ul class="page-wrapper__text">
          <li class="page-wrapper__item"><a>Що таке щеплення</a></li>
          <li class="page-wrapper__item"><a>Вакцинація дітей</a></li>
          <li class="page-wrapper__item"><a>Вакцинація батьків</a></li>
        </ul>

        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />

    <Links>
      <ol>
        <li
          class="list"
        >
          Все, що варто знати про вакцинацію | Центр громадського здоров’я (phc.org.ua) <a href="https://www.phc.org.ua/news/vse-scho-varto-znati-pro-vakcinaciyu" target="_blank">https://www.phc.org.ua/news/vse-scho-varto-znati-pro-vakcinaciyu</a> Остання дата доступу 30.03.2021
        </li>
        <li
          class="list"
        >
          Наказ МОЗ України від 11.10.2019 № 2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень» <a href="https://zakon.rada.gov.ua/laws/show/z1182-19#Text" target="_blank">https://zakon.rada.gov.ua/laws/show/z1182-19#Text</a> Остання дата доступу 30.03.2021
        </li>
        <li
          class="list"
        >
          Parents | Your Child’s Vaccine Visit | CDC <a href="https://www.cdc.gov/vaccines/parents/visit/index.html " target="_blank">https://www.cdc.gov/vaccines/parents/visit/index.html </a> Остання дата доступу 30.03.2021
        </li>
        <span class="list">MAT-UA-2101723 (дата першого застосування 20.11.2021)</span>
      </ol>
      <p class="list">
        MAT-UA-2100441
      </p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Що ж таке вакцинація?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-vaccination#what-is"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Правило № 1. Здоров'я",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-vaccination#health"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Правило № 2. Спосіб життя",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-vaccination#lifestyle"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Правило № 3. Вакцина",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/what-is-vaccination#vaccine"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';

export default {
  name: "whatIsVaccination",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
.wi-vaccination {
  // width: 100vw;
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }
  
  .page-wrapper__text {
    &.do-not-forget {
      padding-left: 34px;
      margin-top: 12px;
      margin-bottom: 60px;
    }
    .page-wrapper__item {
      list-style-type: none;
      margin-left: 20px;
      &::before {
        content: "•";
        color: #2E2E2E;
        margin-right: 6px;
        font-size: 20px;
      }
    }
  }

  .med-text {

    // Regular Monitor > 1024px
    @media (min-width: 1025px) {
      #self-medication-block {
        display: flex !important;
        justify-content: space-around !important;
        align-items: center !important;

        #self-medication-svg {
          display: none !important;
        }

        .word {
          display: flex !important;
        }
      }
    }

    // Tablets < 1024px
    @media (max-width: 1024px) {
      #self-medication-block {
        #self-medication-svg {
          display: block !important;
        }

        .word {
          display: none !important;
        }
      }
    }

    #self-medication-block {
      width: 100%;
      height: 15vh;
      bottom: 0;
      left: 0;
      position: fixed;
      z-index: 100;

      box-shadow: 0 0 20px 0 rgba(154, 90, 176, 0.2);
      background: #EFF1F3;

      @media (max-width: 1024px) {
        bottom: 60px;
      }

      #self-medication-svg {
        width: 100%;
        height: 100%;

        .self-medication-svg-letter {
          fill: #A5A4A4;
        }
      }

      .word {
        display: flex;
        justify-content: center;

        .letter {
          width: 2vw;
          height: 15vh;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          &.bigger-letter {
            width: 3vw;
          }

          &.smaller-letter {
            width: 1vw;
          }

          &.znak {
            background-image: url('../../assets/img/footer/znak.svg');
          }

          &.s {
            background-image: url('../../assets/img/footer/s_letter.svg');
          }

          &.a {
            background-image: url('../../assets/img/footer/a_letter.svg');
          }

          &.m {
            background-image: url('../../assets/img/footer/m_letter.svg');
          }

          &.o {
            background-image: url('../../assets/img/footer/o_letter.svg');
          }

          &.l {
            background-image: url('../../assets/img/footer/l_letter.svg');
          }

          &.i {
            background-image: url('../../assets/img/footer/i_letter.svg');
          }

          &.k {
            background-image: url('../../assets/img/footer/k_letter.svg');
          }

          &.u {
            background-image: url('../../assets/img/footer/u_letter.svg');
          }

          &.v {
            background-image: url('../../assets/img/footer/v_letter.svg');
          }

          &.n {
            background-image: url('../../assets/img/footer/n_letter.svg');
          }

          &.e {
            background-image: url('../../assets/img/footer/e_letter.svg');
          }

          &.b {
            background-image: url('../../assets/img/footer/b_letter.svg');
          }

          &.t {
            background-image: url('../../assets/img/footer/t_letter.svg');
          }

          &.g {
            background-image: url('../../assets/img/footer/g_letter.svg');
          }

          &.d {
            background-image: url('../../assets/img/footer/d_letter.svg');
          }

          &.z {
            background-image: url('../../assets/img/footer/z_letter.svg');
          }

          &.r {
            background-image: url('../../assets/img/footer/r_letter.svg');
          }

          &.ia {
            background-image: url('../../assets/img/footer/ia_letter.svg');
          }

          &.ge {
            background-image: url('../../assets/img/footer/ge_letter.svg');
          }

          &.ii {
            background-image: url('../../assets/img/footer/ii_letter.svg');
          }

          &.sh {
            background-image: url('../../assets/img/footer/sh_letter.svg');
          }
        }
      }
    }
  }
}
</style>