<template>
  <div class="v-school" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="vac-before-school/banner-desc.png" mobImg="vac-before-school/banner-mob.png">
      <template v-slot:link>Вакцинація дітей перед школою</template>
      <template v-slot:title>Вакцинація дітей перед школою</template>
      <template v-slot:text>Школа — абсолютно новий етап в житті дитини. У цей період малюкові особливо необхідна підтримка і турбота.</template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Вакцинація дітей перед школою
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                Вакцинація дітей до 6-ти років
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Які вакцини роблять дітям у 6 років?
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-4" class="contents__link">
                Вакцинація від вітряної віспи
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-5" class="contents__link">
                Вакцинація від пневмококової інфекції
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-6" class="contents__link">
                Вакцинація від менінгококової інфекції
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-7" class="contents__link">
                Вакцинація від грипу
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-8" class="contents__link">
                Вакцинація від гепатиту А (інфекційна жовтяниця, хвороба «брудних рук»)
              </a>
            </li>
          </ul>
        </Contents>
        <section class="page-wrapper__section v-school__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Вакцинація дітей перед школою
          </h2>

          <p class="page-wrapper__text">
            Школа — абсолютно новий етап в житті дитини. У цей період малюкові особливо необхідна підтримка і турбота.
          </p>
          <p class="page-wrapper__text">
            Навчання має не тільки психологічне і фізичне навантаження, а може позначатися і на здоров'ї дитини, яка ще не адаптувалася до нових умов. Крім того, в школі, як у місці скупчення великої кількості дітей, є ризик зараження різними інфекціями.
          </p>
          <p class="page-wrapper__text">
            Школа — місце, де інфекції можуть поширюватися дуже швидко. Тому саме в цьому закладі стають особливо небезпечні інфекції, які характеризуються високою заразністю, такі як грип, кір, краснуха, паротит (свинка), вітряна віспа. Вакцинація покликана допомогти попередити ці захворювання. Проте важливо пам'ятати, що визначити правильну схему вакцинації та у разі потреби надати відповідні рекомендації може тільки лікар.
          </p>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            Вакцинація дітей до 6-ти років
          </h2>

          <p class="page-wrapper__text">
            Згідно з календарем профілактичних щеплень (Наказ МОЗ України від 11.10.2019 №2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень»), до 6 років дитина повинна отримати таку планову вакцинацію:<sup>1</sup>
          </p>

          <div class="v-school__vac vac">
            <ul class="vac__list">
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">3 щеплення від гепатиту В</h5>
                  <p class="vac__text">(перша доба життя, 2 і 6 місяців);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol3.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">1 щеплення від туберкульозу</h5>
                  <p class="vac__text">(перші 3-5 діб життя);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">1 щеплення від кору, краснухи, епідемічного  паротиту</h5>
                  <p class="vac__text">(12 місяців);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">4 щеплення від дифтерії, правця</h5>
                  <p class="vac__text">(2, 4, 6 і 18 місяців);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol4.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">4 щеплення від кашлюку</h5>
                  <p class="vac__text">(2, 4, 6 і 18 місяців);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol4.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">4 щеплення від поліомієліту</h5>
                  <p class="vac__text">(2, 4, 6 і 18 місяців);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol4.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">3 щеплення від гемофільної інфекції типу b</h5>
                  <p class="vac__text">(2, 4 і 12 місяців);</p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol3.svg">
                </div>
              </li>
              <li class="vac__item">
                <div class="vac__content-text">
                  <h5 class="vac__title">у віці від 6 місяців</h5>
                  <p class="vac__text">рекомендують робити щеплення від грипу щороку.<sup>2</sup></p>
                </div>
                <div class="vac__content-img">
                  <img src="@/assets/img_const-pages/infection-in-school/ukol.svg">
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-3">
          <h2 class="page-wrapper__h2-title">
            Які вакцини роблять дітям у 6 років?
          </h2>

          <p class="page-wrapper__text">
            Перед входженням у новий колектив (школу) у 6 років згідно з календарем профілактичних щеплень, проводять низку ревакцинації від:
          </p>
          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">кору, краснухи та епідемічного паротиту (друга доза);</li>
            <li class="page-wrapper__item"> дифтерії та правця (п’ята доза);</li>
            <li class="page-wrapper__item">поліомієліту (п’ята доза).<sup>1</sup></li>
          </ul>

          <p class="page-wrapper__text">
            Щоб додатково захистити дитину, можна обговорити з лікарем проведення вакцинації від інших інфекцій, зокрема вітряної віспи, пневмококової та менінгококової інфекцій, грипу, гепатиту А.<sup>3</sup>
          </p>
          <p class="page-wrapper__text">
            Крім цього у регіонах, ендемічних з вірусного кліщового енцефаліту, починаючи з 4-х років дітей вакцинують щорічно від кліщового енцефаліту протягом 3 років.<sup>4</sup>
          </p>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-4">
          <h2 class="page-wrapper__h2-title">
            Вакцинація від вітряної віспи
          </h2>

          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/vac-before-school/vispa.svg" alt="">
            </template>
            <template v-slot:text>
              Вітряна віспа характеризується високою заразністю і може мати тяжкий перебіг. Для неї характерна осінньо-зимова сезонність, що пояснюється тіснішим контактуванням дітей під час відвідування дитячих садків і шкіл. Спалах інфекції в школі зазвичай передбачає карантин, що означає пропуск занять для школярів.<sup>5</sup>
            </template>
          </Vocabulary>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-6">
          <h2 class="page-wrapper__h2-title">
            Вакцинація від пневмококової інфекції
          </h2>

          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/vac-before-school/pnevmococova.svg" alt="">
            </template>
            <template v-slot:text>
              Найбільше випадків пневмококової інфекції реєструють серед дитячого населення. Пневмокок є причиною таких тяжких станів, як бактеріємія та сепсис. Як правило, таку вакцинацію рекомендують дітям, які мають хронічні захворювання і часто хворіють, для зниження ризику розвитку тяжких респіраторних інфекцій.<sup>3</sup>
            </template>
          </Vocabulary>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-6">
          <h2 class="page-wrapper__h2-title">
            Вакцинація від менінгококової інфекції
          </h2>

          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/vac-before-school/meningcocova.svg" alt="">
            </template>
            <template v-slot:text>
              Менінгококова інфекція викликає тяжкі захворювання та вражає переважно дітей. Збудник передається повітряно-крапельним шляхом, а клінічні прояви характеризуються, як безсимптомним носійством, так і формами із високою летальністю. Щеплення – ефективний метод захисту дітей від небезпечної інфекції.<sup>6</sup>
            </template>
          </Vocabulary>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-7">
          <h2 class="page-wrapper__h2-title">
            Вакцинація від грипу
          </h2>

          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/vac-before-school/flu.svg" alt="">
            </template>
            <template v-slot:text>
              Це захворювання може мати тяжкий перебіг і зумовлювати ускладнення, зокрема пневмонію. Щеплення дітей проводять щорічно перед сезоном активності захворювання. Вакцинація є найефективнішим методом профілактики грипу та попередження важких ускладнень цієї хвороби.<sup>3</sup>
            </template>
          </Vocabulary>
        </section>

        <section class="page-wrapper__section v-school__section" id="stage-7">
          <h2 class="page-wrapper__h2-title">
            Вакцинація від гепатиту А (інфекційна жовтяниця, хвороба «брудних рук»)
          </h2>

          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/vac-before-school/gepatit.svg" alt="">
            </template>
            <template v-slot:text>
              У школі діти часто вперше починають самостійно користуватися їдальнею та громадським туалетом, що підвищує ризик розвитку кишкових інфекцій, до яких відноситься гепатит А. Це захворювання печінки вірусного походження, що може мати як легкий, так і важкий перебіг. Для профілактики інфікування існує безпечна та ефективна вакцина.<sup>7</sup>
            </template>
          </Vocabulary>
        </section>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />
    <Links>
      <ol>
        <li
          class="list"
        >
          Календар профілактичних щеплень (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen">Календар профілактичних щеплень (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Наказ МОЗ України від 11.08.2014  № 551 «Про удосконалення проведення профілактичних щеплень в Україні» <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/z1237-14#Text">Про удосконалення проведення про... | від 11.08.2014 № 551 (rada.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Вакцинація: від яких хвороб варто захиститись (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/health/vakcinacija-vid-jakih-hvorob-varto-zahistitis">Вакцинація: від яких хвороб варто захиститись (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Про затвердження Методичних рекомендацій "Неспецифічна профілактика трансмісивних природно-вогнищевих інфекцій, що передаються іксодовими кліщами" (rada.gov.ua)<a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0369282-13#Text">Про затвердження Методичних реко... | від 13.05.2013 № 369 (rada.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Вітряна віспа | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/inshi-infekciyni-zakhvoryuvannya/krapelni-infekcii/vitryana-vispa">Вітряна віспа | Центр громадського здоров’я (phc.org.ua)</a>
        </li>
        <li
          class="list"
        >
          Менінгококова інфекція | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/inshi-infekciyni-zakhvoryuvannya/krapelni-infekcii/meningokokova-infekciya">Менінгококова інфекція | Центр громадського здоров’я (phc.org.ua)</a>
        </li>
        <li
          class="list"
        >
          Гепатит A | Центр громадського здоров’я (phc.org.ua) <a target="_blank" href="https://phc.org.ua/kontrol-zakhvoryuvan/virusni-gepatiti/tipi-virusnikh-gepatitiv/gepatit-a">Гепатит A | Центр громадського здоров’я (phc.org.ua)</a>
        </li>
      </ol>
      <p class="list">
        MAT-UA-2101723 (дата першого застосування 20.11.2021)
      </p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Вакцинація дітей перед школою",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Вакцинація дітей до 6-ти років",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Які вакцини роблять дітям у 6 років?",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-3"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Вакцинація від вітряної віспи",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-4"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Вакцинація від пневмококової інфекції",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-5"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Вакцинація від менінгококової інфекції",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-6"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Вакцинація від грипу",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-7"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "Вакцинація від гепатиту А (інфекційна жовтяниця, хвороба «брудних рук»)",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-school#stage-8"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';
import Warning from '@/components/const-pages/warning.vue';
import Vocabulary from '@/components/const-pages/vocabulary.vue';

export default {
  name: "vaccinationSchool",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
    Warning,
    Vocabulary,
  },
  data: () => {
    return {
      readAlso: {
        title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
        link: '#'
      },
      faq: [
        {
          id: 0,
          title: 'Що таке кашлюк?',
          text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
        },
        {
          id: 1,
          title: 'Які ознаки грипу?',
          text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
        },
        {
          id: 2,
          title: 'Як передається грип?',
          text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
        },
      ],
      articles: [
        {
          id: 0,
          img: 'similar-pered-sad2.png',
          title: 'Щеплення перед дитячим садочком',
          link: '#',
        },
        {
          id: 1,
          img: 'similar-pidgotovka-do-vac.png',
          title: 'Підготовка дитини до щеплення',
          link: '#',
        },
        {
          id: 2,
          img: 'similar-swit-recom.png',
          title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
          link: '#',
        },
        {
          id: 3,
          img: 'similar-pered-shkola.png',
          title: 'Щеплення дітям перед школою',
          link: '#',
        }
      ]
    }
  },
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
.v-school {
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }
  
  .vac {
    border: 2px solid #515BA2;
    border-radius: 14px;
    padding: 2px 8px;
    margin-top: 28px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      list-style-type: none;
      @media (max-width: 1160px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      position: relative;
      padding-left: 22px;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 768px) {
        padding-left: 8px;
        padding-right: 8px;
        justify-content: flex-start;
      }

      &::after {
        content: '';
        width: 1px;
        background-color: #BFBFBF;
        height: 90%;
        position: absolute;
        right: 0;
        top: 8px;
      }

      &::before {
        content: '';
        width: 90%;
        background-color: #BFBFBF;
        height: 1px;
        position: absolute;
        right: 8px;
        left: 8px;
        top: 0;

        @media (max-width: 1160px) {
          width: 95%;
        }
      }

      &:nth-of-type(4)::after,
      &:nth-of-type(8)::after {
        background-color: transparent;
        @media (max-width: 1160px) {
          //background-color: transparent;
        }
      }

      &:nth-of-type(1)::after {
        @media (max-width: 540px) {
          background-color: transparent;
        }
      }

      &:nth-of-type(2)::after,
      &:nth-of-type(6)::after {
        background-color: #BFBFBF;
        @media (max-width: 1160px) {
          background-color: transparent;
        }
      }

      &:nth-of-type(3)::after,
      &:nth-of-type(5)::after,
      &:nth-of-type(7)::after {
        @media (max-width: 540px) {
          background-color: transparent;
        }
      }
      
      &:nth-of-type(1)::before,
      &:nth-of-type(2)::before,
      &:nth-of-type(3)::before,
      &:nth-of-type(4)::before {
        background-color: transparent;
      }

      &:nth-of-type(2)::before {
        @media (max-width: 540px) {
          background-color: #BFBFBF;
        }
      }

      &:nth-of-type(3)::before,
      &:nth-of-type(4)::before {
        @media (max-width: 1160px) {
          background-color: #BFBFBF;
        }
      }

      &:nth-of-type(4) .vac__title,
      &:nth-of-type(5) .vac__title{
        max-width: 117px;
        @media (max-width: 540px) {
          max-width: unset;
        }
      }
    }

    &__title {
      font-family: 'Raleway-Bold';
      color: #515BA2;
      font-size: 18px;
      line-height: 24px;
      max-width: 138px;

      @media (max-width: 540px) {
        max-width: 254px;
      }
    }

    &__text {
      font-family: 'Raleway-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #2E2E2E;
      margin-top: 4px;
    }

    &__content {
      &-img {
        text-align: end;
        padding-right: 20px;

        @media (max-width: 768px) {
          padding-right: 0;
          margin-top: 12px;
        }
      }
    }
  }

  .page-wrapper__item {
    list-style-type: none;
    margin-left: 20px;
    position: relative;
    &::before {
      content: "•";
      color: #2E2E2E;
      left: -16px;
      font-size: 25px;
      position: absolute;
    }
  }

  .links-wrapper {
    p.list {
      margin-top: 24px !important;

      @media (max-width: 540px) {
        margin-top: 48px !important;
      }
    }
  }
}
</style>