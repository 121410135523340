<template>
  <div class="v-kindergarten" data-view>
    <Header bgColor="#515BA2" mobBg="linear-gradient(180deg, #515BA2 8.27%, #97B7EE 101.47%)" />
    <Banner descImg="vaccination-kindergarten/banner-desc.png" mobImg="vaccination-kindergarten/banner-mob.png">
      <template v-slot:link>Вакцинація дітей перед дитячим садком</template>
      <template v-slot:title>Вакцинація дітей перед дитячим садком</template>
      <template v-slot:text>
        Звісно, кожна дитина унікальна, як і унікальний склад мікробів, які населяють організм малюка. За перші два роки життя імунна система до них звикає. Але ось настає час вступу до дитячого садка.
      </template>
    </Banner>
    <PageWrapper>
      <template v-slot:content>
        <Contents>
          <ul class="contents__list">
            <li class="contents__item">
              <a href="#stage-1" class="contents__link">
                Вакцинація дітей перед дитячим садком
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-2" class="contents__link">
                Обов’язкові щеплення
              </a>
            </li>
            <li class="contents__item">
              <a href="#stage-3" class="contents__link">
                Рекомендовані щеплення
              </a>
            </li>
          </ul>
        </Contents>

        <ul class="page-wrapper__section wi-flu__section" id="stage-1">
          <h2 class="page-wrapper__h2-title">
            Вакцинація дітей перед дитячим садком
          </h2>

          <p class="page-wrapper__text">
            Звісно, кожна дитина унікальна, як і унікальний склад мікробів, які населяють організм малюка. За перші два роки життя імунна система до них звикає. Але ось настає час вступу до дитячого садка.<br>
            Ваш малюк скоро зустрінеться з багатьма новими і такими ж унікальними дітьми. А його імунній системі належить познайомитися з новими бактеріями і вірусами, багато з яких можуть зумовлювати серйозні інфекційні захворювання. Тому вакцинація є надійним захистом і підготовкою імунної системи до зустрічі з новими мікробами!<sup>1</sup><br>
            Тож які щеплення для захисту дитини від серйозних інфекційних захворювань варто обговорити з лікарем перед відвідуванням дитячого садка?
          </p>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-2">
          <h2 class="page-wrapper__h2-title">
            Обов’язкові щеплення
          </h2>

          <p class="page-wrapper__text">
            На другому році життя настає час бустерної вакцинації, введення додаткової дози препарату для посилення імунітету, від дифтерії, правця, кашлюка та поліомієліту. Після цього малюк є захищеним від найнебезпечніших інфекцій, і час наступної ревакцинації настає у 6 років.<sup>2</sup><br>
            Тому загалом до моменту відвідування дошкільного закладу відповідно до календаря профілактичних щеплень (Наказ МОЗ України від 11.10.2019 №2070 «Про внесення змін до Календаря профілактичних щеплень в Україні та Переліку медичних протипоказань до проведення профілактичних щеплень»)
          </p>

          <p class="page-wrapper__text">
            <strong>
              Дитина має бути вакцинована проти 10 захворювань:
            </strong>
          </p>

          <ul class="page-wrapper__text">
            <li class="page-wrapper__item">
              <strong>
                гепатиту В;
              </strong>
            </li>
            <li class="page-wrapper__item">
              <strong>туберкульозу;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>кору;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>краснухи;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>паротиту;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>дифтерії;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>правця;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>кашлюку;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>поліомієліту;</strong>
            </li>
            <li class="page-wrapper__item">
              <strong>гемофільної інфекції типу b.<sup>2</sup></strong>
            </li>
          </ul>
        </ul>

        <ul class="page-wrapper__section wi-flu__section" id="stage-3">
          <h2 class="page-wrapper__h2-title">
            Рекомендовані щеплення
          </h2>

          <p class="page-wrapper__text">
            Варто розглянути додаткові щеплення, що не входять в обов’язковий перелік, але можуть захистити дитину від не менш небезпечних хвороб.<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Зокрема в першу чергу від гепатиту А (інфекційна жовтяниця), умови для поширення якої завжди є в дитячих колективах.
          </p>
          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/circle-liver.svg" alt="">
            </template>
            <template v-slot:text>
              <strong>Гепатит А</strong> — це вірусне захворювання печінки, спалахи якого трапляються в Україні через забруднення води.<sup>1</sup>
            </template>
          </Vocabulary>
          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/circle-lungs.svg" alt="">
            </template>
            <template v-slot:text>
              Також можна обговорити з педіатром необхідність вакцинації дитини від <strong>пневмококової інфекції</strong>. 
            </template>
          </Vocabulary>
          <p class="page-wrapper__text">
            У всьому світі це одна із основних причин смертності серед дітей до 5 років. Пневмокок спричиняє такі небезпечні захворювання, як менінгіт та сепсис. Вакцинацію проти пневмококової інфекції можна проводити одночасно з іншими вакцинами, передбаченими Календарем профілактичних щеплень<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Серед додаткових щеплень також рекомендована вакцинація від менінгококової інфекції. 
          </p>
          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/circle-brain.svg" alt="">
            </template>
            <template v-slot:text>
              <strong>Менінгококовий менінгіт</strong> — це небезпечне захворювання, що вражає оболонки головного та спинного мозку. Якщо вчасно не розпочати лікування, то 50% випадків закінчуються летально. На сьогоднішній день вакцинація є найкращим способом захисту дітей від бактеріального менінгіту.<sup>1</sup>
            </template>
          </Vocabulary>
          <p class="page-wrapper__text">
            З метою протистояння грипу рекомендовано щорічне щеплення. 
          </p>
          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/circle-cough.svg" alt="">
            </template>
            <template v-slot:text>
              <strong>Грип</strong> — це  високозаразне вірусне захворювання з можливістю тяжких ускладнень та ризиком смерті. Найчастішим ускладненням хвороби є пневмонія, яка може призвести до летальних наслідків.<sup>1</sup>
            </template>
          </Vocabulary>
          <Vocabulary>
            <template v-slot:image>
              <img src="@/assets/img_const-pages/circle-bact.svg" alt="">
            </template>
            <template v-slot:text>
              Лікар може порекомендувати щеплення від <strong>вітряної віспи</strong>, що є однією з найпоширеніших інфекцій у світі.
            </template>
          </Vocabulary>
          <p class="page-wrapper__text">
            Вірус передається повітряно-крапельним шляхом та є надзвичайно заразним. Захворювання зазвичай переноситься легко, проте можуть виникнути ускладнення.<sup>1</sup>
          </p>
          <p class="page-wrapper__text">
            Також зверніть увагу, що у кожному дитячому садку передбачені прогулянки. І в весняно-літній період серед рослин можуть бути не тільки гусениці, але і небезпечні кліщі. Їх укус може спричиняти розвиток кліщового енцефаліту, який має дуже серйозні наслідки. У регіонах України, ендемічних з вірусного кліщового енцефаліту, вакцинопрофілактику від кліщового енцефаліту рекомендують починати дітям з 4-річного віку щорічно протягом 3 років.<sup>3</sup>
          </p>
          <p class="page-wrapper__text">
            Усі питання стосовно щеплення можна вирішити з лікарем. 
          </p>
          <div class="v-kindergarten__info">
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.2698 15.8891V12.679H29.4799V9.46889H26.2698V6.25879H23.0597V9.46889H19.8496V12.679H23.0597V15.8891H26.2698Z" fill="#F1F2F2"/>
              <path d="M29.4797 8.66615H27.0721V6.25858C27.0721 5.81719 26.711 5.45605 26.2696 5.45605H23.0595C22.6181 5.45605 22.257 5.81719 22.257 6.25858V8.66615H19.8494C19.408 8.66615 19.0469 9.02729 19.0469 9.46868V12.6788C19.0469 13.1202 19.408 13.4813 19.8494 13.4813H22.257V15.8889C22.257 16.3303 22.6181 16.6914 23.0595 16.6914H26.2696C26.711 16.6914 27.0721 16.3303 27.0721 15.8889V13.4813H29.4797C29.9211 13.4813 30.2822 13.1202 30.2822 12.6788V9.46868C30.2822 9.02729 29.9211 8.66615 29.4797 8.66615ZM28.6772 11.8763H26.2696C25.8282 11.8763 25.4671 12.2374 25.4671 12.6788V15.0864H23.862V12.6788C23.862 12.2374 23.5009 11.8763 23.0595 11.8763H20.6519V10.2712H23.0595C23.5009 10.2712 23.862 9.91007 23.862 9.46868V7.0611H25.4671V9.46868C25.4671 9.91007 25.8282 10.2712 26.2696 10.2712H28.6772V11.8763Z" fill="#ACB317"/>
              <path d="M46.9143 12.9697C46.9343 12.8293 46.9343 12.6888 46.9343 12.5484C46.9243 11.0336 45.861 9.72952 44.3863 9.40851C42.701 9.0875 41.0458 10.1308 40.6145 11.796L37.0833 21.958C37.0332 21.9781 36.983 21.9981 36.9329 22.0282L29.8406 26.1813C27.6336 27.4753 26.2693 29.8428 26.2693 32.4109V39.9747C25.8279 39.9747 25.4668 40.3358 25.4668 40.7772V47.1974C25.4668 47.6388 25.8279 47.9999 26.2693 47.9999H37.5047C37.9461 47.9999 38.3072 47.6388 38.3072 47.1974V40.7772C38.3072 40.3358 37.9461 39.9747 37.5047 39.9747V38.2091C37.5047 37.527 37.7956 36.8749 38.3072 36.4135L43.7343 31.5883C44.3261 31.0666 44.7475 30.3845 44.9581 29.6221L48.429 16.5911C48.4993 16.3203 48.5394 16.0394 48.5394 15.7585C48.5394 14.6049 47.9174 13.5415 46.9143 12.9697ZM42.1493 12.2675C42.3499 11.4048 43.1825 10.843 44.0553 10.9835C44.7976 11.1339 45.3293 11.796 45.3293 12.5484V12.5684C43.8546 12.5985 42.5806 13.6017 42.2095 15.0262L39.9724 21.9379C39.6314 21.7774 39.2702 21.6771 38.8991 21.637L42.1493 12.2675ZM36.7021 46.3848H27.0718V41.5697H36.7021V46.3848ZM46.8842 16.1698L43.3932 29.2008C43.2728 29.6522 43.022 30.0635 42.6709 30.3745L37.2338 35.1996C36.3811 35.962 35.8996 37.0555 35.8896 38.1991V39.9646H27.8643V32.4008C27.8744 30.4046 28.9277 28.5688 30.6431 27.5556L37.7254 23.4025C38.4878 22.9611 39.4709 23.2119 39.9223 23.9844C39.9223 23.9844 39.9223 23.9844 39.9223 23.9944C40.1329 24.3656 40.1931 24.807 40.0828 25.2182C39.9724 25.6295 39.7016 25.9806 39.3304 26.1913L34.736 28.8497C34.3648 29.0904 34.2544 29.582 34.4852 29.9531C34.7059 30.3042 35.1673 30.4246 35.5385 30.234L40.1329 27.5757C40.8753 27.1543 41.417 26.4521 41.6377 25.6295C41.8584 24.807 41.738 23.9342 41.3167 23.2019L41.2665 23.1317L43.7443 15.4876C43.9449 14.6249 44.7775 14.0631 45.6503 14.2036C46.3926 14.3541 46.9243 15.0161 46.9243 15.7685C46.9444 15.8989 46.9243 16.0394 46.8842 16.1698Z" fill="#ACB317"/>
              <path d="M23.0594 39.9649V32.4011C23.0594 29.843 21.6951 27.4756 19.4882 26.1715L12.3958 22.0084C12.3457 21.9883 12.2955 21.9682 12.2454 21.9482L8.73432 11.8464C8.313 10.1611 6.64776 9.08772 4.94239 9.40873C3.45772 9.72974 2.39438 11.0338 2.39438 12.5486C2.39438 12.6891 2.40441 12.8295 2.41444 12.9699C1.41129 13.5417 0.789328 14.6051 0.779297 15.7587C0.779297 16.0396 0.819423 16.3205 0.879612 16.5913L4.3706 29.6123C4.57123 30.3747 4.99255 31.0669 5.59445 31.5885L11.0115 36.4137C11.5231 36.8751 11.824 37.5272 11.824 38.2093V39.9749C11.3827 39.9749 11.0215 40.336 11.0215 40.7774V47.1976C11.0215 47.639 11.3827 48.0001 11.824 48.0001H23.0594C23.5008 48.0001 23.8619 47.639 23.8619 47.1976V40.7774C23.8619 40.326 23.5008 39.9649 23.0594 39.9649ZM3.9894 12.5486C3.9894 11.7962 4.52107 11.1442 5.25337 10.9837C6.15621 10.8533 6.99886 11.4351 7.1995 12.3279L10.4297 21.6272C10.0585 21.6673 9.69735 21.7676 9.35628 21.9281L7.12927 15.0565C6.75811 13.622 5.47407 12.5988 3.9894 12.5586V12.5486ZM12.0748 35.1999L6.64776 30.3747C6.29666 30.0637 6.04587 29.6524 5.92549 29.191L2.4345 16.17C2.40441 16.0296 2.38435 15.8891 2.38435 15.7487C2.38435 14.9963 2.91602 14.3443 3.64832 14.1838C4.54113 14.0533 5.38378 14.6251 5.58441 15.5079L8.05218 23.1118L8.00202 23.1821C7.5807 23.9144 7.46032 24.7971 7.68101 25.6097C7.90171 26.4323 8.44341 27.1345 9.18575 27.5558L13.7802 30.2142C14.1714 30.4148 14.6529 30.2643 14.8636 29.8731C15.0542 29.5019 14.9338 29.0505 14.5827 28.8198L9.98827 26.1614C9.6171 25.9508 9.34625 25.5997 9.2359 25.1884C9.12556 24.7771 9.18575 24.3357 9.39641 23.9645C9.8378 23.1921 10.8209 22.9313 11.5833 23.3727L18.6756 27.5257C20.391 28.5389 21.4443 30.3847 21.4543 32.371V39.9348H13.4291V38.1692C13.4291 37.0557 12.9375 35.9623 12.0748 35.1999ZM22.2569 46.3851H12.6266V41.5699H22.2569V46.3851Z" fill="#ACB317"/>
              <path d="M36.0195 2.35616C32.8897 -0.783714 27.8137 -0.783714 24.6738 2.34613L24.6638 2.35616C21.5339 -0.783714 16.458 -0.783714 13.3281 2.34613L13.3181 2.35616C10.1882 5.48601 10.1882 10.562 13.3181 13.7019L24.102 24.4858C24.413 24.7968 24.9246 24.7968 25.2356 24.4858L36.0195 13.7019C39.1393 10.562 39.1393 5.48601 36.0195 2.35616ZM24.6638 22.7804L14.4416 12.5583C11.9337 10.0504 11.9438 5.98759 14.4517 3.4797C15.6554 2.27591 17.2906 1.6038 18.9859 1.6038C20.6913 1.59377 22.3264 2.27591 23.5202 3.48973L24.092 4.06153C24.403 4.37251 24.9146 4.37251 25.2255 4.06153L25.7973 3.48973C28.2952 0.98184 32.358 0.981841 34.8659 3.4797L34.8759 3.48973C37.3838 5.99762 37.3838 10.0604 34.8759 12.5583L24.6638 22.7804Z" fill="#ACB317"/>
            </svg>
            <p>Пам’ятайте, що вакцинація — це ефективний захист дитини від небезпечних інфекцій та їх ускладнень.<sup>1</sup></p>
          </div>
        </ul>

        <ReadAlsoCard :content="readAlso"/>
        <FaqConstPage :faq="faq"/>
        <SocialNetwork/>
      </template>
    </PageWrapper>

    <SimilarArticles :articles="articles"/>

    <MedText />
    <Links>
      <ol>
        <li
          class="list"
        >
          Вакцинація: від яких хвороб варто захиститись (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/health/vakcinacija-vid-jakih-hvorob-varto-zahistitis">Вакцинація: від яких хвороб варто захиститись (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Календар профілактичних щеплень (moz.gov.ua) <a target="_blank" href="https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen">Календар профілактичних щеплень (moz.gov.ua)</a>
        </li>
        <li
          class="list"
        >
          Про затвердження Методичних рекомендацій "Неспецифічна профілактика трансмісивних природно-вогнищевих інфекцій, що передаються іксодовими кліщами" (rada.gov.ua) <a target="_blank" href="https://zakon.rada.gov.ua/rada/show/v0369282-13#Text">Про затвердження Методичних реко... | від 13.05.2013 № 369 (rada.gov.ua)</a>
        </li>
      </ol>
      <p class="list">
        MAT-UA-2101723 (дата першого застосування 20.11.2021)
      </p>
    </Links>

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Вакцинація дітей перед дитячим садком",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-kindergarten#stage-1"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Обов’язкові щеплення",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-kindergarten#stage-2"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Рекомендовані щеплення",
        "item": "https://d3r65du9m6q6hf.cloudfront.net/vaccination-before-kindergarten#stage-3"
      }]
    }
    </script>
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://d3r65du9m6q6hf.cloudfront.net",
      "logo": "https://d3r65du9m6q6hf.cloudfront.net/logo.png"
    }
    </script>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import PageWrapper from '../../components/page-wrapper.vue';
import Banner from '../../components/const-pages/banner.vue';
import Contents from '../../components/const-pages/contents.vue';
import ReadAlsoCard from '@/components/const-pages/read-also-card.vue';
import FaqConstPage from '@/components/const-pages/faq-const-pages.vue';
import Header from '@/components/main-header.vue';
import FooterBlock from '@/components/const-pages/bottom-block.vue';
import SocialNetwork from '@/components/const-pages/social-network.vue';
import Links from '@/components/const-pages/links.vue';
import MedText from '@/components/const-pages/med-text.vue';
import SimilarArticles from '@/components/const-pages/similar-articles.vue';
import Vocabulary from '@/components/const-pages/vocabulary.vue';

export default {
  name: "vaccinationKindergarten",
  components: {
    Header,
    FooterBlock,
    PageWrapper,
    Banner,
    Contents,
    ReadAlsoCard,
    FaqConstPage,
    SocialNetwork,
    Links,
    MedText,
    SimilarArticles,
    Vocabulary,
  },
  data: () => ({
    readAlso: {
      title: 'Був смажень, і швимкі яски спіралили в кружві, і марамульки йшли в псашки, як трулі долові',
      link: '#'
    },
    faq: [
      {
        id: 0,
        title: 'Що таке кашлюк?',
        text: 'Кашлюк – це вкрай заразне захворювання дихальних шляхів, викликане бактерією. Кашлюк легко поширюється від людини до людини, головним чином повітряно-крапельним шляхом при кашлі чи чханні. Багато дітей, які заразилися кашлюком, страждають від нападів кашлю протягом 4-8 тижнів. Найбільш небезпечне це захворювання для немовлят. Першими ознаками кашлюку є помірний жар, нежить, кашель, який, як правило, переходить у судомний кашель. У немовлят це може супроводжуватися періодами тимчасового припинення дихальних рухів (апное). Пневмонія є відносно поширеним ускладненням від кашлюку, рідше виникають конвульсії і ураження головного мозку (енцефалопатія).<sup>2</sup>',
      },
      {
        id: 1,
        title: 'Які ознаки грипу?',
        text: 'Ознаки грипу такі: раптовий початок, головний біль, ломота в м’язах і суглобах, біль у горлі, підвищення температури тіла до 38 °С і більше, кашель<sup>2</sup>.',
      },
      {
        id: 2,
        title: 'Як передається грип?',
        text: 'Шляхи передачі грипу: повітряно-крапельний або повітряно-пиловий, тобто збудники переносяться повітрям від хворої людини до здорової під час розмови, кашлю або чхання у разі тісного контакту<sup>2</sup>.',
      },
    ],
    articles: [
      {
        id: 0,
        img: 'similar-pered-sad2.png',
        title: 'Щеплення перед дитячим садочком',
        link: '#',
      },
      {
        id: 1,
        img: 'similar-pidgotovka-do-vac.png',
        title: 'Підготовка дитини до щеплення',
        link: '#',
      },
      {
        id: 2,
        img: 'similar-swit-recom.png',
        title: 'Міжнародні рекомендації щодо вакцинації. Загальні положення',
        link: '#',
      },
      {
        id: 3,
        img: 'similar-pered-shkola.png',
        title: 'Щеплення дітям перед школою',
        link: '#',
      }
    ]
  }),
  mounted() {
    document.querySelectorAll('.contents__link').forEach(link => {
      
      link.addEventListener('click', function(e) {
          e.preventDefault();
          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);
          const topOffset = document.querySelector('.header').offsetHeight;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;
          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });
  }
}
</script>

<style scoped lang="scss">
$fontB: "Raleway-Bold";
$fontR: "Raleway-Regular";

.v-kindergarten {
  max-width: 100vw;
  //overflow-x: hidden;
  background: #f8f8f8;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding: 12px 19px 12px 23px;
    border: 2px solid #BFBFBF;
    border-radius: 14px;
    font-size: 18px;
    line-height: 30px;
    font-family: $fontR;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 16px 20px;
      font-size: 16px;
      line-height: 140%;
    }

    svg {
      margin-right: 25px;
      min-width: 34px;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }

  .links-wrapper {
    p.list {
      margin-top: 24px !important;

      @media (max-width: 540px) {
        margin-top: 48px !important;
      }
    }
  }

  .warning {
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .vocabulary {
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .page-wrapper__item {
    list-style-type: none;
    margin-left: 26px;
    position: relative;
    &::before {
      content: "•";
      color: #515BA2;
      left: -16px;
      font-size: 25px;
      position: absolute;
    }
    strong {
      color: #515BA2;
      font-family: $fontB;
    }
  }
}
</style>