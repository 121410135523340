import "@babel/polyfill";
import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'

import topMenu from './components/top-block'
import topMenuPert from './components/topBlockPert'

import bottomBlock from './components/bottom-block'
import bottomBlockPert from './components/bottom-block-pert'

Vue.use(VueMeta)
Vue.component('topMenu', topMenu)
Vue.component('bottomBlock', bottomBlock)
Vue.component('bottomBlockPert', bottomBlockPert)
Vue.component('topMenuPert', topMenuPert)


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');