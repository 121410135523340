import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import confidentiality from '../views/confidentiality.vue'
import cookie from '../views/cookie.vue'
import useTerm from '../views/use-term.vue'
import flu from '../views/flu.vue'
import pertussis2 from '../views/pertussis2.vue'
import page404 from '../views/page404.vue'
import whatIsVaccination from '../views/articles/what-is-vaccination.vue'
import whatIsFlu from '../views/articles/what-is-flu.vue'
import vaccinationKindergarten from '../views/articles/vaccination-kindergarten.vue'
import vaccinationSchool from '../views/articles/vaccination-school.vue'
import vaccinationAdults from '../views/articles/vaccination-adults.vue'
import recomendations from '../views/articles/recomendations.vue'
import uaRecomendations from '../views/articles/ua-recomendations.vue'
import preparation from '../views/articles/preparation.vue'
import polio from '../views/articles/polio.vue'
import infectionInSchool from '../views/articles/infection-in-school.vue'

Vue.use(VueRouter);

const routes = [
    // {
    //     path: '/',
    //     redirect: "/meningitis"
    // },
    {
        path: '/',
        name: 'home',
        component: home,
    },
    {
        path: '/meningitis',
        name: 'home',
        component: home,
    },
    {
        path: '/page404',
        name: 'page404',
        component: page404,
    },
    {
        path: '/pertussis',
        name: 'pertussis2',
        component: pertussis2,
    },
    {
        path: '/flu',
        name: 'flu',
        component: flu,
    },
    {
        path: '/confidentiality',
        name: 'confidentiality',
        component: confidentiality,
    },
    {
        path: '/cookie',
        name: 'cookie',
        component: cookie,
    },
    {
        path: '/terms-of-use',
        name: 'useTerm',
        component: useTerm,
    },
    {
        path: '*',
        redirect: "/page404"
    },
    // {
    //     path: '/what-is-vaccination',
    //     name: 'whatIsVaccination',
    //     component: whatIsVaccination,
    // },
    // {
    //     path: '/what-is-flu',
    //     name: 'whatIsFlu',
    //     component: whatIsFlu,
    // },
    // {
    //     path: '/vaccination-before-kindergarten',
    //     name: 'vaccinationKindergarten',
    //     component: vaccinationKindergarten,
    // },
    // {
    //     path: '/vaccination-before-school',
    //     name: 'vaccinationSchool',
    //     component: vaccinationSchool,
    // },
    // {
    //     path: '/vaccination-for-adults',
    //     name: 'vaccinationAdults',
    //     component: vaccinationAdults,
    // },
    // {
    //     path: '/international-recomendations',
    //     name: 'recomendations',
    //     component: recomendations,
    // },
    // {
    //     path: '/ukrainian-recomendations',
    //     name: 'uaRecomendations',
    //     component: uaRecomendations,
    // },
    // {
    //     path: '/preparation-for-vaccination',
    //     name: 'preparation',
    //     component: preparation,
    // },
    // {
    //     path: '/polio',
    //     name: 'polio',
    //     component: polio,
    // },
    // {
    //     path: '/infection-in-school',
    //     name: 'infectionInSchool',
    //     component: infectionInSchool,
    // },
];

const router = new VueRouter({
    mode: 'history',
    routes,

    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
});

export default router