<template>
    <div class="container" data-view>
        <img src="../assets/img404/Ellipse 85.png" class="el85" alt="">
        <!-- <img src="../assets/img404/Ellipse 86.png" class="el86" alt=""> -->

        <div class="header">
            <img src="../assets/img_pertussis/logo.png" alt="" class="logo1">
        </div>
        <div class="white_bound">
            <div class="four">
                404
            </div>
            <div class="info">
                Інформація не знайдена
            </div>
        </div>
    </div>
</template>
<script>
export default {
    metaInfo: {
        meta: [
            { vmid: 'description1', name: 'description', content: '404' },
            { vmid: 'robots', name: 'robots', content: 'noindex' }
        ]
    },
    mounted : function(){
        //window.location = '/404.html';
    }
}
</script>

<style scoped lang="scss">
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";
$fontB: "Raleway-Bold";
.container{
    width: 100vw; height: 100%; overflow: hidden;
    position: fixed;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    background: radial-gradient(100% 209.06% at -8.54% -6.3%, #DFECEE 0%, #E9FAFD 100%);
}
.el85{
    z-index: -1;
    position: fixed;

}
.el86{
    z-index: -1;
    position: fixed;
    top:20%;
    left:20%;
}
.four{
    font-family: $fontB;
    font-size: 200px;
    line-height: 200px;
    color: #A6B9C6;
}
.info{
    font-family: $fontB;
    font-size: 30px;
    line-height: 60px;
    color: #A6B9C6;
    margin-top: 20px;
}
body{
    
}
html{
    margin:0px !important;
}
.white_bound{
    z-index: 2;
    padding: 0px 100px 100px 100px;
    margin-left:25%;
    margin-top: 10%;
    background-color: white;
    border-radius: 30px;
    width:50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 1000px) {
        width: 90%;
        margin-left:5%;
        margin-right: 5%;
        margin-top:20%;
    }
}
.header{
    background-color: white;
    height: 9vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    z-index: 110000;
    .logo1{
        margin-right: 10vw;
    }
}
</style>