<template>
  <div class="page-wrapper">
    <div class="page-wrapper__links">
      <router-link to="#" class="item calendar">
        <div class="item__link">
          Календар щеплень
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 11L15 6L10 1" stroke="white"/>
            <path d="M0 6L15 6" stroke="white"/>
          </svg>
        </div>
        <img src="@/assets/img_const-pages/icon-cal.svg"/>
      </router-link>
      <router-link to="#" class="item center">
        <div class="item__link">
          Центри вакцинацій
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 11L15 6L10 1" stroke="white"/>
            <path d="M0 6L15 6" stroke="white"/>
          </svg>
        </div>
        <img src="@/assets/img_const-pages/icon-map.svg"/>
      </router-link>
    </div>
    <div class="page-wrapper__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-wrapper',
}
</script>

<style lang="scss" scoped>
$fontB: "Raleway-Bold";
$fontR: "Raleway-Regular";
$fontM: "Raleway-Medium";

a {
  text-decoration: none;
}

strong {
  font-family: $fontB;
}

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 52px 0 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;

  @media (max-width: 1024px) {
    padding: 0;
  }

  &__content {
    width: 65%;
    padding-left: 10px;
    margin-top: -340px;

    @media (max-width: 1024px) {
      margin-top: 32px;
      width: 100%;
      padding-right: 10px;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }

  &__h2-title {
    font-size: 30px;
    line-height: 38px;
    color: #515BA2;
    font-family: $fontB;
    padding-top: 72px;
    margin-bottom: 35px;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
      padding-top: 50px;
      margin-bottom: 18px;
    }
  }

  &__subtitle {
    font-family: $fontM;
    color: #515BA2;
    font-size: 18px;
    line-height: 30px;
  }

  &__text {
    font-size: 18px;
    line-height: 30px;
    font-family: $fontM;
    margin-top: 35px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
      margin-top: 18px;
    }
  }

  &__img {
    width: 100%;
    max-width: 100%;
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__item {
    margin-left: 34px;
    margin-top: 12px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
    margin-right: 10px;
    margin-left: auto;
    width: 273px;
    max-width: 25%;
    background-color: inherit;

    @media (max-width: 1024px) {
      position: fixed;
      bottom: 0;
      top: auto;
      flex-direction: row;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      max-width: 100%;
      z-index: 6;
    }

    .item {
      height: 157px;
      padding: 36px 23px 20px 30px;
      font-family: 'Raleway-Bold';
      font-size: 18px;
      line-height: 24px;
      border-radius: 14px;
      display: flex;
      flex-direction: column;

      @media (max-width: 1024px) {
        padding: 13px 9px 14px 16px;
        font-size: 16px;
        line-height: 19px;
        flex-direction: row-reverse;
        justify-content: center;
        width: 50%;
        height: 60px;
      }

      @media (max-width: 400px) {
        justify-content: flex-start;
      }

      img {
        align-self: end;
        @media (max-width: 1024px) {
          max-width: 35px;
          margin: 0 11px;
        }
      }

      &__link {
        color: #FFFFFF;
        max-width: 141px;
        display: inline-block;

        svg {
          margin-left: 7px;

          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
      &.calendar {
        background-color: #ACB317;
        box-shadow: 0px 8px 30px -10px rgba(172, 179, 23, 0.8);
        &:hover {
          cursor: pointer;
          background-color: #CDD430;
          outline: none
        }
        &:active {
          box-shadow: none;
          background-color: #ACB317;
        }
        @media (max-width: 1024px) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          &:focus,
          &:active {
            background-color: #ACB317;
          }
        }

      }
      &.center {
        margin-top: 26px;
        background-color: #515BA2;
        box-shadow: 0px 8px 30px -10px rgba(81, 91, 162, 0.8);
        &:hover {
          cursor: pointer;
          background-color: #6875CF;
          outline: none;
        }
        &:active {
          box-shadow: none;
          background-color: #6875CF;
        }

        @media (max-width: 1024px) {
          margin-top: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &:focus,
          &:active {
            background-color: #515BA2;
          }
        }
      }
    }
  }
  
}

</style>
